import { isSuccess } from "@devexperts/remote-data-ts";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RoutePaths } from "../../../utils/route.utils";
import NoTableData from "../../pages/dashboard/common/no-table-data";
import TableLoader from "../../pages/dashboard/common/table-loader";

import { Button, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { GetProtectionTxnData } from "../../../api/insurance/model/getCorpAdminProtectionTxnResponse";
import { ProtectionDashboardData } from "../../../api/insurance/model/protectionDashboardResponse";
import { ProtectionService } from "../../../services/protection.service";
import { currencyFormat } from "../../../utils/format.utils";
import {
  ITableData,
  TablePagination,
} from "../../pages/dashboard/common/table/table-pagination";
const PhoneIcon = "/images/icons/phone.svg";

const InsuranceTable: React.FC<{}> = ({}) => {
  const [items, setItems] = useState<ProtectionDashboardData[]>([]);
  const [txnRequests, setTxnRequests] = useState<GetProtectionTxnData[]>([]);

  const [show, setShow] = useState<boolean>(false);
  const [cid, setCid] = useState<string>("");

  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });
  useEffect(() => {
    const run = async () => {
      const result = await ProtectionService.getProtectionDashboard(
        paging.page,
        paging.limit
      );

      if (isSuccess(result)) {
        if (result.value.successful) {
          setItems(result.value.corporates ?? []);
        }
      }
    };
    run();
  }, [show]);

  const tblData = (tblData: ProtectionDashboardData[]) => {
    return tblData;
  };

  const tblRequestData = (tblRequestData: GetProtectionTxnData[]) => {
    return tblRequestData;
  };

  useEffect(() => {
    if (tblRequestData(txnRequests).length === 0) {
      setShow(false);
    }
  }, [tblRequestData(txnRequests)]);

  if (!items) {
    return <TableLoader />;
  }

  const getRequest = async (id?: string) => {
    const result = await ProtectionService.getCorpAdminTxnRequest(id);

    if (isSuccess(result)) {
      if (result.value.successful) {
        setCid(id ?? "");
        setTxnRequests(result.value.txnDetails ?? []);
      }
    }
  };

  const updateCorpAdminTxnRequest = async (item: GetProtectionTxnData) => {
    const result = await ProtectionService.updateCorpAdminTxnRequest(cid, item);

    if (isSuccess(result)) {
      if (result.value.successful) {
        console.log("updated successfully");
        getRequest(cid);
      }
    }
    setShow(true);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="xl"
        scrollable
        centered
      >
        <ModalHeader closeButton>
          <div className="container">
            <div className="row">
              <div className="col ">
                <h4>Corp Admin Txn Requests</h4>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <table>
            <thead>
              <tr>
                <th scope="col">Date of Txn</th>
                <th scope="col">Amount</th>
                <th scope="col">Txn Reference No</th>
                <th scope="col">Action </th>
              </tr>
            </thead>
            <tbody>
              {tblRequestData(txnRequests).map((item, key) => (
                <tr key={key}>
                  <td>{item.dateOfTxn === "" ? " - " : item.dateOfTxn}</td>
                  <td className="text-end">
                    {currencyFormat(item.amount ?? 0)}
                  </td>
                  <td>
                    {item.txnRefNumber === "" ? " - " : item.txnRefNumber}
                  </td>
                  <td>
                    <Button
                      className="btn-gradiant-blue btn-lg text-white fs-small m-1"
                      onClick={() => {
                        updateCorpAdminTxnRequest(item);
                      }}
                    >
                      Accept
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ModalBody>
      </Modal>

      <div
        className="row mt-2 ms-1 me-1 p-3 border rounded-15"
        style={{ background: "white" }}
      >
        {items.length > 0 ? (
          <TablePagination
            data={paging}
            changePage={(data) => setPaging((p) => ({ ...p, page: data }))}
            changeLimit={(data) =>
              setPaging((p) => ({ ...p, limit: data, page: 1 }))
            }
          >
            <table>
              <thead>
                <tr>
                  <th scope="col">Corporate Name</th>
                  <th scope="col">Policy No</th>
                  <th scope="col">Lives Covered</th>
                  <th scope="col">Policy Start Date</th>
                  <th scope="col">Policy End Date</th>
                  <th scope="col">CD Balance</th>
                  <th scope="col" style={{ zIndex: "100" }}>
                    Requests
                  </th>
                </tr>
              </thead>
              <tbody>
                {tblData(items).map((item, key) => (
                  <tr key={key}>
                    <td>
                      <Link
                        className="text-secondary ncash_nav-link"
                        to={
                          RoutePaths.NavigateToOnboardingDetails +
                          "/" +
                          item.cid
                        }
                      >
                        {item.corporateName === "" ? " - " : item.corporateName}
                      </Link>
                    </td>
                    <td>{item.policyNo === "" ? " - " : item.policyNo}</td>
                    <td>{item.livesCovered}</td>
                    <td>
                      {item.policyStartDate === ""
                        ? " - "
                        : item.policyStartDate}
                    </td>
                    <td>
                      {item.policyEndDate === "" ? " - " : item.policyEndDate}
                    </td>
                    <td className="text-end text-nowrap">
                      {currencyFormat(item.cdBalance) || "-"}
                    </td>

                    <td className="text-center text-nowrap">
                      {item.processingIcon ? (
                        <Button
                          className="btn-gradiant-blue btn-lg text-white fs-small m-1 me-2 px-2"
                          title="Click Here"
                          onClick={() => {
                            getRequest(item.cid);
                            setShow(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#fff"
                            className="bi bi-three-dots-vertical"
                            viewBox="0 0 16 16"
                            style={{}}
                          >
                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                          </svg>
                        </Button>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TablePagination>
        ) : (
          <NoTableData />
        )}
      </div>
    </>
  );
};

export default InsuranceTable;
