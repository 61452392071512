import { remoteData } from "@devexperts/remote-data-ts";
import { NcaishSettingsApiService } from "../api/detax/api/ncaishSettingsApi.service";
import { CampaignCreateResponse } from "../api/detax/model/campaignCreateResponse";
import { CampaignListResponse } from "../api/detax/model/campaignListResponse";
import { IMPSFeesRes } from "../api/detax/model/impsFeesRes";
import { ReplaceCardRes } from "../api/detax/model/replaceCardDetails";
import { CampaignTabKind } from "../components/pages/dashboard/settings/campaign.utils";
import { BaseResponse } from "../model/baseResponse";
import { DeliveryUploadRequest } from "../model/deliveryUploadRequest";
import { DeliveryUploadResponse } from "../model/deliveryUploadResponse";
// eslint-disable-next-line @typescript-eslint/no-namespace

export namespace SettingsService {
  export const createCampaign = async (
    title: string,
    body: string,
    cids: Array<string>,
    images: any,
    campaignState: CampaignTabKind
  ) => {
    const result = await NcaishSettingsApiService.createCampaign(
      title,
      body,
      cids,
      images,
      campaignState
    );
    return remoteData.map(
      result,
      (response: CampaignCreateResponse) => response
    );
  };

  export const getCampaignList = async (
    state: CampaignTabKind,
    pn: number,
    rc: number
  ) => {
    const result = await NcaishSettingsApiService.getCampaignList(
      state,
      pn,
      rc
    );
    return remoteData.map(result, (response: CampaignListResponse) => response);
  };

  export const getCampaignById = async (campaignId: string) => {
    const result = await NcaishSettingsApiService.getCampaignById(campaignId);
    return remoteData.map(
      result,
      (response: CampaignCreateResponse) => response
    );
  };

  export const updateCampaignById = async (
    campaignId: string,
    title: string,
    body: string,
    cids: Array<string>,
    images: any,
    campaignState: CampaignTabKind
  ) => {
    const result = await NcaishSettingsApiService.updateCampaignById(
      campaignId,
      title,
      body,
      cids,
      images,
      campaignState
    );
    return remoteData.map(
      result,
      (response: CampaignCreateResponse) => response
    );
  };

  export const deliveryUpload = async (data: Array<DeliveryUploadRequest>) => {
    const result = await NcaishSettingsApiService.deliveryUpload(data);
    return remoteData.map(
      result,
      (response: DeliveryUploadResponse) => response
    );
  };

  export const sendNotification = async (cid: string, body: FormData) => {
    const result = await NcaishSettingsApiService.sendNotification(cid, body);
    return remoteData.map(result, (response: BaseResponse) => response);
  };

  export const getReplaceCardDetails = async (
    from: string,
    to: string,
    pn: number,
    rc: number
  ) => {
    const result = await NcaishSettingsApiService.getReplaceCardDetails(
      from,
      to,
      pn,
      rc
    );
    return remoteData.map(result, (response: ReplaceCardRes) => response);
  };

  export const getIMPSFees = async (
    from: string,
    to: string,
    pn: number,
    rc: number
  ) => {
    const result = await NcaishSettingsApiService.getIMPSFees(from, to, pn, rc);
    return remoteData.map(result, (response: IMPSFeesRes) => response);
  };
}
