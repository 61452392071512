import { RemoteData } from "@devexperts/remote-data-ts";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import {
  GetInsuranceDetailsResponse,
  InclusionExclusionResponse,
} from "../../admin/model/adminInsuranceInfo";
import { InsuranceProvidersRes } from "../../admin/model/insuranceProvidersRes";
import { ApiClient } from "../../api-client";
import { BaseResponse } from "../../employee/model/baseResponse";
import { APIError } from "../../errors";
import {
  GetProtectionTxnData,
  GetProtectionTxnsResponse,
} from "../model/getCorpAdminProtectionTxnResponse";
import { ProtectionDashboardResponse } from "../model/protectionDashboardResponse";
import { ProtectionType } from "../../../enum/protection-type";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

type filterVal = [TQueryParam] | [];

export namespace NcaishProtectionService {
  export const getProtectionDashboard = async (
    pn: number,
    rc: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ProtectionDashboardResponse>> => {
    const path =
      `/secure/protection/admin/getSuperAdminInsuranceDashboard`.replace(
        /^\//,
        ""
      );
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);

    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getCorpAdminTxnRequest = async (
    cid?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetProtectionTxnsResponse>> => {
    const path = `/secure/protection/admin/getCorpAdminTxnRequest`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [
      {
        key: "cid",
        value: cid,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);

    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const updateCorpAdminTxnRequest = async (
    cid?: string,
    body?: GetProtectionTxnData,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, BaseResponse>> => {
    const path = `/secure/protection/admin/updateCorpAdminTxnRequest`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [
      {
        key: "cid",
        value: cid,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);

    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getAllPredefinedCoverages = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, InclusionExclusionResponse>> => {
    const path = `/secure/protection/admin/getAllCoverages`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .filter((param) => param.value !== "")
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const updateInsuranceData = async (
    cid: string,
    body: FormData,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, BaseResponse>> => {
    const path = `/secure/protection/admin/corpInsuranceReg`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [{ key: "cid", value: cid }];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getInsuranceData = async (
    cid: string,
    type: ProtectionType,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetInsuranceDetailsResponse>> => {
    const path = `/secure/protection/admin/getCorpInsuranceDetails`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [
      { key: "cid", value: cid },
      { key: "type", value: type },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getInsuranceProviders = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, InsuranceProvidersRes>> => {
    const path = `/secure/protection/admin/insuranceProviders`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}
