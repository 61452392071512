import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import { pipe } from "fp-ts/lib/function";
import { fold, isNone, none, Option, some } from "fp-ts/lib/Option";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AmplitudeInstance } from "../../..";
import { AdminApplicationBasicInfo } from "../../../api/admin/model/adminApplicationBasicInfo";
import { AmplitudeEvent } from "../../../enum/amplitude";
import { OnboardingService } from "../../../services/onboarding.service";
import { TOnboardingItem } from "../../../types/admin/onboarding-item.type";
import { unWrapOption } from "../../../utils/option.utils";
import { RoutePaths } from "../../../utils/route.utils";
import NoTableData from "../../pages/dashboard/common/no-table-data";
import TableLoader from "../../pages/dashboard/common/table-loader";
import {
  ITableData,
  TablePagination,
} from "../../pages/dashboard/common/table/table-pagination";
import StatusText from "../status-text";

const OnboardTable: React.FC<{
  selectedState: string;
  searchVal?: string;
  search?: boolean;
}> = ({ selectedState, searchVal = "", search }) => {
  const [items, setItems] = useState<Option<TOnboardingItem[]>>(none);
  const [filteredData, setFilteredData] =
    useState<Option<TOnboardingItem[]>>(none);
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });
  useEffect(() => {
    setPaging((p) => ({
      ...p,
      total: 10000,
      page: 1,
      limit: 10,
    }));
  }, [selectedState, search]);
  useEffect(() => {
    //if (selectedState === null) {
    setItems(none);
    const run = async () => {
      const result = await OnboardingService.onboardingListByState(
        selectedState,
        paging.page,
        paging.limit
      );
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          setPaging((p) => ({
            ...p,
            total: result.value.recordCount ?? 10,
            page: paging.page,
            limit: paging.limit,
          }));
          setItems(some(result.value.data));
          setFilteredData(some(result.value.data));
          // filterData(some(result.value.data || []));
          AmplitudeInstance.logEvent(AmplitudeEvent.ONBOARDING_STATUS, {
            timeStamp: new Date(),
            status: selectedState,
          });
        }
      }
    };

    const search = async () => {
      const result = await OnboardingService.onboardingListByStateSearch(
        searchVal,
        selectedState,
        paging.page,
        paging.limit
      );
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          setPaging((p) => ({
            ...p,
            total: result.value.recordCount ?? 10,
            page: paging.page,
            limit: paging.limit,
          }));
          setItems(some(result.value.data));
          setFilteredData(some(result.value.data));
          // filterData(some(result.value.data || []));

          AmplitudeInstance.logEvent(AmplitudeEvent.ONBOARDING_SEARCH, {
            timeStamp: new Date(),
            searchVal: searchVal,
          });
          AmplitudeInstance.logEvent(AmplitudeEvent.ONBOARDING_STATUS, {
            timeStamp: new Date(),
            status: selectedState,
          });
        }
      }
    };
    if (searchVal.length === 0) {
      run();
    } else {
      search();
    }

    //}
  }, [paging.page, paging.limit, paging.total]);

  const tblData = (tblData: TOnboardingItem[]) => {
    // let result = [];
    // let startFrom = (paging.page - 1) * paging.limit;
    // let endAt = paging.page * paging.limit;
    // result = tblData.slice(startFrom, endAt);
    // return result;
    return tblData;
  };
  // useEffect(() => filterData(items), [searchVal]);
  // const filterData = (allData: Option<TOnboardingItem[]>) => {
  //   let data = pipe(
  //     allData,
  //     fold(
  //       () => [],
  //       (d) => (d.length > 0 ? d : [])
  //     )
  //   );
  //   if (searchVal) {
  //     let nameRegEx = new RegExp(".*" + searchVal + ".*", "gi");
  //     let mobRegEx = new RegExp(searchVal + ".*", "gi");

  //     // let filteredData = data;
  //     let filteredData = data.filter((item) =>
  //       nameRegEx.test(item?.companyName || "")
  //     );

  //     setPaging((p) => ({ ...p, total: filteredData.length, page: 1 }));
  //     setFilteredData(some(filteredData));
  //   } else {
  //     setPaging((p) => ({ ...p, total: data.length, page: 1 }));
  //     setFilteredData(allData);
  //   }
  // };

  if (isNone(items)) {
    return <TableLoader />;
  }

  return (
    <div
      className="row mt-2 ms-1 me-1 p-3 border rounded-15"
      style={{ background: "white" }}
    >
      {pipe(
        filteredData,
        fold(
          () => [<NoTableData />],
          (datas) =>
            datas.length
              ? [
                  <TablePagination
                    data={paging}
                    changePage={(data) =>
                      setPaging((p) => ({ ...p, page: data }))
                    }
                    changeLimit={(data) =>
                      setPaging((p) => ({ ...p, limit: data, page: 1 }))
                    }
                  >
                    <table>
                      <thead>
                        <tr>
                          <th scope="col">Application Code</th>
                          <th scope="col">Company Name</th>
                          <th scope="col">User Name</th>
                          <th scope="col">Mobile</th>
                          <th scope="col">Email Id</th>
                          <th scope="col">Created At</th>
                          <th scope="col">Updated At</th>
                          <th scope="col" style={{ zIndex: "100" }}>
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tblData(datas).map((item, key) => (
                          <tr key={key}>
                            <td>
                              <Link
                                className="text-secondary ncash_nav-link"
                                to={
                                  RoutePaths.NavigateToOnboardingDetails +
                                  "/" +
                                  item.id
                                }
                                onClick={() => {
                                  AmplitudeInstance.logEvent(
                                    AmplitudeEvent.APPLICATION_DETAILS,
                                    {
                                      timeStamp: new Date(),
                                      applicationName: item.companyName,
                                      applicationCode: item.appCode,
                                    }
                                  );
                                }}
                              >
                                {item.appCode === "" ? " - " : item.appCode}
                              </Link>
                            </td>
                            <td>
                              {item.companyName === ""
                                ? " - "
                                : item.companyName}
                            </td>
                            <td>
                              {item.applicantInfo.name === ""
                                ? " - "
                                : item.applicantInfo.name}
                            </td>
                            <td>
                              {item.applicantInfo.mobile === ""
                                ? " - "
                                : item.applicantInfo.mobile}
                            </td>
                            <td>
                              {item.applicantInfo.email === ""
                                ? " - "
                                : item.applicantInfo.email}
                            </td>
                            <td className="text-center text-nowrap">
                              {item.createdAt || "-"}
                            </td>
                            <td className="text-center text-nowrap">
                              {item.updatedAt || "-"}
                            </td>
                            <td
                              className="text-center text-nowrap"
                              style={{ zIndex: "200" }}
                            >
                              <StatusText
                                text={
                                  data.find(
                                    (obj) =>
                                      obj.state ===
                                      unWrapOption(item.state, () => "")
                                  )?.label ?? "Error"
                                }
                                className={classNames({
                                  color1:
                                    unWrapOption(item.state, () => "") ===
                                    "CIN_VERIFIED",
                                  color2:
                                    unWrapOption(item.state, () => "") ==
                                    "SIGNUP_COMPLETED",
                                  color3:
                                    unWrapOption(item.state, () => "") ==
                                    "SIGNATORY_UPDATED",
                                  color4:
                                    unWrapOption(item.state, () => "") ==
                                    "SERVICE_SELECTION_COMPLETED",
                                  color6:
                                    unWrapOption(item.state, () => "") ==
                                    "REJECTED",
                                  color5:
                                    unWrapOption(item.state, () => "") ==
                                    "BR_SENT",
                                  color9:
                                    unWrapOption(item.state, () => "") ==
                                    "REVIEW_PENDING",
                                  color7:
                                    unWrapOption(item.state, () => "") ==
                                    "LIVE",
                                  color8:
                                    unWrapOption(item.state, () => "") ==
                                    "EXPIRED",
                                })}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </TablePagination>,
                ]
              : [<NoTableData />]
        )
      )}
    </div>
  );
};

export default OnboardTable;

export const data = [
  {
    label: "Cin Verified",
    state: AdminApplicationBasicInfo.StateEnum.CINVERIFIED,
    count: 35,
  },
  {
    label: "Signup Completed",
    state: AdminApplicationBasicInfo.StateEnum.SIGNUPCOMPLETED,
    count: 16,
  },
  {
    label: "Signatory Selected",
    state: AdminApplicationBasicInfo.StateEnum.SIGNATORYUPDATED,
    count: 5,
  },
  {
    label: "Services Selected",
    state: AdminApplicationBasicInfo.StateEnum.SERVICESELECTIONCOMPLETED,
    count: 1,
  },
  {
    label: "BR Sent",
    state: AdminApplicationBasicInfo.StateEnum.BRSENT,
    count: 1,
  },
  {
    label: "Pending Approval",
    state: AdminApplicationBasicInfo.StateEnum.REVIEWPENDING,
    count: 3,
  },
  {
    label: "Live",
    state: AdminApplicationBasicInfo.StateEnum.LIVE,
    count: 75,
  },
  {
    label: "Rejected",
    state: AdminApplicationBasicInfo.StateEnum.REJECTED,
    count: 1,
  },
  {
    label: "Expired",
    state: AdminApplicationBasicInfo.StateEnum.EXPIRED,
    count: 0,
  },
];
