import React, { useState } from "react";
import PageTitle from "../../common/page-title";
import Heading from "../heading";
import AllEmployeesSearch from "./all-employees-search";
import AllEmployeesStatusCount from "./all-employees-status-count";
import AllEmployeesTable from "./all-employees-table";
export interface IDateRange {
  startDate?: any;
  endDate?: any;
}
const AllEmployees: React.FC = () => {
  const [searchVal, setSearchVal] = useState<string>("");
  // const [filterDate, setFilterDate] = useState<IDateRange>({});
  const [search, setSearch] = useState(false);
  const [searchType, setSearchType] = useState("name");
  return (
    <>
      <PageTitle title="Employees" />
      <Heading title="Employees" />
      <AllEmployeesSearch
        searchVal={searchVal}
        setSearchVal={setSearchVal}
        onSearch={() => setSearch(!search)}
        searchType={searchType}
        setSearchType={(val) => setSearchType(val)}
      />
      <AllEmployeesStatusCount />
      <AllEmployeesTable
        searchType={searchType}
        searchVal={searchVal}
        search={search}
      />
    </>
  );
};

export default AllEmployees;
