import { isSuccess } from "@devexperts/remote-data-ts";
import { none } from "fp-ts/lib/Option";
import { NcaishCorporateApiService } from "../../../../api/corporate/api/ncaishCorporateApi.service";
import { InitiateDetaxBatchApiRequest } from "../../../../api/detax/model/initiateDetaxBatchApiRequest";
import { TEligibleUserinfo } from "../../../../models/batches.models";
import { DetaxTypeKind } from "../../../../models/detax-type-kind";
import { BatchesService } from "../../../../services/batches.service";
import { Dispatch } from "../../../../state/dispatch";
import { DetaxDispatch } from "../../../../state/slice/detax.slice";
import { unWrapOption } from "../../../../utils/option.utils";

export const getTypeKind = (type: string): DetaxTypeKind => {
  switch (type) {
    case "FBA":
      return DetaxTypeKind.FBA;
    case "TA":
      return DetaxTypeKind.TA;
    case "NPS":
      return DetaxTypeKind.NPS;
    case "EA":
      return DetaxTypeKind.EA;
    case "GC":
      return DetaxTypeKind.GC;

    default:
      return DetaxTypeKind.FBA;
  }
};

export type TTab = { tabLabel: string; tabValue: DetaxTypeKind };

export const tabData: TTab[] = [
  { tabLabel: "De'tax FBA", tabValue: DetaxTypeKind.FBA },
  { tabLabel: "De'tax Claim", tabValue: DetaxTypeKind.TA },
  { tabLabel: "Expense Advance", tabValue: DetaxTypeKind.EA },
  { tabLabel: "NPS", tabValue: DetaxTypeKind.NPS },
  { tabLabel: "Employee Rewards", tabValue: DetaxTypeKind.GC },
];

export const getTabLabel = (tabValue: DetaxTypeKind): string => {
  const tab = tabData.find((tab) => tab.tabValue === tabValue);
  return tab ? tab.tabLabel : "";
};

export const fetchBatchDetail = async (batchId: string) => {
  //DetaxDispatch.setBatchInfo(none);
  Dispatch.App.setCommonError({
    showError: false,
    errorText: "",
  });
  const result = await BatchesService.getTransactionBatchDetailByBatchId(
    batchId
  );

  if (isSuccess(result)) {
    if (unWrapOption(result.value.successful, () => false) === true) {
      DetaxDispatch.setBatchInfo(result.value.data);
    } else {
      Dispatch.App.setCommonError({
        showError: true,
        errorText: unWrapOption(result.value.message, () => ""),
      });
    }
  } else {
    console.log("Error BatchDetailsByBatchId: ", result);
  }
};

export const fetchBatchNpsDetail = async (batchId: string) => {
  //DetaxDispatch.setBatchInfo(none);
  Dispatch.App.setCommonError({
    showError: false,
    errorText: "",
  });
  const result = await BatchesService.getNpsBatchDetailByBatchId(batchId);

  if (isSuccess(result)) {
    if (unWrapOption(result.value.successful, () => false) === true) {
      DetaxDispatch.setNpsBatchInfo(result.value.data);
    } else {
      Dispatch.App.setCommonError({
        showError: true,
        errorText: unWrapOption(result.value.message, () => ""),
      });
    }
  } else {
    console.log("Error BatchDetailsByBatchId: ", result);
  }
};

export const selectedUsers = (users: TEligibleUserinfo[]) =>
  users.filter((user) => user.isChecked);

export const getModeOfPayment = (
  mode: string
): InitiateDetaxBatchApiRequest.ModeEnum => {
  if (mode === "IMPS") {
    return InitiateDetaxBatchApiRequest.ModeEnum.IMPS;
  }
  return InitiateDetaxBatchApiRequest.ModeEnum.NEFT;
};

export const fetchBalance = async (cin: string) => {
  const result = await NcaishCorporateApiService.fetchBalance(cin);
  console.log("balance Details: ", result);
  if (isSuccess(result)) {
    // if (unWrapOption(result.value.successful, () => false)) {
    ///DetaxDispatch.setBatchInfo(result.value.data);
    // } else {
    //}
  } else {
  }
};
