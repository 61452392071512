import { isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import { MdDelete, MdSave } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { AmplitudeInstance } from "../../../..";
import { AdminApplicationInfo } from "../../../../api/admin/model/adminApplicationInfo";
import {
  CreateReqBody,
  DeleteReqBody,
  GetEmployeesDataEmployeesRes,
  GetMaxLimitData,
  ReqData,
} from "../../../../api/admin/model/maxLimitResponse";
import { AmplitudeEvent } from "../../../../enum/amplitude";
import { useAppSelector } from "../../../../hooks/hooks";
import { TAllAvailableCategories } from "../../../../models/onboarding.models";
import { OnboardingService } from "../../../../services/onboarding.service";
import { ProfileSelect } from "../../../../state/slice/profile.slice";
import { unWrapOption } from "../../../../utils/option.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import SuccessPopup from "../../../pages/dashboard/common/succes-popup";
import WhiteCard from "../../../white-card/white-card";
import ErrorPopup from "../../common/ErrorPopup";
import { OptionDrop } from "../onboard-details";
import AnnualLimit from "./annual-limit";

export interface Option {
  label: string;
  value: string;
}
const SetMaxLimit: React.FC<{
  listOfCat: TAllAvailableCategories[];
  cid: string;
  employeeList: GetEmployeesDataEmployeesRes[];
  annualLimit: boolean;
  details: AdminApplicationInfo;
}> = ({ listOfCat, cid, employeeList, annualLimit, details }) => {
  const approvedSuperAdmin = useAppSelector(
    ProfileSelect.selectApprovedSuperAdmin
  );
  const animatedComponents = makeAnimated();
  const navigate = useNavigate();
  const [successPopup, setSuccessPopup] = useState({
    show: false,
    message: "",
  });
  const [error, setError] = useState({
    show: false,
    message: "",
  });
  const [activeTab, setActiveTab] = useState(0);
  const [annualShow, setAnnualShow] = useState(false);
  const [switchVal, setSwitchVal] = useState(false);
  const [stateData, setStateData] = useState<GetMaxLimitData[]>([]);

  const [empDrop, setEmpDrop] = useState<Array<OptionDrop>>([]);

  const [maxLimitData, setMaxLimitData] = useState(
    listOfCat
      .filter((obj) => !obj.isEligibleToAdd)
      .map((obj) => ({
        ...obj,
        maxLimitCat: [
          {
            maxLimit: 0,
            type: { value: "", label: "" },
            employees: [
              {
                uid: "",
                empName: "",
              },
            ],
          },
        ],
      }))
  );
  const [maxLimitData1, setMaxLimitData1] = useState(
    listOfCat
      .filter((obj) => !obj.isEligibleToAdd)
      .map((obj) => ({
        ...obj,
        maxLimitCat: [
          {
            maxLimit: 0,
            type: { value: "", label: "" },
            employees: [
              {
                uid: "",
                empName: "",
              },
            ],
          },
        ],
      }))
  );

  useEffect(() => {
    setEmpDrop(
      employeeList.map((obj) => ({ label: obj.empName, value: obj.uid }))
    );
  }, [employeeList]);

  const [catData, setCatData] = useState(
    listOfCat
      .filter((obj) => !obj.isEligibleToAdd)
      .map((obj) => ({
        ...obj,
        userCat: [
          {
            maxLimit: 0,
            type: { value: "", label: "" },
            employees: [
              {
                uid: "",
                empName: "",
                taxRegime: "",
              },
            ],
          },
        ],
      }))
  );

  const option = [
    { value: "specific", label: "for specific employees" },
    // { value: "BUSINESS_UNIT", label: "for the business unit" },
    { value: "all", label: "for the entire organization" },
  ];

  useEffect(() => {
    const run = async () => {
      const result = await OnboardingService.getMaxLimitData(
        cid,
        unWrapOption(maxLimitData[activeTab]?.fbaCategoryId, () => "")
      );
      if (isSuccess(result)) {
        setStateData(result.value.data);
        const updatedMaxLimit = maxLimitData.map((cat) => {
          if (
            unWrapOption(cat.fbaCategoryId, () => "") ===
            unWrapOption(maxLimitData[activeTab]?.fbaCategoryId, () => "")
          ) {
            // If the category name matches the category being edited
            return {
              ...cat,
              maxLimitCat:
                result.value.data.length > 0
                  ? result.value.data.map((item) => ({
                      ...item,
                      type: {
                        value: item.type,
                        label:
                          item.type === "all"
                            ? "for the entire organization"
                            : "for specific employees",
                      },
                      employees:
                        item.employees !== null
                          ? item.employees.filter((obj) =>
                              empDrop.map((val) => obj.uid === val.value)
                            )
                          : [{ empName: "", uid: "" }],

                      // Other properties from the item
                    }))
                  : [],
            };
          }
          return cat; // Return unchanged for other categories
        });

        setMaxLimitData(updatedMaxLimit);
        setMaxLimitData1(updatedMaxLimit);
      } else {
        // setAppState(initialState);
      }
    };
    run();
    runCatData();
  }, [cid, activeTab, successPopup.show]);

  const runCatData = async () => {
    const result = await OnboardingService.getUserCategoryData(
      cid,
      unWrapOption(maxLimitData[activeTab]?.fbaCategoryId, () => "")
    );
    if (isSuccess(result)) {
      if (result.value.successful) {
        setStateData(result.value.data);

        const updatedUserCat = catData.map((cat) => {
          if (
            unWrapOption(cat.fbaCategoryId, () => "") ===
            unWrapOption(catData[activeTab]?.fbaCategoryId, () => "")
          ) {
            // If the category name matches the category being edited
            return {
              ...cat,
              userCat:
                result.value.data.length > 0
                  ? result.value.data.map((item) => ({
                      ...item,
                      type: {
                        value: item.type,
                        label:
                          item.type === "all"
                            ? "for the entire organization"
                            : "for specific employees",
                      },
                      employees:
                        item.employees !== null
                          ? item.employees.filter((obj) =>
                              empDrop.map((val) => obj.uid === val.value)
                            )
                          : [{ empName: "", uid: "", taxRegime: "" }],

                      // Other properties from the item
                    }))
                  : [],
            };
          }
          return cat; // Return unchanged for other categories
        });

        setCatData(updatedUserCat);
      } else {
        alert(result.value.message);
      }
    } else {
      // setAppState(initialState);
    }
  };

  const removedUsers = (i: number) => {
    const final = maxLimitData1.flatMap((obj, index) => {
      if (activeTab === index && obj.maxLimitCat[i]?.employees.length > 0) {
        const existingUids = new Set(
          maxLimitData[index].maxLimitCat[i].employees.map((x) => x.uid)
        );

        return obj.maxLimitCat[i]?.employees
          .filter((x) => !existingUids.has(x.uid)) // Only include uids not in the original data
          .map((obj1) => ({
            empName: obj1.empName,
            categoryId: unWrapOption(obj.fbaCategoryId, () => ""),
            maxLimit: obj.maxLimitCat[i].maxLimit,
          }));
      }
      return [];
    });

    return final;
  };

  const deleteMaxLimitData = async (
    type: string,
    body: Array<ReqData>,
    indexProp: number
  ) => {
    const final: Array<ReqData> =
      type === "all"
        ? body
        : maxLimitData1.flatMap((obj, index) => {
            if (
              activeTab === index &&
              obj.maxLimitCat[indexProp]?.employees.length > 0
            ) {
              const existingUids = new Set(
                maxLimitData[index].maxLimitCat[indexProp].employees.map(
                  (x) => x.uid
                )
              );

              return obj.maxLimitCat[indexProp].employees
                .filter((x) => !existingUids.has(x.uid)) // Only include uids not in the original data
                .map((obj1) => ({
                  uid: obj1.uid,
                  categoryId: unWrapOption(obj.fbaCategoryId, () => ""),
                  maxLimit: obj.maxLimitCat[indexProp].maxLimit,
                }));
            }
            return [];
          });

    const reqBody: DeleteReqBody = { data: final };
    // const reqBody: DeleteReqBody = { data: body };
    const result = await OnboardingService.deleteMaxLimit(cid, type, reqBody);

    if (isSuccess(result)) {
      if (result.value.successful) {
        setSuccessPopup({ show: true, message: result.value.message });
        AmplitudeInstance.logEvent(AmplitudeEvent.DELETE_SET_MAX_LIMIT, {
          timeStamp: new Date(),
          corporateName: details.corporateInfo?.name,
          applicationCode: details.appCode,
          request: {
            type: type,
            body: reqBody,
          },
        });
      } else {
        setError({ show: true, message: result.value.message });
      }
    } else {
      setError({ show: true, message: "Delete Failed" });
    }
  };

  useEffect(() => {
    if (annualLimit) {
      setSwitchVal(annualLimit);
    }
  }, [annualLimit]);

  const enableAnnualLimit = async () => {
    const result = await OnboardingService.enableAnnualLimit(cid);
    if (isSuccess(result)) {
      if (result.value.successful) {
        setSuccessPopup({ show: true, message: result.value.message });
        setAnnualShow(false);
        setSwitchVal(true);
        AmplitudeInstance.logEvent(AmplitudeEvent.ANNUAL_LIMIT, {
          timeStamp: new Date(),
          corporateName: details.corporateInfo?.name,
          applicationCode: details.appCode,
        });
      } else {
        setError({ show: true, message: result.value.message });
        setSwitchVal(false);
      }
    } else {
      setError({ show: true, message: "Annual Update Failed" });
      setSwitchVal(false);
    }
  };

  const createMaxLimitData = async (type: string, body: any) => {
    let reqBody: CreateReqBody = { data: body };
    const result = await OnboardingService.createMaxLimit(cid, type, reqBody);
    if (isSuccess(result)) {
      if (result.value.successful) {
        setSuccessPopup({ show: true, message: result.value.message });
        AmplitudeInstance.logEvent(AmplitudeEvent.CREATE_SET_MAX_LIMIT, {
          timeStamp: new Date(),
          corporateName: details.corporateInfo?.name,
          applicationCode: details.appCode,
          request: {
            type: type,
            body: body,
          },
        });
      } else {
        setError({ show: true, message: result.value.message });
      }
    } else {
      setError({ show: true, message: "Create Failed" });
    }
  };

  const handleChange = (
    data: string,
    maxLimitCatIndex: number,
    name: string,
    value: any
  ) => {
    const updatedMaxLimit = maxLimitData.map((cat) => {
      if (unWrapOption(cat.name, () => "") === data) {
        if (name !== "employees") {
          return {
            ...cat,
            maxLimitCat: cat.maxLimitCat.map((maxLimitCat, index) => {
              if (index === maxLimitCatIndex) {
                return { ...maxLimitCat, [name]: value };
              }
              return maxLimitCat;
            }),
          };
        } else if (name === "employees") {
          return {
            ...cat,
            maxLimitCat: cat.maxLimitCat.map((maxLimitCat, index) => {
              if (index === maxLimitCatIndex) {
                return {
                  ...maxLimitCat,
                  [name]: value.map((obj: OptionDrop) => ({
                    uid: obj.value,
                    empName: obj.label,
                  })),
                };
              }
              return maxLimitCat;
            }),
          };
        }
      }
      return cat;
    });

    setMaxLimitData(updatedMaxLimit);
  };

  const handleAddMaxLimit = (data: string) => {
    const categoryToAdd = maxLimitData.filter(
      (cat) => unWrapOption(cat.name, () => "") === data
    );
    if (categoryToAdd) {
      const newMaxLimitCat = {
        maxLimit: 0,
        type:
          catData[activeTab].userCat[0].type.value === "specific"
            ? { value: "specific", label: "for specific employees" }
            : { value: "", label: "" },
        employees: [],
      };

      const updatedMaxLimit = [...maxLimitData];
      const index = updatedMaxLimit.findIndex(
        (cat) => unWrapOption(cat.name, () => "") === data
      );

      if (index !== -1) {
        // if (updatedMaxLimit[index].maxLimitCat.length < 5) {
        updatedMaxLimit[index].maxLimitCat.push(newMaxLimitCat);
        setMaxLimitData(updatedMaxLimit);
        // }
      }
    }
  };

  const handleDeleteMaxLimit = (data: string, maxLimitCatIndex: number) => {
    const indexToUpdate = maxLimitData.findIndex(
      (cat) => unWrapOption(cat.name, () => "") === data
    );

    if (indexToUpdate !== -1) {
      const updatedMaxLimit = [...maxLimitData];
      const maxLimitCatArray = updatedMaxLimit[indexToUpdate].maxLimitCat;

      if (maxLimitCatArray.length > 1) {
        maxLimitCatArray.splice(maxLimitCatIndex, 1);
        setMaxLimitData(updatedMaxLimit);
      }
    }
  };

  const getFilteredEmployees = (
    employees: Array<{ uid: string; empName: string }>
  ) =>
    employees
      .filter((obj) => obj.uid !== "")
      .map((obj) => ({
        value: obj.uid,
        label: obj.empName,
      }));

  return (
    <>
      <SuccessPopup
        message={successPopup.message}
        show={successPopup.show}
        onClose={(flag) => setSuccessPopup({ show: false, message: "" })}
      />
      <ErrorPopup
        errorText={error.message}
        show={error.show}
        onHide={() => setError({ show: false, message: "" })}
      />
      <WhiteCard className="p-3">
        <div className="d-flex justify-content-between">
          <h6 className="text-secondary fw-bold">Set Max Limit</h6>
          {approvedSuperAdmin && (
            <>
              {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              className=" fw-bold me-3 m-0"
              style={{ fontSize: 14, marginTop: 12 }}
            >
              Enable Annual Max Limit
            </p>

            <ReactSwitch
              switchState={switchVal}
              handleSwitch={(data: boolean) => {
                setSwitchVal(data);
                if (data) {
                  setAnnualShow(true);
                }
              }}
              disabled={annualLimit || switchVal}
              height={25}
              width={50}
            />
          </div> */}
              <button
                onClick={() => {
                  navigate(RoutePaths.NavigateToSetMaxUpload + "/" + cid);
                }}
                className="btn-gradiant-blue text-white px-3 py-2"
              >
                Upload
              </button>
            </>
          )}
        </div>

        <div style={{ display: "flex", flexDirection: "row", marginTop: 8 }}>
          <>
            <div style={{ width: "17%", border: "1px solid #E2E2E2" }}>
              {maxLimitData.map((obj, index) => (
                <div
                  key={index}
                  style={{
                    paddingTop: 36.5,
                    paddingBottom: 36.5,
                    paddingLeft: 15,
                    borderBottom: "1px solid #E2E2E2",
                    cursor: "pointer",
                    borderRight: activeTab === index ? "5px solid #157FE1" : 0,
                    fontSize: 14,
                  }}
                  onClick={() => {
                    setActiveTab(index);
                  }}
                >
                  {unWrapOption(obj.name, () => "")}
                </div>
              ))}
            </div>
            <div style={{ width: "83%", border: "1px solid #E2E2E2" }}>
              <>
                {maxLimitData[activeTab]?.maxLimitCat &&
                maxLimitData[activeTab].maxLimitCat.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <p>No max limit is set for this category</p>
                    {approvedSuperAdmin && (
                      <button
                        onClick={() => {
                          handleAddMaxLimit(
                            unWrapOption(maxLimitData[activeTab].name, () => "")
                          );
                        }}
                        className="btn bg-primary border-0 px-4 text-white  ms-2 mt-2"
                      >
                        Add Limit
                      </button>
                    )}
                  </div>
                ) : (
                  maxLimitData.map(
                    (obj, index) =>
                      activeTab === index &&
                      obj.maxLimitCat.map((val, index1) => (
                        <>
                          <div
                            key={index1}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              borderBottom: "1px solid #E2E2E2",
                              flexWrap: "wrap",
                              justifyContent: "space-evenly",
                              // width: "95%",
                            }}
                            className="p-3"
                          >
                            <div style={{ width: "28%" }}>
                              <label>Max Limit</label>
                              <input
                                required
                                name="maxLimit"
                                type="number"
                                className="form-control textfield"
                                style={{ width: "100%" }}
                                placeholder="Enter amount"
                                value={val.maxLimit}
                                onChange={(e) => {
                                  handleChange(
                                    unWrapOption(obj.name, () => ""),
                                    index1,
                                    e.target.name,
                                    e.target.value
                                  );
                                }}
                                disabled={!approvedSuperAdmin}
                              />
                            </div>
                            <div style={{ width: "28%" }}>
                              <label>Employees</label>
                              <Select
                                options={option.filter((obj) =>
                                  catData[activeTab].userCat[0].type.value ===
                                  "specific"
                                    ? obj.value === "specific"
                                    : true
                                )}
                                value={val.type}
                                onChange={(value) => {
                                  handleChange(
                                    unWrapOption(obj.name, () => ""),
                                    index1,
                                    "type",
                                    value
                                  );
                                }}
                                isDisabled={!approvedSuperAdmin}
                              />
                              <style>{`.css-b62m3t-container {
                                    position: relative;
                                    box-sizing: border-box;
                                    width: 100%;
                                }`}</style>
                            </div>
                            <div style={{ width: "28%" }}>
                              {val.type.value === "specific" && (
                                <>
                                  <label>
                                    {" "}
                                    Select Employees{" "}
                                    {getFilteredEmployees(val.employees)
                                      .length > 0 &&
                                      ` (${
                                        getFilteredEmployees(val.employees)
                                          .length
                                      })`}
                                  </label>
                                  <Select
                                    options={
                                      catData[activeTab].userCat[0].type
                                        .value === "specific"
                                        ? empDrop.filter((obj) => {
                                            const final = catData[
                                              activeTab
                                            ].userCat[0].employees.map(
                                              (x) => x.uid
                                            );

                                            return final.includes(obj.value);
                                          })
                                        : empDrop
                                    }
                                    value={getFilteredEmployees(val.employees)}
                                    onChange={(value) => {
                                      handleChange(
                                        unWrapOption(obj.name, () => ""),
                                        index1,
                                        "employees",
                                        value
                                      );
                                    }}
                                    isMulti
                                    isDisabled={!approvedSuperAdmin}
                                    // components={animatedComponents}
                                  />
                                  {removedUsers(index1).length > 0 && (
                                    <p className="m-0 text-danger">
                                      <b>Selected for Deletion :</b>{" "}
                                      {removedUsers(index1)
                                        .map((z) => z.empName)
                                        .join(", ")}
                                    </p>
                                  )}
                                </>
                              )}
                            </div>

                            {approvedSuperAdmin && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "10%",
                                }}
                              >
                                <button
                                  className="btn btn-sm btn-danger mt-4"
                                  onClick={() => {
                                    deleteMaxLimitData(
                                      val.type.value,
                                      val.type.value === "all"
                                        ? [
                                            {
                                              uid: null,
                                              categoryId: unWrapOption(
                                                obj.fbaCategoryId,
                                                () => ""
                                              ),
                                              maxLimit: val.maxLimit,
                                            },
                                          ]
                                        : val.employees.map((obj1) => ({
                                            uid: obj1.uid,
                                            categoryId: unWrapOption(
                                              obj.fbaCategoryId,
                                              () => ""
                                            ),
                                            maxLimit: val.maxLimit,
                                          })),
                                      index1
                                    );
                                  }}
                                  disabled={removedUsers(index1).length === 0}
                                >
                                  <MdDelete />
                                </button>

                                <button
                                  className="btn btn-sm btn-primary mt-4 ms-2"
                                  onClick={() => {
                                    createMaxLimitData(
                                      val.type.value,
                                      val.type.value === "all"
                                        ? [
                                            {
                                              uid: null,
                                              categoryId: unWrapOption(
                                                obj.fbaCategoryId,
                                                () => ""
                                              ),
                                              maxLimit: val.maxLimit,
                                            },
                                          ]
                                        : val.employees.map((obj1) => ({
                                            uid: obj1.uid,
                                            categoryId: unWrapOption(
                                              obj.fbaCategoryId,
                                              () => ""
                                            ),
                                            maxLimit: val.maxLimit,
                                          }))
                                    );
                                  }}
                                >
                                  <MdSave />
                                </button>
                              </div>
                            )}
                          </div>
                        </>
                      ))
                  )
                )}
              </>

              {approvedSuperAdmin &&
                maxLimitData[activeTab]?.maxLimitCat &&
                maxLimitData[activeTab].maxLimitCat.length > 0 && (
                  <div>
                    <button
                      onClick={() => {
                        handleAddMaxLimit(
                          unWrapOption(maxLimitData[activeTab].name, () => "")
                        );
                      }}
                      className="btn bg-primary border-0 px-4 text-white  mt-2"
                      style={{ marginLeft: 27 }}
                    >
                      Add Limit
                    </button>
                  </div>
                )}
            </div>
          </>
        </div>
      </WhiteCard>
      <AnnualLimit
        show={annualShow}
        onHide={() => {
          setAnnualShow(false);
          setSwitchVal(false);
        }}
        onApprove={() => {
          enableAnnualLimit();
        }}
      />
    </>
  );
};

export default SetMaxLimit;
