import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { AmplitudeInstance } from "../..";
import { dashboardMenus } from "../../config/dashboard-menu";
import { AmplitudeEvent } from "../../enum/amplitude";
import { Dispatch } from "../../state/dispatch";
import { AlphaDispatch } from "../../state/slice/alpha.slice";
import { TDashboardMenu } from "../../types/dashboard-menu.type";
import "./sidebar.scss";

type TSidebarMenuProps = {
  menuItems?: TDashboardMenu[];
};

const menuArr = [];

const SidebarMenu: React.FC<TSidebarMenuProps> = ({
  menuItems = dashboardMenus,
}) => {
  const location = useLocation();

  const activeSidemenu = (path: string) => {
    // let flag = false;
    // let tmpPath = location.pathname.split("/", 2).join("/").toLowerCase();
    // let possiblePaths: string[] = [];
    // if (path == RoutePaths.Onboarding || tmpPath == RoutePaths.OnboardDetails)
    //   possiblePaths = [RoutePaths.Onboarding, RoutePaths.OnboardDetails];
    // else if (path == RoutePaths.Trial || tmpPath == RoutePaths.TrialDetails)
    //   possiblePaths = [RoutePaths.Trial, RoutePaths.TrialDetails];
    // else if (path == RoutePaths.Corporate || tmpPath == RoutePaths.CorporateDetails)
    //   possiblePaths = [RoutePaths.Corporate, RoutePaths.CorporateDetails];
    // else if (path == RoutePaths.Employees)
    //   possiblePaths = [RoutePaths.Employees];

    // possiblePaths = possiblePaths.map(i => i.toLowerCase())

    // if (possiblePaths.includes(tmpPath)) flag = true;
    return location.pathname.includes(path);
  };

  const Amplitude = (key: string) => {
    switch (key) {
      case dashboardMenus[0].label:
        AmplitudeInstance.logEvent(AmplitudeEvent.TABBAR_ONBOARDING, {
          timeStamp: new Date(),
        });
        break;
      case dashboardMenus[1].label:
        AmplitudeInstance.logEvent(AmplitudeEvent.TABBAR_CORPORATE, {
          timeStamp: new Date(),
        });
        break;
      case dashboardMenus[2].label:
        AmplitudeInstance.logEvent(AmplitudeEvent.TABBAR_EMPLOYEES, {
          timeStamp: new Date(),
        });
        break;
      case dashboardMenus[3].label:
        AmplitudeInstance.logEvent(AmplitudeEvent.TABBAR_TRIAL, {
          timeStamp: new Date(),
        });
        break;
      case dashboardMenus[4].label:
        AmplitudeInstance.logEvent(AmplitudeEvent.TABBAR_BATCH, {
          timeStamp: new Date(),
        });
        break;
      case dashboardMenus[5].label:
        AmplitudeInstance.logEvent(AmplitudeEvent.TABBAR_KIT, {
          timeStamp: new Date(),
        });
        break;
      case dashboardMenus[6].label:
        AmplitudeInstance.logEvent(AmplitudeEvent.TABBAR_MEMBERSHIPS, {
          timeStamp: new Date(),
        });
        break;

      default:
        break;
    }
  };

  return (
    <div className="menu-list-container">
      <ul className="menu-list">
        {menuItems.map((menu: TDashboardMenu, index: any) => {
          return (
            <li key={index} style={{ padding: 0 }}>
              <Link
                to={menu.path}
                className={classNames({
                  active: activeSidemenu(menu.path),
                })}
                onClick={() => {
                  Dispatch.Detax.hideUploadTransaction();
                  AlphaDispatch.setActiveTab(menu.path);
                }}
              >
                <img
                  src={menu.iconImagePath}
                  alt={menu.label}
                  width="24"
                  height="28"
                  className="pb-2 w-h-size"
                />
                <p> {menu.label} </p>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default SidebarMenu;
