import { isSuccess } from "@devexperts/remote-data-ts";
import { format } from "date-fns";
import xlsx from "json-as-xlsx";
import { useEffect, useState } from "react";
import { IMPSFeesData } from "../../../../api/detax/model/impsFeesRes";
import { SettingsService } from "../../../../services/settings.service";
import { currencyFormat } from "../../../../utils/format.utils";
import { IDateRange } from "../../../admin/all-corporate-employees/all-employees";
import ReuseIcon from "../../../common/reuse-icons/reuse-icons";
import DateRangeDropdown from "../common/date-range-dropdown";
import NoTableData from "../common/no-table-data";
import { ITableData, TablePagination } from "../common/table/table-pagination";

const ImpsFees = () => {
  const [data, setData] = useState<Array<IMPSFeesData>>();
  const [filterDate, setFilterDate] = useState<IDateRange>({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(),
  });
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    getIMPSFees(
      format(new Date(filterDate.startDate), "dd-MM-yyyy"),
      format(new Date(filterDate.endDate), "dd-MM-yyyy"),
      paging.page,
      paging.limit
    );
  }, [paging.page, paging.limit, filterDate]);

  const getIMPSFees = async (
    from: string,
    to: string,
    pn: number,
    rc: number,
    download?: boolean
  ) => {
    const result = await SettingsService.getIMPSFees(from, to, pn, rc);
    if (isSuccess(result)) {
      if (result.value.successful) {
        const updatedData = result.value.data?.map((x) => ({
          ...x,
          transferAmount: (x.transferAmount ?? 0) - (x.convenienceFee ?? 0),
        }));

        if (download) {
          exportToExcel(updatedData);
        } else {
          setData(updatedData);
          setPaging({ ...paging, total: result.value.recordCount ?? 0 });
        }
      }
    }
  };

  const exportToExcel = async (data?: Array<IMPSFeesData>) => {
    let cols: string[] = ["Employee Name", "Email", "Date", "Amount", "Fees"];

    let content: any[] = [];
    data &&
      data.map((item, index) => {
        content = [
          {
            ["Employee Name"]:
              item.customerName === "" ? "-" : item.customerName,
            ["Email"]: item.customerEmail === "" ? "-" : item.customerEmail,
            ["Date"]: item.date === "" ? "-" : item.date,
            ["Amount"]:
              item.transferAmount === 0
                ? "-"
                : currencyFormat(item.transferAmount ?? 0),
            ["Fees"]:
              item.convenienceFee === 0
                ? "-"
                : currencyFormat(item.convenienceFee ?? 0),
          },
          ...content,
        ];
      });

    let output = [
      {
        sheet: "Sheet1",
        columns: cols.map((item) => ({ label: item, value: item })),
        content,
      },
    ];
    xlsx(output, { fileName: "IMPS Fees Details" });
  };

  return (
    <div>
      <h1 className="page-heading fw-bold">IMPS Fees</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignSelf: "stretch",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
        className="mb-1"
      >
        <div className="m-1">
          <DateRangeDropdown
            startDate={filterDate.startDate}
            endDate={filterDate.endDate}
            onChange={(data: IDateRange) => setFilterDate(data)}
          />
        </div>
        <ReuseIcon className="">
          <svg
            className="comman-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="47.54"
            height="43.884"
            viewBox="0 0 47.54 43.884"
            onClick={() => {
              getIMPSFees(
                format(new Date(filterDate.startDate), "dd-MM-yyyy"),
                format(new Date(filterDate.endDate), "dd-MM-yyyy"),
                1,
                100000,
                true
              );
            }}
          >
            <path
              id="download"
              d="M36.57,38.4a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,36.57,38.4Zm7.313,0a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,43.883,38.4ZM47.54,32v9.142A2.732,2.732,0,0,1,44.8,43.884H2.743a2.645,2.645,0,0,1-1.943-.8A2.645,2.645,0,0,1,0,41.141V32a2.645,2.645,0,0,1,.8-1.943,2.645,2.645,0,0,1,1.943-.8H16.028l3.857,3.885a5.516,5.516,0,0,0,7.77,0l3.885-3.885H44.8A2.732,2.732,0,0,1,47.54,32ZM38.255,15.743a1.648,1.648,0,0,1-.4,2l-12.8,12.8a1.8,1.8,0,0,1-2.572,0l-12.8-12.8a1.648,1.648,0,0,1-.4-2,1.708,1.708,0,0,1,1.685-1.115h7.313V1.829A1.758,1.758,0,0,1,18.826.543,1.758,1.758,0,0,1,20.112,0h7.313a1.758,1.758,0,0,1,1.286.543,1.758,1.758,0,0,1,.543,1.286v12.8h7.313a1.706,1.706,0,0,1,1.685,1.115Z"
              fill="#103458"
            />
          </svg>
        </ReuseIcon>
      </div>
      <div>
        {data && data.length > 0 ? (
          <TablePagination
            data={paging}
            changePage={(data) => setPaging((p) => ({ ...p, page: data }))}
            changeLimit={(data) =>
              setPaging((p) => ({ ...p, limit: data, page: 1 }))
            }
          >
            <table>
              <thead>
                <tr>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Date</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Fees</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, key) => (
                  <tr key={key}>
                    <td>{item.customerName}</td>
                    <td>{item.customerEmail}</td>
                    <td>{item.date}</td>
                    <td>{currencyFormat(item.transferAmount ?? 0)}</td>
                    <td>{currencyFormat(item.convenienceFee ?? 0)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TablePagination>
        ) : (
          <NoTableData />
        )}
      </div>
    </div>
  );
};

export default ImpsFees;
