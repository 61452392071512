import { isSuccess } from "@devexperts/remote-data-ts";
import { useEffect, useState } from "react";
import { OnboardingService } from "../../../services/onboarding.service";
import { TOnBoardingApplicationCountInfo } from "../../../types/admin/onboarding-application-count.type";
import { addAllDashboardCount } from "../../../utils/dashboard.utils";
import { unWrapOption } from "../../../utils/option.utils";
import PageTitle from "../../common/page-title";
import Heading from "../heading";
import OnboardSearch from "./onboard-search";
import OnboardStatus from "./onboard-status";
import OnboardTable from "./onboard-table";

const Onboarding = () => {
  const [selectedState, setSelectedState] = useState<string>("");
  const [counts, setCounts] = useState<TOnBoardingApplicationCountInfo[]>([]);
  const [searchVal, setSearchVal] = useState("");
  const [search, setSearch] = useState(false);
  useEffect(() => {
    const run = async () => {
      const result = await OnboardingService.onboardingCounts();

      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          const countsObj = addAllDashboardCount(result.value.data);
          setCounts(countsObj);
          setSelectedState("ALL");
        }
      }
    };
    run();
  }, []);

  return (
    <>
      <PageTitle title="Onboardings" />
      <Heading title="Onboardings" />
      <OnboardSearch
        searchVal={searchVal}
        setSearchVal={setSearchVal}
        onSearch={() => {
          setSearch(!search);
        }}
      />
      <OnboardStatus
        onSelectState={setSelectedState}
        selectedState={selectedState}
        counts={counts}
      />
      <OnboardTable
        searchVal={searchVal}
        selectedState={selectedState}
        search={search}
      />
    </>
  );
};

export default Onboarding;
