import classNames from "classnames";
import React from "react";
import { RoutePaths } from "../../../utils/route.utils";
import Heading from "../heading";
import { data } from "./onboard-table";

const OnboardHeader: React.FC<{
  approveEnable: boolean;
  detail?: string;
  status?: string;
  setShowRejectPopup?: (show: boolean) => void;
  setShowApprovePopup?: (show: boolean) => void;
}> = ({
  detail,
  status,
  setShowRejectPopup = (show: boolean) => {},
  setShowApprovePopup = (show: boolean) => {},
  approveEnable,
}) => {
  return (
    <div className="d-flex justify-content-between my-2" id="sub-header">
      <Heading
        title="Onboarding"
        detail={detail}
        status={data.find((obj) => obj.state === status)?.label ?? "Error"}
        links={[RoutePaths.NavigateToOnboarding]}
      />
      <div className="d-flex justify-content-center align-items-center">
        {status && status === "REVIEW_PENDING" && (
          <>
            <button
              disabled={!approveEnable}
              onClick={(e) => setShowApprovePopup(true)}
              className={`btn border-0 px-4 text-white  ms-2 ${classNames({
                ["bg-success"]: approveEnable,
                ["bg-secondary"]: !approveEnable,
              })}`}
            >
              Approve
            </button>
            <button
              onClick={(e) => setShowRejectPopup(true)}
              className="btn bg-danger border-0 px-4 text-white  ms-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              type="button"
            >
              Reject
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default OnboardHeader;
