import classnames from "classnames";
import { FieldHookConfig, useField } from "formik";
import React from "react";
import { images } from "../../../../utils/staticData";
import s from "./formik-input-group.module.scss";

interface Props {
  icon?: keyof typeof images;
  color?: "bg-lightpink" | "bg-lightblue" | "bg-lightpurple";
  // onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

const FormikInputGroup: React.FC<
  Props &
    FieldHookConfig<string> &
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
> = ({
  icon = "building",
  color = "bg-lightblue",
  children,
  onChange,
  ...props
}) => {
  const [field, meta] = useField(props);

  const className = classnames(s["p-2"], {
    [s["ncash_bg-lightpink"]]: color === "bg-lightpink",
    [s["ncash_bg-lightblue"]]: color === "bg-lightblue",
    [s["ncash_bg-lightpurple"]]: color === "bg-lightpurple",
  });

  const image = Object.entries(images).find((x) => x[0] === icon)?.[1];

  return (
    <div>
      <div className="input-group border-1 rounded-15 ml-auto mr-auto">
        <span
          className="input-group-text ncash_w-group-wrapper input-field"
          id="basic-addon1"
        >
          <div className={className}>
            {" "}
            <img className="img-fluid icons w-50" src={image} alt="Building" />
          </div>
        </span>
        <input
          className={props.className}
          placeholder={props.placeholder}
          type={props.type}
          {...field}
          {...props}
        />
      </div>

      {meta.error && meta.touched && (
        <div className="fs-small ncash_validate-error text-center mt-2">
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default FormikInputGroup;
