import { isSuccess } from "@devexperts/remote-data-ts";
import {
  setIsAuthenticated,
  updatePageAfterLogin,
} from "../components/app/app.slice";
import { setLeadApiInfo } from "../components/company/company.slice";
import { LeadService } from "../services/lead.service";
import { OnboardingService } from "../services/onboarding.service";
import { store } from "../state/store";
import { unWrapOption } from "./option.utils";
import { RoutePaths } from "./route.utils";

export const getNextScreenFromReview = async (navigate: any) => {
  let screen = RoutePaths.WelcomeBack;
  const result = await OnboardingService.getReview();
  if (isSuccess(result)) {
    const screen = unWrapOption(
      result.value.nextAction,
      () => RoutePaths.WelcomeBack
    );
    store.dispatch(updatePageAfterLogin(screen)); // this will save to be routed to after login in redux store
    store.dispatch(setIsAuthenticated(true));
  }

  navigate(screen);
};

export const getLeadInfo = async () => {
  const result = await LeadService.getLeadReview();
  console.log("review result: ", result);
  if (isSuccess(result)) {
    if (unWrapOption(result.value.successful, () => false)) {
      store.dispatch(setLeadApiInfo(result.value.data));
    } else {
      //setError(unWrapOption(result.value.message, () => "Invalid CIN"));
    }
  } else {
    // send to retry page/ error page
  }
};
