import React from "react";
import { SelectedServicesInfo } from "../../../api/admin/model/models";
import { AdminApplicationInfo } from "../../../api/corporate/model/adminApplicationInfo";
import WhiteCard from "../../white-card/white-card";
import ServicePopup from "./ServicePopup";
const Pencil = "/images/icons/edit.svg";
const ServiceSelected: React.FC<{
  // services: Array<CorporateServiceInfo>;
  selectedServices: SelectedServicesInfo[];
  setSelectedServices: (val: SelectedServicesInfo[]) => void;
  applicationStatus: string;
  editService: boolean;
  setEditService: (val: boolean) => void;
  id: string;
  details: AdminApplicationInfo;
}> = ({
  selectedServices,
  setSelectedServices,
  applicationStatus,
  setEditService,
  editService,
  id,
  details,
}) => {
  // const selectedServices = services
  //   ? services.map(OnboardingService.parseService)
  //   : [];
  let buttonView =
    selectedServices.filter((service) => service.selected).length < 3;

  return (
    <WhiteCard className="p-3">
      <div className="d-flex justify-content-between">
        <h6 className="text-secondary fw-bold">Service Selected</h6>
        <p></p>
        {applicationStatus ===
          AdminApplicationInfo.ApplicationStatusEnum.LIVE && (
          //  && buttonView
          <div
            className=" btn p-1 me-3 border border-2 rounded-2"
            onClick={() => {
              // setEdit(true);
            }}
          >
            <img
              src={Pencil}
              className="img-fluid ml-auto mr-auto"
              alt="icon"
              width="18"
              onClick={() => setEditService(true)}
            />
          </div>
        )}
      </div>

      <div className="row mx-2">
        <div className="col-12">
          <div className="row">
            {selectedServices
              .filter((obj) => obj.selected)
              .map((item, key) => {
                return (
                  <div key={key} className="col-md-4">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          {/* <img
                          src={item.img}
                          className="img-fluid ml-auto mr-auto w-50"
                          alt="icon"
                        /> */}

                          <span className="text-success me-2">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 20 20"
                              height="1.4em"
                              width="1.4em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left d-flex align-items-center">
                        <h6 className="mb-0  text-secondary fw-bold">
                          {item.name}
                        </h6>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <ServicePopup
        show={editService}
        closeModal={() => {}}
        actionState1={false}
        selectedServices={selectedServices}
        close={(val: boolean) => setEditService(val)}
        id={id}
        details={details}
      />
    </WhiteCard>
  );
};

export default ServiceSelected;
