import axios from "axios";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const PolicyDetailsModal: React.FC<{
  show: boolean;
  onClose: () => void;
  policyLink?: string;
}> = ({ show, onClose, policyLink }) => {
  const [file, setFile] = useState<File | null>(null);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const onLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const onPreviousPage = () => {
    setPageNumber((prev) => Math.max(prev - 1, 1));
  };

  const onNextPage = () => {
    setPageNumber((prev) => Math.min(prev + 1, numPages));
  };

  useEffect(() => {
    if (policyLink) {
      downloadFile(policyLink);
    }
  }, [policyLink]);

  const downloadFile = async (link: string) => {
    try {
      const response = await axios.get(link, {
        responseType: "blob", // Ensure the response is treated as binary data.
      });

      const downloadedFile = new File([response.data], "Policy- Details.pdf", {
        type: "application/pdf",
      });

      setFile(downloadedFile);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const downloadPDF = () => {
    if (file) {
      // Create a URL for the file object
      const url = URL.createObjectURL(file);

      // Create a link and trigger download
      const link = document.createElement("a");
      link.href = url;
      link.download = file.name; // Use the original file name
      link.click();

      // Release the created URL after the download starts
      URL.revokeObjectURL(url);
    }
  };

  console.log("File", file);

  return (
    <Modal show={show} onHide={() => onClose()} size="lg" centered>
      <style>{`.modal-dialog { max-width: ${630}px;}`}</style>
      <Modal.Header closeButton className="pt-3 pb-2">
        <Modal.Title className=" fs-5 fw-bold">Policy Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0 pb-3">
        {file && (
          <div
            className={`wrap-icon`}
            style={{
              alignSelf: "stretch",
              display: "flex",
              alignItems: "center",
              // backgroundColor: "red",
              justifyContent: "flex-end",
              boxShadow: "none",
            }}
          >
            <svg
              className="comman-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="47.54"
              height="43.884"
              viewBox="0 0 47.54 43.884"
              onClick={() => {
                downloadPDF();
                setPageNumber(1);
                setNumPages(0);
              }}
            >
              <path
                id="download"
                d="M36.57,38.4a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,36.57,38.4Zm7.313,0a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,43.883,38.4ZM47.54,32v9.142A2.732,2.732,0,0,1,44.8,43.884H2.743a2.645,2.645,0,0,1-1.943-.8A2.645,2.645,0,0,1,0,41.141V32a2.645,2.645,0,0,1,.8-1.943,2.645,2.645,0,0,1,1.943-.8H16.028l3.857,3.885a5.516,5.516,0,0,0,7.77,0l3.885-3.885H44.8A2.732,2.732,0,0,1,47.54,32ZM38.255,15.743a1.648,1.648,0,0,1-.4,2l-12.8,12.8a1.8,1.8,0,0,1-2.572,0l-12.8-12.8a1.648,1.648,0,0,1-.4-2,1.708,1.708,0,0,1,1.685-1.115h7.313V1.829A1.758,1.758,0,0,1,18.826.543,1.758,1.758,0,0,1,20.112,0h7.313a1.758,1.758,0,0,1,1.286.543,1.758,1.758,0,0,1,.543,1.286v12.8h7.313a1.706,1.706,0,0,1,1.685,1.115Z"
                fill="#103458"
              />
            </svg>
          </div>
        )}
        {/* <input
            type="file"
            onChange={(e) => {
              const selectedFile = e.target.files?.[0];
              if (selectedFile) {
                // setFile(URL.createObjectURL(selectedFile)); // Create a temporary URL for the uploaded PDF
                setFile(selectedFile); // Create a temporary URL for the uploaded PDF
              }
            }}
          /> */}
        <div style={{ overflow: "auto" }}>
          <Document
            file={file}
            onLoadSuccess={onLoadSuccess}
            onLoadError={(error) => console.error("Error loading PDF:", error)}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </div>
        {file && (
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              onClick={onPreviousPage}
              disabled={pageNumber <= 1}
              className="btn-gradiant-blue text-white px-3 py-2 me-2"
            >
              Previous
            </button>
            <span>
              Page {pageNumber} of {numPages}
            </span>
            <button
              onClick={onNextPage}
              disabled={pageNumber >= numPages}
              className="btn-gradiant-blue text-white px-3 py-2 ms-2"
            >
              Next
            </button>
          </div>
        )}
        {/* <div
            className="d-flex flex-row justify-content-between  mt-2 w-100"
            style={{ border: "1px #D6D6D6 solid", borderRadius: 6, padding: 16 }}
          >
            <div style={{ width: "50%", maxHeight: "70vh", overflow: "auto" }}>
              <p className="m-0 fw-bold " style={{ fontSize: 18 }}>
                INCLUSIONS
              </p>
              {data?.inclusions &&
                Object.entries(data?.inclusions).map(([key, val]) => (
                  <div className="mt-2">
                    <p className="m-0"> {key}</p>
                    <p className="m-0" style={{ color: "#6D6D6D" }}>
                      {val}
                    </p>
                  </div>
                ))}
            </div>
            <div style={{ border: "1px solid #D6D6D6" }} className="ms-4 me-4" />
            <div style={{ width: "50%", maxHeight: "70vh", overflow: "auto" }}>
              <p className="m-0 fw-bold " style={{ fontSize: 18 }}>
                EXCLUSIONS
              </p>
              {data?.exclusions &&
                Object.entries(data?.exclusions).map(([key, val]) => (
                  <div className="mt-2">
                    <p className="m-0"> {key}</p>
                    <p className="m-0" style={{ color: "#6D6D6D" }}>
                      {val}
                    </p>
                  </div>
                ))}
            </div>
          </div> */}
      </Modal.Body>
    </Modal>
  );
};

export default PolicyDetailsModal;
