import React from "react";
import { Button } from "react-bootstrap";
import { fromPublic } from "../../../utils/common.utils";
import CustomSearchBar from "../../custom-search/custom-search";
import WhiteCard from "../../white-card/white-card";
const Filter = fromPublic("/images/filter.svg");
interface IDateRange {
  startDate?: any;
  endDate?: any;
}
const AllEmployeesSearch: React.FC<{
  searchVal: string;
  setSearchVal: (val: string) => void;
  onSearch?: () => void;
  searchType: string;
  setSearchType: (val: string) => void;
}> = ({
  searchVal,
  setSearchVal,
  searchType,
  setSearchType,
  onSearch = () => {},
}) => {
  return (
    <WhiteCard className="mb-4 mt-2 p-1 rounded">
      <div className="d-flex justify-content-between">
        <CustomSearchBar
          value={searchVal}
          onChange={(val: any) => setSearchVal(val)}
          boxShadow="none"
          height="auto"
          width="70%"
          text={
            searchType === "name"
              ? "Search by Name"
              : searchType === "email"
              ? "Search by E-mail"
              : "Search by Mobile"
          }
          onEnter={() => {
            onSearch();
          }}
        />

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
          className="me-2"
        >
          <select
            className="form-control m-1 fs-small"
            value={searchType}
            onChange={(e) => {
              setSearchType(e.target.value);
              if (searchVal.length > 0) {
                onSearch();
              }
            }}
            style={{
              width: 120,
              appearance: "auto",
              // border: "auto",
              // background: "initial",
              // padding: "initial",
              paddingLeft: 7,
            }}
          >
            <option value={"name"}>Name</option>
            <option value={"email"}>Email</option>
            <option value={"mobile"}>Mobile</option>
          </select>
          <Button
            className="btn-gradiant-blue btn-lg text-white fs-small m-1 me-2 ms-2"
            onClick={() => onSearch()}
          >
            Search
          </Button>
          {/* <DateRangeDropdown
            startDate={filterDate.startDate}
            endDate={filterDate.endDate}
            onChange={(data: IDateRange) => setFilterDate(data)}
          /> */}
        </div>
      </div>
    </WhiteCard>
  );
};

export default AllEmployeesSearch;
