/**
 * Ncaish Admin API
 * Apis to super admin operations
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ApplicantInfo } from "./applicantInfo";

export interface AdminCorporateBasicInfo {
  id?: string;
  corpCode?: string;
  companyName?: string;
  cin?: string;
  applicantInfo?: ApplicantInfo;
  status?: AdminCorporateBasicInfo.StatusEnum;
  createdAt?: string;
  updatedAt?: string;
}
export namespace AdminCorporateBasicInfo {
  export type StatusEnum = "ACTIVE" | "INACTIVE" | "BLOCKED";
  export const StatusEnum = {
    ACTIVE: "ACTIVE" as StatusEnum,
    INACTIVE: "INACTIVE" as StatusEnum,
    BLOCKED: "BLOCKED" as StatusEnum,
  };
}
