import { isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { MultiValue } from "react-select";
import { AmplitudeInstance } from "../../..";
import {
  AdminApplicationInfo,
  CorporateServiceInfo,
  SelectedServicesInfo,
} from "../../../api/admin/model/models";
import { AmplitudeEvent } from "../../../enum/amplitude";
import { OnboardingService } from "../../../services/onboarding.service";

const TickIcon = "/images/tick.svg";
const TaxIcon = "/images/tax.png";
const FXIcon = "/images/fx.png";
const NpsIcon = "/images/nps.png";
const InsuranceIcon = "/images/shieldBlack.svg";

export interface CustomOptionType {
  value: string;
  label: string;
}

interface IProtectData {
  insurer: string;
  imidNo: string;
  customerId: number;
  policyNum: string;
  cdbgAccNum: string;
  startDate: string;
  endDate: string;
  inclusions: string;
  exclusions: string;
  depentCount: string;
  type: MultiValue<CustomOptionType>;
  relationship: MultiValue<CustomOptionType>; // Assuming relationship is an array of strings
  grades: Map<string, number>;
}

const ServicePopup: React.FC<{
  actionState1: boolean;
  show: boolean;
  closeModal: () => void;
  selectedServices: SelectedServicesInfo[];
  id: string;
  close: (val: boolean) => void;
  details: AdminApplicationInfo;
}> = ({
  show,
  closeModal,
  actionState1,
  selectedServices,
  id,
  close,
  details,
}) => {
  const Image = (data: string) => {
    if (data === CorporateServiceInfo.TypeEnum.DETAX_CARD) {
      return TaxIcon;
    } else if (data === CorporateServiceInfo.TypeEnum.FX) {
      return FXIcon;
    } else if (data === CorporateServiceInfo.TypeEnum.NPS) {
      return NpsIcon;
    } else if (data === CorporateServiceInfo.TypeEnum.PROTECTION) {
      return InsuranceIcon;
    }
  };

  const [service, setService] = useState<SelectedServicesInfo[]>([]);

  useEffect(() => {
    setService(selectedServices);
  }, [show]);
  let body = service
    .filter((service) => service.selected)
    .map((service) => service.type);

  const updateSelectServices = async (closePopup?: boolean) => {
    const result = await OnboardingService.updateService(body, id);
    if (isSuccess(result)) {
      if (closePopup) {
        close(false);
        window.location.reload();
        AmplitudeInstance.logEvent(AmplitudeEvent.UPDATE_SERVICE_SELECTED, {
          timeStamp: new Date(),
          applicationName: details.corporateInfo?.name,
          applicationCode: details.appCode,
          request: body,
          id: id,
        });
      }
      //   setSelectedServices(result.value.data);
    } else {
      // setAppState(initialState);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          closeModal();
          close(false);
          // setSelectedServices(selectedServices);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select the Services</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {service.map((obj, index) => (
              <>
                <div
                  style={{
                    border: obj.selected
                      ? "1.5px solid #2294E5"
                      : "1.5px solid #A9A9A9",
                    boxShadow: "3px 3px 6px rgba(0, 0, 0, 0.16)",
                    borderRadius: 8,
                    cursor: "pointer",
                  }}
                  className="m-2 d-flex col justify-content-between p-2 align-items-center"
                  onClick={() => {
                    const updatedServices = [...service]; // Create a copy of the array
                    updatedServices[index] = {
                      ...updatedServices[index],
                      selected: !obj.selected,
                    }; // Update the selected property
                    setService(updatedServices); // Update the state
                  }}
                >
                  <div
                    style={{
                      width: "10%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    className="p-2"
                  >
                    <img
                      src={Image(obj.type)}
                      className="img-fluid ml-auto mr-auto"
                    />
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      width: "90%",
                    }}
                  >
                    <div>
                      <b>{obj.name}</b>
                    </div>
                    <div style={{ fontSize: 12, color: "#929292" }}>
                      {obj.description}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "10%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {obj.selected && <img src={TickIcon} />}
                  </div>
                </div>
              </>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-gradiant-blue px-3 py-2 font-14 mr-auto text-white"
            size="sm"
            type="button"
            onClick={() => {
              updateSelectServices(true);
            }}
            disabled={false}
          >
            {actionState1 && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
            )}
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ServicePopup;
