/**
 * Ncaish Admin API
 * Apis to super admin operations
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface LoginResponse { 
    successful?: boolean;
    message?: string;
    redirection?: LoginResponse.RedirectionEnum;
    loginId?: string;
}
export namespace LoginResponse {
    export type RedirectionEnum = 'SIGNUP' | 'SIGNUP_VERIFICATION' | 'COMPANY_REVIEW' | 'CONTRACT_RECEIPIENT' | 'SELECT_SERVICES' | 'APPLICATION_REVIEW' | 'LEAD_FORM' | 'LEAD_REVIEW' | 'LEAD_THANK_YOU' | 'APPLICATION_SUBMITTED' | 'DASHBOARD' | 'LOGIN' | 'LOGIN_VERIFICATION' | 'FX_RETAIL' | 'TRIAL_EXPIRED' | 'SEND_REDIRECT' | 'LEAD_VERIFICATION';
    export const RedirectionEnum = {
        SIGNUP: 'SIGNUP' as RedirectionEnum,
        SIGNUPVERIFICATION: 'SIGNUP_VERIFICATION' as RedirectionEnum,
        COMPANYREVIEW: 'COMPANY_REVIEW' as RedirectionEnum,
        CONTRACTRECEIPIENT: 'CONTRACT_RECEIPIENT' as RedirectionEnum,
        SELECTSERVICES: 'SELECT_SERVICES' as RedirectionEnum,
        APPLICATIONREVIEW: 'APPLICATION_REVIEW' as RedirectionEnum,
        LEADFORM: 'LEAD_FORM' as RedirectionEnum,
        LEADREVIEW: 'LEAD_REVIEW' as RedirectionEnum,
        LEADTHANKYOU: 'LEAD_THANK_YOU' as RedirectionEnum,
        APPLICATIONSUBMITTED: 'APPLICATION_SUBMITTED' as RedirectionEnum,
        DASHBOARD: 'DASHBOARD' as RedirectionEnum,
        LOGIN: 'LOGIN' as RedirectionEnum,
        LOGINVERIFICATION: 'LOGIN_VERIFICATION' as RedirectionEnum,
        FXRETAIL: 'FX_RETAIL' as RedirectionEnum,
        TRIALEXPIRED: 'TRIAL_EXPIRED' as RedirectionEnum,
        SENDREDIRECT: 'SEND_REDIRECT' as RedirectionEnum,
        LEADVERIFICATION: 'LEAD_VERIFICATION' as RedirectionEnum
    };
}

