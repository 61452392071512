import { isSuccess } from "@devexperts/remote-data-ts";
import { none } from "fp-ts/lib/Option";
import { MembershipService } from "../../../../services/membership.service";
import { Dispatch } from "../../../../state/dispatch";
import { MembershipDispatch } from "../../../../state/slice/membership.slice";
import { unWrapOption } from "../../../../utils/option.utils";

export const fetchMembershipBatchDetail = async (
  cId: string,
  membershipId: string
) => {
  Dispatch.App.setCommonError({
    showError: false,
    errorText: "",
  });

  MembershipDispatch.setBatchInfo(none);

  const result =
    await MembershipService.getMembershipBatchDetailByBatchAndCorpId(
      cId,
      membershipId
    );

  if (isSuccess(result)) {
    if (unWrapOption(result.value.successful, () => false)) {
      MembershipDispatch.setBatchInfo(result.value.data);
    } else {
      Dispatch.App.setCommonError({
        showError: true,
        errorText: unWrapOption(result.value.message, () => ""),
      });
    }
  } else {
    console.log("Error BatchDetailsByBatchIdandCIdmembership: ", result);
  }
};

export const updateMembershipBatchStatus = async (
  cId: string,
  membershipId: string,
  status: string,
  amtRecieved?: number,
  transId?: string,
  transDate?: string,
  paymentMadeDate?: String
) => {
  Dispatch.App.setCommonError({
    showError: false,
    errorText: "",
  });

  const result =
    await MembershipService.updateMembershipBatchDetailByBatchAndCorpId(
      cId,
      membershipId,
      status,
      amtRecieved,
      transId,
      transDate,
      paymentMadeDate
    );

  if (isSuccess(result)) {
    if (unWrapOption(result.value.successful, () => false)) {
      MembershipDispatch.setBatchInfo(result.value.data);
    } else {
      Dispatch.App.setCommonError({
        showError: true,
        errorText: unWrapOption(result.value.message, () => ""),
      });
    }
  } else {
    console.log("Error BatchDetailsByBatchIdandCIdmembership: ", result);
  }
};

export const getMembershipSubscriptionDetail = async (cId: string) => {
  Dispatch.App.setCommonError({
    showError: false,
    errorText: "",
  });

  const result = await MembershipService.getMembershipSubcriptionListWithCId(
    cId
  );

  if (isSuccess(result)) {
    if (unWrapOption(result.value.successful, () => false)) {
      // return result;
    } else {
      Dispatch.App.setCommonError({
        showError: true,
        errorText: unWrapOption(result.value.message, () => ""),
      });
      // return none;
    }
  } else {
    console.log("Error BatchDetailsByBatchIdandCIdmembership: ", result);
    // return none;
  }
};
