/**
 * Corporate API
 * Apis to manage corporate onboarding and operations
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import { RemoteData } from "@devexperts/remote-data-ts";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { ApiClient } from "./api-client";
import { APIError } from "./errors";

//@ts-ignore
//import * as models from '../model/models';
import { CorpApplicationApiResponse } from "../model/corpApplicationApiResponse";
import { CorpServicesApiResponse } from "../model/corpServicesApiResponse";
import { CorpUpdateServiceApiResponse } from "../model/corpUpdateServiceApiResponse";
import { GetContractReceipientApiResponse } from "../model/getContractReceipientApiResponse";
import { SendBrResponse } from "../model/sendBrResponse";
import { UpdateCorporateReceipientApiRequest } from "../model/updateCorporateReceipientApiRequest";
import { UpdateServiceApiRequest } from "../model/updateServiceApiRequest";
import { UpdateSignatoriesApiResponse } from "../model/updateSignatoriesApiResponse";
import { ValidateCinRequest } from "../model/validateCinRequest";
import { ValidateCorpApiResponse } from "../model/validateCorpApiResponse";
import { ListOfSelectedCategoriesApiReq } from "./admin/model/onboarding/listOfSelectedCategoryApiReq";
import { UpdateClaimsFlowApiReq } from "./admin/model/onboarding/updateClaimsFlowApiReq";
import { GetUserCategoryDataResponse } from "../model/getUserCategoryResponse";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CorporateOnboardingApiService {
  /**
   * Api to get already linked receipient(signatories and directors) for application
   */
  export const getContractReceipient = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetContractReceipientApiResponse>> => {
    const path = `/api/secure/corporates/onboarding/contractRec`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getUserCategoryData = async (
    cid: string,
    catId: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetUserCategoryDataResponse>> => {
    const path = `/secure/detax/${cid}/get-users/categories-data`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [{ key: "catId", value: catId }];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const updateUserCategoryData = async (
    cid: string,
    catId: string,
    type: string,
    uid: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetUserCategoryDataResponse>> => {
    const path = `/secure/detax/customise/categories`.replace(/^\//, "");

    const allQueryParams: TQueryParam[] = [
      { key: "cid", value: cid },
      { key: "type", value: type },
      { key: "catId", value: catId },
      { key: "uid", value: uid },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      headers: { ...headers },
    };
    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Temporary api to mark any account as live
   */
  export const goLive = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CorpApplicationApiResponse>> => {
    const path = `/api/secure/corporates/onboarding/goLive`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to fetch my application details
   */
  export const myApplication = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CorpApplicationApiResponse>> => {
    const path = `/api/secure/corporates/onboarding/review`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to send BR to linked receipients through docusign
   * @param accountId -
   */
  export const sendBr = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, SendBrResponse>> => {
    const path = `/api/secure/corporates/onboarding/sendBr`.replace(/^\//, "");
    //.replace("{" + "accountId" + "}", String(accountId));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to link services with application
   * @param body -
   */
  export const services = async (
    body: UpdateServiceApiRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CorpUpdateServiceApiResponse>> => {
    const path = `/api/secure/corporates/onboarding/services`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to set categories to corporate while approval
   */
  export const setCorporateCategory = async (
    categories: ListOfSelectedCategoriesApiReq,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CorpUpdateServiceApiResponse>> => {
    const path = `/secure/corporates/applications/categories/update`;
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");
    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: categories,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to update Add categories to corporate while live
   */

  export const updateAddCorporateCategory = async (
    id: string,
    categories: ListOfSelectedCategoriesApiReq,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CorpUpdateServiceApiResponse>> => {
    const path = `/secure/corporates/update/${encodeURIComponent(
      String(id)
    )}/category`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: categories,
      headers: { ...headers },
    };
    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to business unit to corporate while live
   */

  export const updateBusinessUnit = async (
    id: string,
    businessUnit: Array<string>,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CorpUpdateServiceApiResponse>> => {
    const path = `/secure/corporates/update/admin/bu/${encodeURIComponent(
      String(id)
    )}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: businessUnit,
      headers: { ...headers },
    };
    return await ApiClient.request(axiosRequestConfig);
  };

  export const updateClaimsFlow = async (
    id: string,
    claimFlowData: UpdateClaimsFlowApiReq,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CorpUpdateServiceApiResponse>> => {
    const path = `/secure/admin/enable/new/claims/${encodeURIComponent(
      String(id)
    )}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: claimFlowData,
      headers: { ...headers },
    };
    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to update remove categories to corporate while live
   */

  export const updateDeleteCorporateCategory = async (
    id: string,
    categories: ListOfSelectedCategoriesApiReq,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CorpUpdateServiceApiResponse>> => {
    const path = `/secure/corporates/remove/${encodeURIComponent(
      String(id)
    )}/category`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: categories,
      headers: { ...headers },
    };
    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to get linked services with application
   */
  export const services1 = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CorpServicesApiResponse>> => {
    const path = `/api/secure/corporates/onboarding/services`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to link receipient(signatories and directors) for application
   * @param body -
   */
  export const updateSignatories = async (
    body: UpdateCorporateReceipientApiRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, UpdateSignatoriesApiResponse>> => {
    const path = `/api/secure/corporates/onboarding/contractRec`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to validate company cin
   * @param request -
   */
  export const validateCin = async (
    request: ValidateCinRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ValidateCorpApiResponse>> => {
    const path = `/api/secure/corporates/onboarding/validate`.replace(
      /^\//,
      ""
    );
    // const allQueryParams: TQueryParam[] = [
    //   {
    //     key: "request",
    //     value: request,
    //   },
    // ];
    // const queryParams = allQueryParams
    //   .map((param) => `${param.key}=${param.value}`)
    //   .join("&");

    const apiUrl = path.concat(`?cin=${request.cin}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}

//export default new CorporateOnboardingApiService()
