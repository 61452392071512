import { isSuccess } from "@devexperts/remote-data-ts";
import { Option, isSome, none, some } from "fp-ts/lib/Option";
import { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AmplitudeInstance } from "../../..";
import { ActivateDetaxRequest } from "../../../api/admin/model/activateDetaxReq";
import { AdminApplicationBasicInfo } from "../../../api/admin/model/adminApplicationBasicInfo";
import {
  AdminApplicationInfo,
  SelectedServicesInfo,
} from "../../../api/admin/model/adminApplicationInfo";
import {
  AdminInsuranceInfoData,
  AdminInsuranceInfoReq,
} from "../../../api/admin/model/adminInsuranceInfo";
import {
  ApplicationApprovalRequest,
  DetaxReq,
  NpsReq,
} from "../../../api/admin/model/applicationApprovalRequest";
import { BrReceipientInfo } from "../../../api/admin/model/brReceipientInfo";
import { CorporateServiceInfo } from "../../../api/admin/model/corporateServiceInfo";
import { CorporateUser } from "../../../api/admin/model/corporateUser";
import { InsuranceProvidersData } from "../../../api/admin/model/insuranceProvidersRes";
import { GetEmployeesDataEmployeesRes } from "../../../api/admin/model/maxLimitResponse";
import { AdminListOfCategories } from "../../../api/admin/model/onboarding/adminListOfCategories";
import { ListOfSelectedCategoriesApiReq } from "../../../api/admin/model/onboarding/listOfSelectedCategoryApiReq";
import { UpdateClaimsFlowApiReq } from "../../../api/admin/model/onboarding/updateClaimsFlowApiReq";
import { AmplitudeEvent } from "../../../enum/amplitude";
import { ProtectionType } from "../../../enum/protection-type";
import { TAllAvailableCategories } from "../../../models/onboarding.models";
import PageLoader from "../../../page-loader";
import { CorporateService } from "../../../services/corporate.service";
import { OnboardingService } from "../../../services/onboarding.service";
import { ProtectionService } from "../../../services/protection.service";
import { unWrapOption } from "../../../utils/option.utils";
import PageTitle from "../../common/page-title";
import WhiteCard from "../../white-card/white-card";
import ApprovePopup from "../common/approve-popup";
import BrInfo from "../common/br-info";
import CompanyDetails from "../common/company-details";
import RejectPopup from "../common/reject-popup";
import ServiceSelected from "../common/service-selected";
import UpdatePopup from "../common/update-popup";
import UserDetailsInfo from "../common/user-details-info";
import CardVariant from "./common/card-variant";
import CategorySelection from "./common/category-selection";
import PostApprovalClaimSelection from "./common/post-approval-claim-selection";
import PreApprovalClaimSelection from "./common/pre-approval-claims-selection";
import SetMaxLimit from "./common/set-max-limit";
import OnboardHeader from "./onboard-header";
import OnboardServiceNps from "./onboard-service-nps";
import OnboardServiceProtect from "./onboard-service-protect";
import OnboardServiceTab from "./onboard-service-tab";

export interface OptionDrop {
  label: string;
  value: string;
}

interface AppAction {
  type:
    | "appState"
    | "adminMaker"
    | "defaultApprover"
    | "corporateAdmin"
    | "businessUnit"
    | "corporateAdminList"
    | "defaultApproverList"
    | "adminMakerList"
    | "isNewClaimFlow"
    | "optedNewClaimFlow"
    | "claimsUserInfo"
    | "defaultBUCount";
  payload: AppState;
}

const selectCategory = (
  catList: TAllAvailableCategories[]
): AdminListOfCategories[] => {
  let val: AdminListOfCategories[] = [];
  catList.map((d) => {
    if (d.isSelected) {
      if (
        isSome(d.fbaCategoryId) &&
        isSome(d.name) &&
        isSome(d.rule) &&
        isSome(d.newRegime)
      ) {
        const data: AdminListOfCategories = {
          fbaCategoryId: d.fbaCategoryId.value,
          name: d.name.value,
          rule: d.rule.value,
          newRegime: d.newRegime.value,
        };
        val = [...val, data];
      } else {
      }
    }
  });
  return val;
};

interface AppState {
  adminMaker: Option<CorporateUser>;
  defaultApprover: Option<CorporateUser>;
  corporateAdmin: Option<CorporateUser>;
  adminMakerList: Array<CorporateUser>;
  defaultApproverList: Array<CorporateUser>;
  corporateAdminList: Array<CorporateUser>;
  businessUnit: string[];
  isNewClaimFlow: boolean;
  optedNewClaimFlow: Option<boolean>;
  claimsUserInfo: Option<Array<CorporateUser>>;
  defaultBUCount: number;
}

function appReducer(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case "appState": {
      const appState = action.payload;
      return appState;
    }

    case "adminMaker": {
      const adminMaker = action.payload.adminMaker;
      return { ...state, adminMaker };
    }

    case "defaultApprover": {
      const defaultApprover = action.payload.defaultApprover;
      return { ...state, defaultApprover };
    }

    case "corporateAdmin": {
      const corporateAdmin = action.payload.corporateAdmin;
      return { ...state, corporateAdmin };
    }
    case "businessUnit": {
      const businessUnit = action.payload.businessUnit;

      return { ...state, businessUnit };
    }

    case "corporateAdminList": {
      const corporateAdminList = action.payload.corporateAdminList;
      return {
        ...state,
        corporateAdminList,
      };
    }

    case "isNewClaimFlow": {
      const isNewClaimFlow = action.payload.isNewClaimFlow;
      return {
        ...state,
        isNewClaimFlow,
      };
    }

    case "defaultApproverList": {
      const defaultApproverList = action.payload.defaultApproverList;
      return {
        ...state,
        defaultApproverList,
      };
    }

    case "adminMakerList": {
      const adminMakerList = action.payload.adminMakerList;
      return { ...state, adminMakerList };
    }

    case "optedNewClaimFlow": {
      const optedNewClaimFlow = action.payload.optedNewClaimFlow;

      return { ...state, optedNewClaimFlow };
    }

    case "claimsUserInfo": {
      const claimsUserInfo = action.payload.claimsUserInfo;

      return { ...state, claimsUserInfo };
    }

    case "defaultBUCount": {
      const defaultBUCount = action.payload.defaultBUCount;

      return { ...state, defaultBUCount };
    }

    default:
      console.warn(`appState - ${action.type} is unhandled`);
      return state;
  }
}

const initialState: AppState = {
  adminMaker: none,
  defaultApprover: none,
  corporateAdmin: none,
  corporateAdminList: [],
  adminMakerList: [],
  defaultApproverList: [],
  businessUnit: [],
  isNewClaimFlow: false,
  optedNewClaimFlow: none,
  claimsUserInfo: none,
  defaultBUCount: 0,
};

export interface NpsData {
  cbo: string;
  cho: string;
  fund: string;
  age: string;
}

const OnboardDetails: React.FC = () => {
  const params = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [showUpdateClaimFlow, setShowUpdateClaimFlow] = useState(false);
  const [showUpdateBusinessUnit, setShowUpdateBusinessUnit] = useState(false);
  const [listOfCat, setListOfCat] = useState<TAllAvailableCategories[]>([]);
  const [proceed, setProceed] = useState<boolean>(false);
  const [alias, setAlias] = useState("");
  const [activeTab, setActiveTab] = useState<CorporateServiceInfo.TypeEnum>();
  const [activeProtect, setActiveProtect] = useState<ProtectionType>(
    ProtectionType.GROUP_MEDICAL
  );
  const [selectOption, setSelectOption] = useState("RUPAY");
  const [editService, setEditService] = useState(false);
  const [state, dispatch] = useReducer(appReducer, initialState);
  const [existCat, setExistCat] = useState<TAllAvailableCategories[]>();
  const [detaxData, setDetaxData] = useState(false);
  const [approveDetax, setApproveDetax] = useState(false);
  const [approveNps, setApproveNps] = useState(false);
  const [approveInsurance, setApproveInsurance] = useState(false);
  const [npsData, setNpsData] = useState<NpsData>({
    cbo: "",
    cho: "",
    fund: "",
    age: "",
  });

  const [selectedServices, setSelectedServices] = useState<
    SelectedServicesInfo[]
  >([]);

  const [annualLimit, setAnnualLimit] = useState(false);
  const setAppState = (appState: AppState) => {
    dispatch({ type: "appState", payload: appState });
  };
  const setAdminMaker = (adminMaker: Option<CorporateUser>) => {
    dispatch({ type: "adminMaker", payload: { ...state, adminMaker } });
  };

  const setCorporateAdmin = (corporateAdmin: Option<CorporateUser>) => {
    dispatch({
      type: "corporateAdmin",
      payload: { ...state, corporateAdmin },
    });
  };

  const [protectData, setProtectData] = useState<AdminInsuranceInfoData>({
    insurer: "",
    imidNo: "",
    insuranceCustomerId: "",
    insurancePolicyNo: "",
    insuranceAccountNo: "",
    policyStartDate: "",
    policyEndDate: "",
    inclusions: [],
    exclusions: [],
    nomineeCount: "",
    insuranceType: ProtectionType.GROUP_MEDICAL,
    relationAllowed: [],
    grades: [],
    accountBankNumber: "",
    ifscCode: "",
    beneficiaryName: "",
    policyDocument: [],
    insurerProviderId: "",
  });
  const [inclusions, setInclusions] = useState<
    { key: string; value: string }[]
  >([]);
  const [exclusions, setExclusions] = useState<
    { key: string; value: string }[]
  >([]);
  const [grades, setGrades] = useState<{ key: string; value: string }[]>([]);
  const [employeeList, setEmployeeList] = useState<
    GetEmployeesDataEmployeesRes[]
  >([]);
  const [providersData, setProvidersData] =
    useState<Array<InsuranceProvidersData>>();

  useEffect(() => {
    getInsuranceProviders();
  }, []);

  const getInsuranceProviders = async () => {
    const result = await ProtectionService.getInsuranceProviders();
    if (isSuccess(result) && result.value.successful) {
      setProvidersData(result.value.data);
    } else {
      console.log(result);
    }
  };

  useEffect(() => {
    if (Object.entries(npsData).some((x) => x[1].length === 0)) {
      setApproveNps(false);
    } else {
      setApproveNps(true);
    }

    if (
      alias.length > 0 &&
      selectOption.length > 0 &&
      state.businessUnit.length > 0 &&
      selectCategory(listOfCat).length > 0
    ) {
      setApproveDetax(true);
    } else {
      setApproveDetax(false);
    }

    if (
      (protectData.insurerProviderId?.length ?? 0) > 0 &&
      (protectData.insurancePolicyNo?.length ?? 0) > 0 &&
      (protectData.insuranceType?.length ?? 0) > 0 &&
      (protectData.policyStartDate?.length ?? 0) > 0 &&
      (protectData.policyEndDate?.length ?? 0) > 0
    ) {
      setApproveInsurance(true);
    } else {
      setApproveInsurance(false);
    }
  }, [
    protectData,
    npsData,
    alias,
    selectOption,
    state.businessUnit,
    listOfCat.length,
  ]);

  useEffect(() => {
    const run = async () => {
      const result = await OnboardingService.getEmployeesWithCid(
        params.id ?? ""
      );
      if (isSuccess(result) && result.value.data.length > 0) {
        setEmployeeList(result.value?.data[0].employees);
      } else {
        // setAppState(initialState);
      }
    };
    if (
      selectedServices.find(
        (x) => x.type === CorporateServiceInfo.TypeEnum.DETAX_CARD
      )?.selected &&
      (params.id?.length ?? 0) > 0
    ) {
      run();
    }
  }, [params.id, selectedServices]);

  const setDefaultApprover = (defaultApprover: Option<CorporateUser>) => {
    dispatch({
      type: "defaultApprover",
      payload: { ...state, defaultApprover },
    });
  };

  const setBusinessUnit = (businessUnit: string[]) => {
    dispatch({
      type: "businessUnit",
      payload: { ...state, businessUnit },
    });
  };

  const setAdminMakerList = (adminMakerList: Array<CorporateUser>) => {
    dispatch({
      type: "adminMakerList",
      payload: { ...state, adminMakerList },
    });
  };
  const setCorporateAdminList = (corporateAdminList: Array<CorporateUser>) => {
    dispatch({
      type: "corporateAdminList",
      payload: { ...state, corporateAdminList },
    });
  };
  const setDefaultApproverList = (
    defaultApproverList: Array<CorporateUser>
  ) => {
    dispatch({
      type: "defaultApproverList",
      payload: { ...state, defaultApproverList },
    });
  };
  const setNewClaimFlow = (isNewClaimFlow: boolean) => {
    dispatch({
      type: "isNewClaimFlow",
      payload: { ...state, isNewClaimFlow },
    });
  };

  const setOptedNewClaimFlow = (optedNewClaimFlow: Option<boolean>) => {
    dispatch({
      type: "optedNewClaimFlow",
      payload: { ...state, optedNewClaimFlow },
    });
  };

  const setClaimsUserInfo = (claimsUserInfo: Option<CorporateUser[]>) => {
    dispatch({
      type: "claimsUserInfo",
      payload: { ...state, claimsUserInfo },
    });
  };

  const [details, setDetails] = useState<AdminApplicationInfo>({
    corporateInfo: {
      cin: "",
      name: "",
      incDate: "",
      regAdd: "",
      rocCode: "",
      regNum: "",
      directors: [],
      cboCode: "",
      choCode: "",
      retireAge: "",
    },
    applicantInfo: {
      name: "",
      mobile: "",
      email: "",
      emailVerified: true,
      mobileVerified: true,
    },
    brInfo: {
      status: "PENDING",
      document: "",
      sentOn: "",
      signedOn: "",
      receipients: [],
    },
    services: [],
    applicationStatus: "CIN_VERIFIED",
    accountStatus: "ACTIVE",
    appCode: "",
    provider: "RUPAY",
  });

  const setClaimsFlowDefaultValueBeforeApprove = (
    data: AdminApplicationInfo
  ) => {
    let receipientsList: CorporateUser[] = (data.brInfo?.receipients || [])
      .filter((item): item is BrReceipientInfo => item !== undefined)
      .map(({ info }) => info)
      .filter((item): item is CorporateUser => item !== undefined);

    // setAppState(initialState);

    // setCorporateAdminList(receipientsList);

    // setDefaultApproverList(receipientsList);
    // setAdminMakerList(receipientsList);

    const convertedVal: Option<boolean> =
      data?.claimsNew !== undefined ? some(data?.claimsNew) : none;
    const updatedAppState: AppState = {
      ...initialState,
      claimsUserInfo: state.claimsUserInfo,
      corporateAdminList: receipientsList,
      defaultApproverList: receipientsList,
      adminMakerList: receipientsList,
      optedNewClaimFlow: convertedVal,
    };
    setAppState(updatedAppState);
    // setOptedNewClaimFlow(convertedVal);
  };

  const setClaimsFlowDefaultValueWhileLive = (data: AdminApplicationInfo) => {
    let receipientsList: CorporateUser[] = data.adminData || [];

    // setAppState(initialState);

    // setCorporateAdminList(receipientsList);

    // setDefaultApproverList(receipientsList);
    // setAdminMakerList(receipientsList);

    const convertedVal: Option<boolean> =
      data?.claimsNew !== undefined ? some(data?.claimsNew) : none;
    const updatedAppState: AppState = {
      ...initialState,
      claimsUserInfo: state.claimsUserInfo,
      corporateAdminList: receipientsList,
      defaultApproverList: receipientsList,
      adminMakerList: receipientsList,
      optedNewClaimFlow: convertedVal,
      defaultBUCount: data.businessUnit?.length ?? 0,
    };
    setAppState(updatedAppState);
    // setOptedNewClaimFlow(convertedVal);
  };

  useEffect(() => {
    const run = async () => {
      if (params?.id) {
        const result = await OnboardingService.getSelectedServices(params.id);

        if (isSuccess(result) && result.value.data) {
          setSelectedServices(result.value.data);
          setActiveTab(result.value.data.filter((x) => x.selected)[0].type);
        } else {
          setSelectedServices([]);
        }

        const result1 = await OnboardingService.detail(params?.id);
        if (isSuccess(result1)) {
          if (result1.value.successful) {
            if (
              result1.value.data?.applicationStatus ===
              AdminApplicationInfo.ApplicationStatusEnum.LIVE
            ) {
              setDetails((prev) => (prev = result1.value.data || prev));
              setNpsData({
                cbo: result1.value.data.corporateInfo?.cboCode ?? "",
                cho: result1.value.data.corporateInfo?.choCode ?? "",
                fund: result1.value.data.corporateInfo?.npsFund ?? "",
                age: result1.value.data.corporateInfo?.retireAge ?? "",
              });
            }
          }
        }
      }
    };

    run();
    // getInsuranceData();
  }, [editService]);

  useEffect(() => {
    const run = async () => {
      if (params.id) {
        const result = await OnboardingService.getAllCorporateCategories(
          params.id
        );

        if (isSuccess(result) && result.value) {
          setDetaxData(result.value.length > 0);
        } else {
          setDetaxData(false);
        }
      }
    };
    if (
      selectedServices.find(
        (x) => x.type === CorporateServiceInfo.TypeEnum.DETAX_CARD
      )?.selected &&
      [
        AdminApplicationBasicInfo.StateEnum.REVIEWPENDING,
        AdminApplicationBasicInfo.StateEnum.LIVE,
      ].includes(
        details.applicationStatus ?? ("" as AdminApplicationBasicInfo.StateEnum)
      )
    ) {
      run();
    }
  }, [selectedServices, details.applicationStatus]);

  useEffect(() => {
    // const controller = new AbortController();

    fetchDetails();
    // return controller.abort();
  }, [params.id, editService, detaxData]);

  const reloadPage = () => {
    window.location.reload();
  };

  const fetchDetails = async () => {
    if (params?.id) {
      setIsLoading(true);

      const result = await OnboardingService.detail(params?.id);
      if (isSuccess(result)) {
        if (result.value.successful) {
          if (
            result.value.data?.applicationStatus ===
              AdminApplicationInfo.ApplicationStatusEnum.REVIEW_PENDING ||
            !detaxData
          ) {
            if (result.value.data) {
              setClaimsFlowDefaultValueBeforeApprove(result.value.data);
            }

            const categoryResult =
              await OnboardingService.getAllAvailableCategories();
            if (isSuccess(categoryResult)) {
              setDetails((prev) => (prev = result.value.data || prev));
              setNpsData({
                cbo: result.value.data?.corporateInfo?.cboCode ?? "",
                cho: result.value.data?.corporateInfo?.choCode ?? "",
                fund: result.value.data?.corporateInfo?.npsFund ?? "",
                age: result.value.data?.corporateInfo?.retireAge ?? "",
              });
              setListOfCat(categoryResult.value);
            }
          } else if (
            result.value.data?.applicationStatus ===
            AdminApplicationInfo.ApplicationStatusEnum.LIVE
          ) {
            const corporateResult = await CorporateService.detail(params.id);

            if (isSuccess(corporateResult)) {
              if (corporateResult.value.data) {
                setSelectOption(corporateResult.value.data.provider || "");

                setClaimsFlowDefaultValueWhileLive(corporateResult.value.data);

                if (corporateResult.value.data.annualLimitSupport) {
                  setAnnualLimit(corporateResult.value.data.annualLimitSupport);
                }
                if (result.value?.data?.userInfo) {
                  setClaimsUserInfo(some(result.value?.data?.userInfo));
                }
                const listOfBusinessUnit =
                  corporateResult.value.data.businessUnit;

                if (listOfBusinessUnit) {
                  setBusinessUnit(listOfBusinessUnit);
                }
              } else {
                setAppState(initialState);
              }
            } else {
              setAppState(initialState);
            }
            //selected services

            const categoryResult =
              await OnboardingService.getAllAvailableCategories();
            if (isSuccess(categoryResult)) {
              const corporateCatResult =
                await OnboardingService.getAllCorporateCategories(params.id);
              if (isSuccess(corporateCatResult)) {
                setDetails((prev) => (prev = result.value.data || prev));
                setNpsData({
                  cbo: result.value.data.corporateInfo?.cboCode ?? "",
                  cho: result.value.data.corporateInfo?.choCode ?? "",
                  fund: result.value.data.corporateInfo?.npsFund ?? "",
                  age: result.value.data.corporateInfo?.retireAge ?? "",
                });
                setDetaxData(corporateCatResult.value.length > 0);
                let catList: TAllAvailableCategories[] = [];
                categoryResult.value.map((allCat) => {
                  let present = false;
                  corporateCatResult.value.map((corporateCat) => {
                    if (
                      unWrapOption(allCat.fbaCategoryId, () => "") ===
                      unWrapOption(corporateCat.fbaCategoryId, () => "")
                    ) {
                      present = true;
                      return;
                    }
                  });
                  if (!present) {
                    catList = [...catList, allCat];
                  }
                });
                catList = [...corporateCatResult.value, ...catList];
                setListOfCat(catList);
              }
            }
          } else {
            setDetails((prev) => (prev = result.value.data || prev));
            setNpsData({
              cbo: result.value.data?.corporateInfo?.cboCode ?? "",
              cho: result.value.data?.corporateInfo?.choCode ?? "",
              fund: result.value.data?.corporateInfo?.npsFund ?? "",
              age: result.value.data?.corporateInfo?.retireAge ?? "",
            });
          }
        }
      }
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <PageLoader />;
  }

  const handleRejectPopup = (show: boolean) => {
    setShowReject(show);
  };

  const handleApprovePopup = (show: boolean) => {
    setShowApprove(show);
  };

  const handleReject = async (remark: string) => {
    const req: ApplicationApprovalRequest = {
      approved: false,
      remarks: remark,
    };

    if (params.id) {
      const result = await OnboardingService.approveOrReject(req, params.id);
      if (isSuccess(result)) {
        if (result.value.successful) {
          setDetails((prev) => (prev = result.value.data || prev));
          setNpsData({
            cbo: result.value.data?.corporateInfo?.cboCode ?? "",
            cho: result.value.data?.corporateInfo?.choCode ?? "",
            fund: result.value.data?.corporateInfo?.npsFund ?? "",
            age: result.value.data?.corporateInfo?.retireAge ?? "",
          });
          AmplitudeInstance.logEvent(AmplitudeEvent.CORPORATE_REJECTED, {
            timeStamp: new Date(),
            applicationName: details.corporateInfo?.name,
            applicationCode: details.appCode,
            request: req,
          });
          window.location.reload();
        }
        setShowReject(false);
      }
    }
  };

  const activateOnlyDetax = async () => {
    if (state.businessUnit.length < 1) {
      console.warn("No business Unit Opted, So update is cancelled");
      return;
    }

    const req: ActivateDetaxRequest = {
      approved: true,
      remarks: "Approved",
      detax: {
        businessUnit: state.businessUnit,
        corpAliasName: alias,
        cardProvider: selectOption,
      },
    };

    if (
      state.isNewClaimFlow &&
      isSome(state.corporateAdmin) &&
      isSome(state.adminMaker) &&
      isSome(state.defaultApprover)
    ) {
      // req["businessUnit"] = state.businessUnit;
      req.detax["userInfo"] = [
        state.defaultApprover.value,
        state.adminMaker.value,
        state.corporateAdmin.value,
      ];
      req.detax["claimsNew"] = true;
    }

    if (params.id) {
      const result = await OnboardingService.activateOnlyDetax(params.id, req);
      if (isSuccess(result)) {
        if (result.value.successful) {
          setDetails((prev) => (prev = result.value.data || prev));
          setNpsData({
            cbo: result.value.data?.corporateInfo?.cboCode ?? "",
            cho: result.value.data?.corporateInfo?.choCode ?? "",
            fund: result.value.data?.corporateInfo?.npsFund ?? "",
            age: result.value.data?.corporateInfo?.retireAge ?? "",
          });
        }
      }
    }
  };

  const handleAddCategorySelection = (index: number) => {
    const list = [...listOfCat];
    if (list[index].isEligibleToAdd) {
      list[index].isSelected = !list[index].isSelected;

      setListOfCat(list);
    }
  };

  const handleDeleteCategorySelection = (index: number) => {
    const list = [...listOfCat];
    if (!list[index].isEligibleToAdd) {
      list[index].isSelected = !list[index].isSelected;

      setListOfCat(list);
    }
  };

  const handleApprove = async () => {
    const req: ApplicationApprovalRequest = {
      approved: true,
      remarks: "Approved",
    };
    ///////////////////////////////////// Detax Req ///////////////////////////////////////////////////////
    if (
      selectedServices.find(
        (x) => x.type === CorporateServiceInfo.TypeEnum.DETAX_CARD
      )?.selected
    ) {
      if (state.businessUnit.length < 1) {
        console.warn("No business Unit Opted, So update is cancelled");
        return;
      }

      let detaxBody: DetaxReq;

      detaxBody = {
        businessUnit: state.businessUnit,
        corpAliasName: alias,
        cardProvider: selectOption,
      };

      if (
        state.isNewClaimFlow &&
        isSome(state.corporateAdmin) &&
        isSome(state.adminMaker) &&
        isSome(state.defaultApprover)
      ) {
        detaxBody["userInfo"] = [
          state.defaultApprover.value,
          state.adminMaker.value,
          state.corporateAdmin.value,
        ];
        detaxBody.claimsNew = true;
      }

      req.detax = detaxBody;
    }
    ///////////////////////////////////// Detax Req ///////////////////////////////////////////////////////
    ///////////////////////////////////// Insurance Req ///////////////////////////////////////////////////////
    if (
      selectedServices.find(
        (x) => x.type === CorporateServiceInfo.TypeEnum.PROTECTION
      )?.selected
    ) {
      let insuranceBody: Array<AdminInsuranceInfoReq> = [];

      const inclusionsMap = new Map(
        inclusions.map((item) => [item.key, item.value])
      );

      // Convert exclusions array back to a Map
      const exclusionsMap = new Map(
        exclusions.map((item) => [item.key, item.value])
      );

      // Convert grades array back to a Map
      const gradesMap = new Map(
        grades.map((item) => [item.key, parseFloat(item.value)])
      );

      const activeProvider = providersData?.find(
        (x) => x.objectId === protectData?.insurerProviderId
      );

      if (
        activeProtect === ProtectionType.GROUP_MEDICAL &&
        (protectData.insurerProviderId?.length ?? 0) > 0 &&
        (protectData.insurancePolicyNo?.length ?? 0) > 0
      ) {
        insuranceBody.push({
          ...protectData,
          insuranceType: protectData?.insuranceType,
          insurer: activeProvider?.insurerId,
          insurancePolicyNo: protectData?.insuranceAccountNo,
          policyStartDate: protectData?.policyStartDate,
          policyEndDate: protectData?.policyEndDate,
          insurerProviderId: protectData.insurerProviderId,
          inclusions: activeProvider?.pdfEnabled
            ? {}
            : Object.fromEntries(inclusionsMap),
          exclusions: activeProvider?.pdfEnabled
            ? {}
            : Object.fromEntries(exclusionsMap),
          grades: Object.fromEntries(gradesMap),
          imidNo: protectData?.imidNo,
          nomineeCount: parseInt(protectData?.nomineeCount ?? "0"),
          relationAllowed:
            parseInt(protectData?.nomineeCount ?? "0") > 0
              ? protectData?.relationAllowed
              : [],
          insuranceCustomerId: parseInt(protectData?.insuranceCustomerId ?? ""),
        });
      } else if (
        activeProtect === ProtectionType.GROUP_LIFE &&
        (protectData.insurerProviderId?.length ?? 0) > 0 &&
        (protectData.insurancePolicyNo?.length ?? 0) > 0
      ) {
        insuranceBody.push({
          insuranceType: protectData?.insuranceType,
          insurer: activeProvider?.insurerId,
          insurancePolicyNo: protectData?.insuranceAccountNo,
          policyStartDate: protectData?.policyStartDate,
          policyEndDate: protectData?.policyEndDate,
          insurerProviderId: protectData.insurerProviderId,
          inclusions: activeProvider?.pdfEnabled
            ? {}
            : Object.fromEntries(inclusionsMap),
          exclusions: activeProvider?.pdfEnabled
            ? {}
            : Object.fromEntries(exclusionsMap),
          nomineeCount: parseInt(protectData?.nomineeCount ?? "0"),
          grades: Object.fromEntries(gradesMap),
        });
      } else {
        alert("Insurance Type Error");
        return;
      }

      req.insurance = insuranceBody;
    }
    ///////////////////////////////////// Insurance Req ///////////////////////////////////////////////////////
    ///////////////////////////////////// NPS Req ///////////////////////////////////////////////////////

    if (
      selectedServices.find((x) => x.type === CorporateServiceInfo.TypeEnum.NPS)
        ?.selected
    ) {
      let npsBody: NpsReq;

      npsBody = {
        corpNPSFund: npsData.fund,
        cboCode: npsData.cbo,
        choCode: npsData.cho,
        retireAge: npsData.age,
      };

      req.nps = npsBody;
    }
    ///////////////////////////////////// NPS Req ///////////////////////////////////////////////////////

    if (params.id) {
      const categories: ListOfSelectedCategoriesApiReq = {
        cid: params.id,
        data: selectCategory(listOfCat),
      };
      const categoryResult = await OnboardingService.setCorporateCategory(
        categories
      );

      if (isSuccess(categoryResult)) {
        if (categoryResult.value.successful) {
          const result = await OnboardingService.approveOrReject(
            req,
            params.id
          );
          if (isSuccess(result)) {
            if (result.value.successful) {
              AmplitudeInstance.logEvent(AmplitudeEvent.CORPORATE_APPROVED, {
                timeStamp: new Date(),
                applicationName: details.corporateInfo?.name,
                applicationCode: details.appCode,
                request: req,
              });

              setDetails((prev) => (prev = result.value.data || prev));
              setNpsData({
                cbo: result.value.data?.corporateInfo?.cboCode ?? "",
                cho: result.value.data?.corporateInfo?.choCode ?? "",
                fund: result.value.data?.corporateInfo?.npsFund ?? "",
                age: result.value.data?.corporateInfo?.retireAge ?? "",
              });
              setShowApprove(false);
              if (
                result.value.data?.applicationStatus ===
                AdminApplicationInfo.ApplicationStatusEnum.LIVE
              ) {
                const corporateResult = await CorporateService.detail(
                  params.id
                );
                if (isSuccess(corporateResult)) {
                  window.location.reload();

                  if (corporateResult.value.data) {
                    setClaimsFlowDefaultValueWhileLive(
                      corporateResult.value.data
                    );

                    const listOfBusinessUnit =
                      corporateResult.value.data.businessUnit;
                    if (listOfBusinessUnit) {
                      setBusinessUnit(listOfBusinessUnit);
                    }

                    if (corporateResult.value.data.annualLimitSupport) {
                      setAnnualLimit(
                        corporateResult.value.data.annualLimitSupport
                      );
                    }

                    if (result.value?.data?.userInfo) {
                      setClaimsUserInfo(some(result.value?.data?.userInfo));
                    }
                  } else {
                    setAppState(initialState);
                  }
                } else {
                  setAppState(initialState);
                }

                const AllCategoryResult =
                  await OnboardingService.getAllAvailableCategories();
                if (isSuccess(AllCategoryResult)) {
                  const corporateCatResult =
                    await OnboardingService.getAllCorporateCategories(
                      params.id
                    );
                  if (isSuccess(corporateCatResult)) {
                    setExistCat(corporateCatResult.value);
                    setDetaxData(corporateCatResult.value.length > 0);
                    let catList: TAllAvailableCategories[] = [];
                    AllCategoryResult.value.map((allCat) => {
                      let present = false;
                      corporateCatResult.value.map((corporateCat) => {
                        if (
                          unWrapOption(allCat.fbaCategoryId, () => "") ===
                          unWrapOption(corporateCat.fbaCategoryId, () => "")
                        ) {
                          present = true;
                          return;
                        }
                      });
                      if (!present) {
                        catList = [...catList, allCat];
                      }
                    });
                    catList = [...corporateCatResult.value, ...catList];
                    setListOfCat(catList);
                  }
                }
              }
            }
          }
        } else {
          console.log("Category Update Failed");
        }
      } else {
        console.log("Category Update Failed");
      }
    }
  };

  const unselectAllCategory = (categories: TAllAvailableCategories[]) => {
    let val: TAllAvailableCategories[] = [];
    categories.map((cat) => {
      cat.isSelected = false;
      val = [...val, cat];
    });
    setListOfCat(val);
  };

  const updateAddCorporateCategory = async () => {
    if (params.id) {
      const userSelectedCat: AdminListOfCategories[] =
        selectCategory(listOfCat);
      if (userSelectedCat.length < 1) {
        return;
      }
      const categories: ListOfSelectedCategoriesApiReq = {
        cid: params.id,
        data: userSelectedCat,
      };
      setIsLoading(true);
      const categoryResult = await OnboardingService.updateAddCorporateCategory(
        params.id,
        categories
      );
      if (isSuccess(categoryResult)) {
        if (categoryResult.value.successful) {
          console.log("Category Updated");

          AmplitudeInstance.logEvent(AmplitudeEvent.ADD_CATEGORY, {
            timeStamp: new Date(),
            corporateName: details.corporateInfo?.name,
            applicationCode: details.appCode,
            previousData: existCat,
            request: categories.data,
          });

          const categoryResult =
            await OnboardingService.getAllAvailableCategories();
          if (isSuccess(categoryResult)) {
            const corporateCatResult =
              await OnboardingService.getAllCorporateCategories(params.id);
            if (isSuccess(corporateCatResult)) {
              setExistCat(corporateCatResult.value);
              setDetaxData(corporateCatResult.value.length > 0);
              setProceed(false);
              let catList: TAllAvailableCategories[] = [];
              categoryResult.value.map((allCat) => {
                let present = false;
                corporateCatResult.value.map((corporateCat) => {
                  if (
                    unWrapOption(allCat.fbaCategoryId, () => "") ===
                    unWrapOption(corporateCat.fbaCategoryId, () => "")
                  ) {
                    present = true;
                    return;
                  }
                });
                if (!present) {
                  catList = [...catList, allCat];
                }
              });
              catList = [...corporateCatResult.value, ...catList];
              setListOfCat(catList);
            }
          }
        } else {
          console.log("Category Update Failed");
        }
      }
      setIsLoading(false);
    }
  };

  const toastHandler = (msg: string) => {
    toast.error(msg, {
      position: "top-right",
    });
  };

  const updateBusinessUnit = async () => {
    if (state.businessUnit.length < 1) {
      console.warn("no business unit selected, so update is cancelled");
      return;
    }

    const result = await OnboardingService.updateBusinessUnit(
      params?.id ?? "",
      state.businessUnit
    );
    if (isSuccess(result)) {
      if (result.value.successful) {
        AmplitudeInstance.logEvent(`${AmplitudeEvent.ADDING_BUSINESS_UNIT}`, {
          timeStamp: new Date(),
          corporateName: details.corporateInfo?.name,
          request: state.businessUnit,
        });

        const corporateResult = await CorporateService.detail(params?.id ?? "");

        if (isSuccess(corporateResult)) {
          if (corporateResult.value.data) {
            setClaimsFlowDefaultValueWhileLive(corporateResult.value.data);

            const listOfBusinessUnit = corporateResult.value.data.businessUnit;
            if (listOfBusinessUnit) {
              setBusinessUnit(listOfBusinessUnit);
            }

            if (corporateResult.value.data.annualLimitSupport) {
              setAnnualLimit(corporateResult.value.data.annualLimitSupport);
            }
          } else {
            setAppState(initialState);
          }
        } else {
          setAppState(initialState);
        }
      } else {
        toastHandler("can't update Business unit, Please try again");
      }
    } else {
      toastHandler("server error, can't update Business unit");
    }
    setShowUpdateBusinessUnit(false);
  };

  const updateNewClaimFlow = async () => {
    let userInfo: CorporateUser[] = [];
    if (
      isSome(state.corporateAdmin) &&
      isSome(state.adminMaker) &&
      isSome(state.defaultApprover)
    ) {
      userInfo = [
        state.defaultApprover.value,
        state.adminMaker.value,
        state.corporateAdmin.value,
      ];
    } else {
      toastHandler("server error, can't update new claim flow");

      return;
    }

    const claimFlow: UpdateClaimsFlowApiReq = {
      userInfo,
      claimsNew: state.isNewClaimFlow,
    };
    const result = await OnboardingService.updateClaimsFlow(
      params?.id ?? "",
      claimFlow
    );

    if (isSuccess(result)) {
      if (result.value.successful) {
        AmplitudeInstance.logEvent(`${AmplitudeEvent.NEW_CLAIM_FLOW}`, {
          timeStamp: new Date(),
          corporateName: details.corporateInfo?.name,
          request: claimFlow,
        });
        const corporateResult = await CorporateService.detail(params?.id ?? "");

        if (isSuccess(corporateResult)) {
          if (corporateResult.value.data) {
            setClaimsFlowDefaultValueWhileLive(corporateResult.value.data);

            const listOfBusinessUnit = corporateResult.value.data.businessUnit;
            if (listOfBusinessUnit) {
              setBusinessUnit(listOfBusinessUnit);
            }

            if (corporateResult.value.data.annualLimitSupport) {
              setAnnualLimit(corporateResult.value.data.annualLimitSupport);
            }
            const result = await OnboardingService.detail(params?.id ?? "");
            if (isSuccess(result)) {
              if (result.value.successful) {
                setSelectOption(corporateResult.value.data.provider || "");

                if (result.value?.data?.userInfo) {
                  setClaimsUserInfo(some(result.value?.data?.userInfo));
                }
              }
            }
          } else {
            setAppState(initialState);
          }
        } else {
          setAppState(initialState);
        }
        // toastHandler("Update Successsfull");
      } else {
        toastHandler("can't update new claim flow, Please try again");
      }
    } else {
      toastHandler("server error, can't update new claim flow");
    }

    setShowUpdateClaimFlow(false);
  };

  const updateDeleteCorporateCategory = async () => {
    if (params.id) {
      const userSelectedCat: AdminListOfCategories[] =
        selectCategory(listOfCat);
      if (userSelectedCat.length < 1) {
        return;
      }
      const categories: ListOfSelectedCategoriesApiReq = {
        cid: params.id,
        data: userSelectedCat,
      };
      setIsLoading(true);
      const categoryResult =
        await OnboardingService.updateDeleteCorporateCategory(
          params.id,
          categories
        );
      if (isSuccess(categoryResult)) {
        if (categoryResult.value.successful) {
          AmplitudeInstance.logEvent(AmplitudeEvent.REMOVE_CATEGORY, {
            timeStamp: new Date(),
            corporateName: details.corporateInfo?.name,
            applicationCode: details.appCode,
            previousData: existCat,
            request: categories.data,
          });

          console.log("Category Updated");
          const categoryResult =
            await OnboardingService.getAllAvailableCategories();
          if (isSuccess(categoryResult)) {
            const corporateCatResult =
              await OnboardingService.getAllCorporateCategories(params.id);
            if (isSuccess(corporateCatResult)) {
              setExistCat(corporateCatResult.value);
              setDetaxData(corporateCatResult.value.length > 0);
              let catList: TAllAvailableCategories[] = [];
              categoryResult.value.map((allCat) => {
                let present = false;
                corporateCatResult.value.map((corporateCat) => {
                  if (
                    unWrapOption(allCat.fbaCategoryId, () => "") ===
                    unWrapOption(corporateCat.fbaCategoryId, () => "")
                  ) {
                    present = true;
                    return;
                  }
                });
                if (!present) {
                  catList = [...catList, allCat];
                }
              });
              catList = [...corporateCatResult.value, ...catList];
              setListOfCat(catList);
            }
          }
        } else {
          console.log("Category Update Failed");
        }
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle title={`Application details | ${details.appCode}`} />
      <ToastContainer />
      <OnboardHeader
        detail={details.appCode}
        status={details.applicationStatus}
        setShowRejectPopup={handleRejectPopup}
        setShowApprovePopup={handleApprovePopup}
        approveEnable={
          (selectedServices.find(
            (x) => x.type === CorporateServiceInfo.TypeEnum.DETAX_CARD
          )?.selected
            ? approveDetax
            : true) &&
          (selectedServices.find(
            (x) => x.type === CorporateServiceInfo.TypeEnum.NPS
          )?.selected
            ? approveNps
            : true) &&
          (selectedServices.find(
            (x) => x.type === CorporateServiceInfo.TypeEnum.PROTECTION
          )?.selected
            ? approveInsurance
            : true)
        }
      />
      {details.corporateInfo && (
        <CompanyDetails
          agreement={details.brInfo?.document}
          corporateInfo={details.corporateInfo}
        />
      )}
      {details.applicantInfo && (
        <UserDetailsInfo applicantInfo={details.applicantInfo} />
      )}
      {details.brInfo && details.brInfo.receipients && (
        <BrInfo brInfo={details.brInfo} />
      )}
      {details.services && (
        <ServiceSelected
          id={params?.id ?? ""}
          // services={details.services}
          details={details}
          selectedServices={selectedServices}
          setSelectedServices={(data: SelectedServicesInfo[]) =>
            setSelectedServices(data)
          }
          applicationStatus={details.applicationStatus || ""}
          editService={editService}
          setEditService={(val: boolean) => setEditService(val)}
        />
      )}
      <OnboardServiceTab
        activeTab={activeTab}
        setActiveTab={(val) => setActiveTab(val)}
        selectedServices={selectedServices}
      />
      {activeTab === CorporateServiceInfo.TypeEnum.DETAX_CARD && (
        <WhiteCard>
          {(details.applicationStatus ===
            AdminApplicationInfo.ApplicationStatusEnum.REVIEW_PENDING ||
            details.applicationStatus ===
              AdminApplicationInfo.ApplicationStatusEnum.LIVE) && (
            <CategorySelection
              proceed={proceed}
              detaxData={detaxData}
              setProceed={(val: boolean) => setProceed(val)}
              applicationStatus={details.applicationStatus || ""}
              catList={listOfCat}
              handleAddCategorySelection={(index: number) =>
                handleAddCategorySelection(index)
              }
              handleDeleteCategorySelection={(index: number) =>
                handleDeleteCategorySelection(index)
              }
              setAlias={(val: string) => setAlias(val)}
              alias={alias}
              unSelectAllCategory={(val: TAllAvailableCategories[]) => {
                unselectAllCategory(val);
              }}
              updateAddCorporateCategory={() => updateAddCorporateCategory()}
              updateDeleteCorporateCategory={() =>
                updateDeleteCorporateCategory()
              }
              cid={params.id ?? ""}
              employeeList={employeeList}
              details={details}
            />
          )}

          {details.applicationStatus ===
            AdminApplicationInfo.ApplicationStatusEnum.LIVE &&
            listOfCat.some((x) => !x.isEligibleToAdd) && (
              <SetMaxLimit
                listOfCat={listOfCat}
                cid={params.id ?? ""}
                employeeList={employeeList}
                annualLimit={annualLimit}
                details={details}
              />
            )}
          {(details.applicationStatus ===
            AdminApplicationInfo.ApplicationStatusEnum.REVIEW_PENDING ||
            (!detaxData
              ? [
                  AdminApplicationInfo.ApplicationStatusEnum.REVIEW_PENDING,
                  AdminApplicationInfo.ApplicationStatusEnum.LIVE,
                ].includes(
                  details.applicationStatus as AdminApplicationInfo.ApplicationStatusEnum
                )
              : false)) && (
            <PreApprovalClaimSelection
              detaxData={detaxData}
              setDefaultApprover={(defaultApprover: Option<CorporateUser>) =>
                setDefaultApprover(defaultApprover)
              }
              setAdminMaker={(adminMaker: Option<CorporateUser>) =>
                setAdminMaker(adminMaker)
              }
              setCorporateAdmin={(corporateAdmin: Option<CorporateUser>) =>
                setCorporateAdmin(corporateAdmin)
              }
              setBusinessUnit={(data: string[]) => setBusinessUnit(data)}
              setDefaultApproverList={(
                defaultApproverList: Array<CorporateUser>
              ) => setDefaultApproverList(defaultApproverList)}
              setAdminMakerList={(adminMakerList: Array<CorporateUser>) =>
                setAdminMakerList(adminMakerList)
              }
              setCorporateAdminList={(
                corporateAdminList: Array<CorporateUser>
              ) => setCorporateAdminList(corporateAdminList)}
              setNewClaimFlow={(isNewClaimFlow: boolean) =>
                setNewClaimFlow(isNewClaimFlow)
              }
              defaultApproverList={state.defaultApproverList}
              adminMakerList={state.adminMakerList}
              corporateAdminList={state.corporateAdminList}
              businessUnit={state.businessUnit}
              defaultApprover={state.defaultApprover}
              adminMaker={state.adminMaker}
              corporateAdmin={state.corporateAdmin}
              isNewClaimFlow={state.isNewClaimFlow}
            />
          )}
          {details.applicationStatus ===
            AdminApplicationInfo.ApplicationStatusEnum.LIVE &&
            detaxData && (
              <PostApprovalClaimSelection
                setDefaultApprover={(defaultApprover: Option<CorporateUser>) =>
                  setDefaultApprover(defaultApprover)
                }
                setAdminMaker={(adminMaker: Option<CorporateUser>) =>
                  setAdminMaker(adminMaker)
                }
                setCorporateAdmin={(corporateAdmin: Option<CorporateUser>) =>
                  setCorporateAdmin(corporateAdmin)
                }
                setBusinessUnit={(data: string[]) => setBusinessUnit(data)}
                setDefaultApproverList={(
                  defaultApproverList: Array<CorporateUser>
                ) => setDefaultApproverList(defaultApproverList)}
                setAdminMakerList={(adminMakerList: Array<CorporateUser>) =>
                  setAdminMakerList(adminMakerList)
                }
                setCorporateAdminList={(
                  corporateAdminList: Array<CorporateUser>
                ) => setCorporateAdminList(corporateAdminList)}
                setNewClaimFlow={(isNewClaimFlow: boolean) =>
                  setNewClaimFlow(isNewClaimFlow)
                }
                setShowUpdateClaimFlowPopup={() => setShowUpdateClaimFlow(true)}
                setShowUpdateBusinessUnitPopup={() =>
                  setShowUpdateBusinessUnit(true)
                }
                defaultApproverList={state.defaultApproverList}
                adminMakerList={state.adminMakerList}
                corporateAdminList={state.corporateAdminList}
                businessUnit={state.businessUnit}
                defaultApprover={state.defaultApprover}
                adminMaker={state.adminMaker}
                corporateAdmin={state.corporateAdmin}
                isNewClaimFlow={state.isNewClaimFlow}
                optedNewClaimFlow={state.optedNewClaimFlow}
                claimsUserInfo={state.claimsUserInfo}
                defaultBUCount={state.defaultBUCount}
              />
            )}

          {(details.applicationStatus ===
            AdminApplicationInfo.ApplicationStatusEnum.REVIEW_PENDING ||
            details.applicationStatus ===
              AdminApplicationInfo.ApplicationStatusEnum.LIVE) && (
            <CardVariant
              status={details.applicationStatus}
              setSelectOption={(val: string) => setSelectOption(val)}
              selectOption={selectOption}
              detaxData={detaxData}
            />
          )}
          {/* <AlertPopup /> */}
          {details.applicationStatus ===
            AdminApplicationInfo.ApplicationStatusEnum.LIVE &&
            !detaxData &&
            selectedServices.find(
              (x) => x.type === CorporateServiceInfo.TypeEnum.DETAX_CARD
            )?.selected && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="me-2 mt-2 mb-2"
              >
                <button
                  className="btn-gradiant-blue text-white px-3  py-2 mt-2 me-2"
                  disabled={
                    state.businessUnit.length === 0 ||
                    alias.length === 0 ||
                    selectCategory(listOfCat).length === 0 ||
                    selectOption.length === 0
                  }
                  onClick={() => {
                    activateOnlyDetax();
                  }}
                >
                  Save
                </button>
              </div>
            )}
        </WhiteCard>
      )}
      {activeTab === CorporateServiceInfo.TypeEnum.NPS && (
        <WhiteCard>
          <OnboardServiceNps
            details={details}
            id={params?.id ?? ""}
            npsData={npsData}
            setNpsData={(val) => setNpsData(val)}
            selectedServices={selectedServices}
          />
        </WhiteCard>
      )}
      {activeTab === CorporateServiceInfo.TypeEnum.PROTECTION && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
              marginBottom: 24,
            }}
          >
            {Object.entries(ProtectionType).map((x) => (
              <div
                style={{
                  backgroundColor: activeProtect === x[1] ? "#4B6F88" : "#fff",
                  border: `1px solid ${
                    activeProtect === x[1] ? "#4B6F88" : "#000000"
                  }`,
                  padding: 10,
                  cursor: "pointer",
                  color: activeProtect === x[1] ? "#C6F1FF" : "#000",
                  borderRadius: 4,
                }}
                onClick={() => {
                  setActiveProtect(x[1]);
                  setProtectData({ ...protectData, insuranceType: x[1] });
                }}
              >
                <p className="m-0" style={{ fontWeight: "bold" }}>
                  {x[1]} Insurance
                </p>
              </div>
            ))}
          </div>
          <WhiteCard>
            <OnboardServiceProtect
              details={details}
              id={params?.id ?? ""}
              protectData={protectData}
              setProtectData={(val) => {
                setProtectData(val);
              }}
              inclusions={inclusions}
              exclusions={exclusions}
              grades={grades}
              setExclusions={(val) => setExclusions(val)}
              setInclusions={(val) => setInclusions(val)}
              setGrades={(val) => setGrades(val)}
              providersData={providersData}
              activeProtect={activeProtect}
            />
          </WhiteCard>
        </>
      )}
      <RejectPopup
        show={showReject}
        onHide={() => handleRejectPopup(false)}
        onReject={handleReject}
      />

      <ApprovePopup
        show={showApprove}
        onHide={() => setShowApprove(false)}
        onApprove={handleApprove}
      />
      <UpdateBusinessUnitPopUp
        show={showUpdateBusinessUnit}
        onHide={() => setShowUpdateBusinessUnit(false)}
        onUpdate={updateBusinessUnit}
      />
      <UpdateClaimFlowPopUp
        show={showUpdateClaimFlow}
        onHide={() => setShowUpdateClaimFlow(false)}
        onUpdate={updateNewClaimFlow}
      />
    </>
  );
};

const UpdateClaimFlowPopUp = ({
  show,
  onHide,
  onUpdate,
}: {
  show: boolean;
  onHide: () => void;
  onUpdate: () => void;
}) => {
  return <UpdatePopup show={show} onHide={onHide} onUpdate={onUpdate} />;
};

const UpdateBusinessUnitPopUp = ({
  show,
  onHide,
  onUpdate,
}: {
  show: boolean;
  onHide: () => void;
  onUpdate: () => void;
}) => {
  return <UpdatePopup show={show} onHide={onHide} onUpdate={onUpdate} />;
};

export default OnboardDetails;
