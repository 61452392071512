import React, { useState } from "react";
import { FiFileText } from "react-icons/fi";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import Button from "../../../common/button/button";
import PageTitle from "../../../common/page-title";
import Heading from "../common/heading";
import CampaignDetails from "./campaign-details";
import DeliveryUpload from "./delivery-upload";
import ImpsFees from "./imps-fees";
import Notifications from "./notifications";
import ReplaceCard from "./replace-card";
import Campaigns from "./settings-campaign";
import "./settings.scss";

export enum TabBar {
  CAMPAIGN = "CAMPAIGN",
  DELIVERY_UPLOAD = "DELIVERY_UPLOAD",
  NOTIFICATION = "NOTIFICATION",
  REPLACE_CARD = "REPLACE_CARD",
  IMPS_FEES = "IMPS_FEES",
}
export enum TabBarLabel {
  CAMPAIGN = "Campaigns",
  DELIVERY_UPLOAD = "Delivery",
  NOTIFICATION = "Notifications",
  REPLACE_CARD = "Card Replacement",
  IMPS_FEES = "IMPS Fees",
}

const option: Array<{ label: TabBarLabel; value: TabBar }> = [
  {
    label: TabBarLabel.DELIVERY_UPLOAD,
    value: TabBar.DELIVERY_UPLOAD,
  },
  {
    label: TabBarLabel.CAMPAIGN,
    value: TabBar.CAMPAIGN,
  },
  {
    label: TabBarLabel.NOTIFICATION,
    value: TabBar.NOTIFICATION,
  },
  {
    label: TabBarLabel.REPLACE_CARD,
    value: TabBar.REPLACE_CARD,
  },
  {
    label: TabBarLabel.IMPS_FEES,
    value: TabBar.IMPS_FEES,
  },
];

export interface Detail {
  show: boolean;
  id: string;
}

const Settings: React.FC = () => {
  const [active, setActive] = useState<TabBar>(option[0].value);
  const [detail, setDetail] = useState<Detail>({ show: false, id: "" });
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(true);

  const handleMouseEnter = () => {
    setCollapsed(false);
  };

  const handleMouseLeave = () => {
    setCollapsed(true);
  };

  return (
    <>
      <PageTitle title="Settings" />
      {/* <Heading title="Settings" /> */}
      <div
        style={{
          // border: "1px solid black",
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Sidebar
          style={{ height: "100vh" }}
          // image={sidebarImage}
          // collapsed={collapsed}
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          // title="Settings"
          backgroundColor="#FFFFFF"
        >
          <Menu>
            <MenuItem
              // icon={<FcSettings size={25} />}
              disabled={true}
            >
              <Heading title="Settings" />
            </MenuItem>
            {option.map((obj) => (
              <MenuItem
                icon={
                  <FiFileText
                    color={active === obj.value ? "#3183FE" : "#777777"}
                    size={15}
                  />
                }
                style={{
                  backgroundColor:
                    active === obj.value ? "#D2EAFF" : "transparent",
                  color: active === obj.value ? "#3183FE" : "#777777",
                }}
                onClick={() => {
                  setActive(obj.value);
                }}
              >
                {obj.label}
              </MenuItem>
            ))}
          </Menu>
        </Sidebar>
        <div style={{ width: "100%", height: "100%" }} className="m-1">
          {active === TabBar.CAMPAIGN && !detail.show && (
            <div style={{ padding: "1%" }}>
              <p style={{ fontWeight: "bold", margin: 0, fontSize: 18 }}>
                Campaigns
              </p>
              <p
                style={{
                  color: "#777777",
                  fontSize: 14,
                  fontWeight: "600",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="my-2 mb-1 border-bottom"
              >
                Create customisable campaigns targeting corporates
                <div
                  style={{
                    marginTop: -15,
                  }}
                >
                  <Button
                    className="btn-gradiant-blue btn-lg text-white fs-small m-1 me-2 p-2"
                    style={{}}
                    onClick={() => {
                      setDetail({ show: true, id: "" });
                    }}
                  >
                    Create Campaign
                  </Button>
                </div>
              </p>
              <div
                style={{
                  height: "83vh",
                  scrollBehavior: "auto",
                  overflow: "scroll",
                }}
              >
                <Campaigns
                  setDetail={(val: Detail) => setDetail(val)}
                  detail={detail}
                />
              </div>
            </div>
          )}
          {active === TabBar.CAMPAIGN && detail.show && (
            <CampaignDetails
              id={detail.id}
              onClose={() => {
                setDetail({ show: false, id: "" });
              }}
            />
          )}
          {active === TabBar.DELIVERY_UPLOAD && <DeliveryUpload />}
          {active === TabBar.NOTIFICATION && <Notifications />}
          {active === TabBar.REPLACE_CARD && <ReplaceCard />}
          {active === TabBar.IMPS_FEES && <ImpsFees />}
        </div>
      </div>
    </>
  );
};
export default Settings;
