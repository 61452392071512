import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RoutePaths } from "../../../utils/route.utils";
import InitiatePopup from "./InitiatePopup";
import ExecutePopup from "./ExecutePopup";
import ApprovePopup from "./ApprovePopup";
import { useAppSelector } from "../../../hooks/hooks";
import { MembershipReduxStore } from "../../../state/slice/membership.slice";
import { unWrapOption } from "../../../utils/option.utils";
import { AdminMembershipBatchInfo } from "../../../api/admin/model/membership/adminMembershipBatchInfo";
import { updateMembershipBatchStatus } from "./utilies/membership.utils";

const MembershipDetailHeading: React.FC<{ text: String }> = ({ text }) => {
  const membershipBatchInfo = useAppSelector(
    MembershipReduxStore.selectBatchInfo
  );
  // const [status, setStatus] = useState("initiate");
  const [initiatePopup, showInitiatePopup] = useState(false);
  const [executePopup, showExecutePopup] = useState(false);
  const [approvePopup, showApprovePopup] = useState(false);

  const val: AdminMembershipBatchInfo = {};
  const batchInfo = unWrapOption(membershipBatchInfo, () => val);

  const getText = () => {
    switch (batchInfo?.batchStatus) {
      case "Requested": {
        return "Initiate";
      }
      case "Initiated": {
        return "Execute";
      }
      case "Executed": {
        return "Approve";
      }
    }
  };

  const btnText = getText();

  const handleBatchStatus = (
    amtRecieved?: number,
    transId?: string,
    transDate?: string,
    paymentMadeDate?: String
  ) => {
    updateMembershipBatchStatus(
      batchInfo.cid || "",
      batchInfo.batchCode || "",
      (btnText || "").toLowerCase(),
      amtRecieved,
      transId,
      transDate,
      paymentMadeDate
    );
    showInitiatePopup(false);
    showExecutePopup(false);
    showApprovePopup(false);
  };

  const handlePopUp = () => {
    switch (batchInfo?.batchStatus) {
      case "Requested": {
        showInitiatePopup(true);
        break;
      }
      case "Initiated": {
        showExecutePopup(true);
        break;
      }
      case "Executed": {
        showApprovePopup(true);
        break;
      }
      default: {
        console.log(
          "membership detail heading <<switch - default>> ::: ",
          batchInfo?.batchStatus
        );
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <h1 className="page-heading">
          <Link to={RoutePaths.Membership}>
            <span className="title">Membership</span>
          </Link>{" "}
          {text}
        </h1>
        {btnText && (
          <div className="mb-3 ">
            <button
              style={{ marginLeft: "10px" }}
              className="btn-gradiant-blue btn-lg text-white fs-small btn btn-primary px-3 py-2"
              onClick={() => handlePopUp()}
            >
              {btnText}
            </button>
          </div>
        )}

        <InitiatePopup
          show={initiatePopup}
          requiredAmt={batchInfo?.batchAmount ? batchInfo?.batchAmount : 0}
          batchInfo={{ cid: batchInfo.cid, batchCode: batchInfo.batchCode }}
          onHide={() => showInitiatePopup(false)}
          onInitiate={handleBatchStatus}
        />

        <ExecutePopup
          cId={batchInfo?.cid}
          show={executePopup}
          onHide={() => showExecutePopup(false)}
          onExecute={handleBatchStatus}
        />

        <ApprovePopup
          show={approvePopup}
          onHide={() => showApprovePopup(false)}
          onApprove={handleBatchStatus}
        />
      </div>
    </>
  );
};

export default MembershipDetailHeading;
