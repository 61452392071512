import React, { useState } from "react";
import { KitManagementCountInfo } from "../../../types/admin/kitmanagement-application-count.type";
import PageTitle from "../../common/page-title";
import Heading from "../heading";
import KitAddNew from "./kit-addData";
import KitTable from "./kit-table";

const KitManagement: React.FC = () => {
  const [selectedState, setSelectedState] = useState<string>("");
  const [counts, setCounts] = useState<KitManagementCountInfo[]>([]);

  // useEffect(() => {
  //   const run = async () => {
  //     const result = await OnboardingService.onboardingCounts();

  //     if (isSuccess(result)) {
  //       if (unWrapOption(result.value.successful, () => false)) {
  //         const countsObj = addAllDashboardCount(result.value.data);
  //         setCounts(countsObj);
  //         setSelectedState("ALL");
  //       }
  //     }
  //   };
  //   run();
  // }, [selectedState]);

  return (
    <>
      <PageTitle title="Kit Management" />
      <div className="d-flex flex-row mb-3 mt-14 justify-content-between">
        <Heading title="Kit Management" />
        <KitAddNew />
      </div>
      <KitTable selectedState={selectedState} />
      {/* <OnboardTable selectedState={selectedState} /> */}
    </>
  );
};

export default KitManagement;
