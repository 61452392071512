import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { start } from "repl";

interface IbatchInfo {
  cid?: String;
  batchCode?: String;
}

interface IFormVal {
  amtRef: String;
  transId: String;
  dateRef: Date | null;
}

const InitiatePopup: React.FC<{
  show: boolean;
  requiredAmt: number;
  batchInfo: IbatchInfo;
  onHide: (show: boolean) => void;
  // membershipInfo: any;
  onInitiate: (
    amtRecieved?: number,
    transId?: string,
    transDate?: string
  ) => void;
}> = ({
  show,
  requiredAmt,
  batchInfo,
  onHide,
  // membershipInfo,
  onInitiate,
}) => {
  const amtRef = React.createRef<HTMLInputElement>();
  const transId = React.createRef<HTMLInputElement>();
  const dateRef = React.createRef<ReactDatePicker>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [amt, setAmt] = useState<string>("");
  const [transactionId, setTransactionId] = useState<string>("");
  const [amtError, setAmtError] = useState<string | null>(null);

  function handleChnAmt(val: string) {
    if (/^[0-9]*$/.test(val)) {
      setAmt(val);
    }
  }

  const handleSubmit = () => {
    const amount = Number(amt);
    let finalDate: string;
    let date: string;
    let month: string;
    if (transactionId.length > 0 && startDate && amount) {
      if (amount >= requiredAmt) {
        const d = startDate.getDate();
        const m = startDate.getMonth() + 1;

        date = d < 10 ? `0${d}` : `${d}`;
        month = m < 10 ? `0${m}` : `${m}`;
        finalDate = `${date}-${month}-${startDate.getFullYear()}`;
      } else {
        setAmtError("The entered amount is lesser than the batch amount");
        return;
      }
    } else {
      console.log("<<access deinien due to wrong input>>");
      return;
    }
    onInitiate(amount, transactionId, finalDate);
  };

  return (
    <Modal show={show} onHide={() => onHide(false)} centered>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="text-center text-black fw-semibold fs-6">
                Confirm Amount Details
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="text-sm-start text-center text-black fs-6 ">
                Enter confirmation of the amount received for membership
              </p>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-sm-4 col-12 d-flex align-items-center">
              <p className="my-1">Amount Received</p>
            </div>
            <div className="col d-flex align-items-stretch">
              <div
                className="w-100  mx-sm-0 mx-2 border border-dark row"
                onClick={() => {
                  if (amtRef.current) {
                    amtRef.current.focus();
                  }
                }}
              >
                <div className="col-1 p-0 d-flex align-items-center">
                  <p className="my-1 p-1">Rs.</p>
                </div>
                <div className="col d-flex align-items-center">
                  <input
                    ref={amtRef}
                    className="my-1 col border-0"
                    name="amtRef"
                    value={amt}
                    onChange={(val) => handleChnAmt(val.currentTarget.value)}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-sm-4 col-12 d-flex align-items-center">
              <p className="my-1">Reference ID</p>
            </div>
            <div className="col d-flex align-items-stretch">
              <div
                className="w-100 mx-sm-0 mx-2 border border-dark row"
                onClick={() => {
                  if (transId.current) {
                    transId.current.focus();
                  }
                }}
              >
                <div className="col p-0 d-flex align-items-center">
                  <input
                    ref={transId}
                    className="my-1 p-1 col border-0"
                    name="transId"
                    value={transactionId}
                    onChange={(val) =>
                      setTransactionId(val.currentTarget.value)
                    }
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-sm-4 col-12 d-flex align-items-center">
              <p className="my-1">Date</p>
            </div>
            <div className="col d-flex align-items-stretch">
              <div
                className="w-100 mx-sm-0 mx-2 border border-dark row"
                onClick={() => {
                  if (dateRef.current) {
                    dateRef.current.setFocus();
                  }
                }}
              >
                <div className="col p-0 d-flex align-items-center">
                  <div className="my-1 p-1 w-100  hide-border-none">
                    <ReactDatePicker
                      ref={dateRef}
                      name="dateRef"
                      maxDate={new Date()}
                      selected={startDate}
                      // onChangeRaw={(e) => {
                      //   e.preventDefault();
                      // }}
                      dateFormat={"dd/MM/yyyy"}
                      placeholderText={"dd/mm/yyyy"}
                      onChange={(date) => setStartDate(date)}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-1">
            <div className="col d-flex justify-content-center">
              <button
                className="btn-ncaish-light text-white fs-small px-3 py-2 w-75 justify-content-center"
                onClick={() => onHide(false)}
              >
                CANCEL
              </button>
            </div>
            <div className="col d-flex justify-content-center">
              <button
                className="btn-gradiant-blue btn-lg text-white fs-small btn btn-primary px-3 py-2 w-75 justify-content-center"
                type="submit"
                onClick={handleSubmit}
              >
                SUBMIT
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {amtError && (
                <p className="text-danger fs-11 text-center">{amtError}</p>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default InitiatePopup;
