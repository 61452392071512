import React, { useState } from "react";
import { CorporateUser } from "../../../../api/admin/model/corporateUser";
import {
  fold,
  fromNullable,
  isSome,
  none,
  Option,
  some,
} from "fp-ts/lib/Option";
import WhiteCard from "../../../white-card/white-card";
import ReactSwitch from "../../../common/switch/react-switch";
import { unWrapOption } from "../../../../utils/option.utils";

interface props {
  setDefaultApprover: (data: Option<CorporateUser>) => void;
  setAdminMaker: (data: Option<CorporateUser>) => void;
  setCorporateAdmin: (data: Option<CorporateUser>) => void;
  setBusinessUnit: (data: string[]) => void;
  businessUnit: string[];
  setDefaultApproverList: (defaultApproverList: Array<CorporateUser>) => void;
  setAdminMakerList: (adminMakerList: Array<CorporateUser>) => void;
  setCorporateAdminList: (corporateAdminList: Array<CorporateUser>) => void;
  defaultApproverList: Array<CorporateUser>;
  adminMakerList: Array<CorporateUser>;
  corporateAdminList: Array<CorporateUser>;
  defaultApprover: Option<CorporateUser>;
  adminMaker: Option<CorporateUser>;
  corporateAdmin: Option<CorporateUser>;
  isNewClaimFlow: boolean;
  setNewClaimFlow: (isNewClaimFlow: boolean) => void;
  detaxData: boolean;
}

const PreApprovalClaimSelection: React.FC<props> = ({
  setAdminMaker,
  setCorporateAdmin,
  setBusinessUnit,
  setDefaultApprover,
  setDefaultApproverList,
  setAdminMakerList,
  setCorporateAdminList,
  businessUnit,
  defaultApproverList,
  adminMakerList,
  corporateAdminList,
  defaultApprover,
  adminMaker,
  corporateAdmin,
  isNewClaimFlow,
  setNewClaimFlow,
  detaxData,
}) => {
  const [name, setName] = useState<string>("");

  const handleDefaultApprover = (value: string) => {
    const selectedMobile = value;
    const selectedObj = defaultApproverList.find(
      (obj) => obj.mobile === selectedMobile
    );

    if (selectedObj) {
      setDefaultApprover(
        fromNullable({ ...selectedObj, role: CorporateUser.RoleEnum.APPROVER })
      );
    }
  };
  const handleAdminMaker = (value: string) => {
    const selectedMobile = value;
    const selectedObj = adminMakerList.find(
      (obj) => obj.mobile === selectedMobile
    );

    if (selectedObj) {
      const filteredCorporateAdminList = defaultApproverList.filter(
        (corpAdmin) => corpAdmin.mobile != selectedObj.mobile
      );
      setCorporateAdminList(filteredCorporateAdminList);
      setAdminMaker(
        fromNullable({
          ...selectedObj,
          role: CorporateUser.RoleEnum.CORPORATE_ADMIN_MAKER,
        })
      );
    }
  };
  const handleCorporateAdmin = (value: string) => {
    const selectedMobile = value;
    const selectedObj = corporateAdminList.find(
      (obj) => obj.mobile === selectedMobile
    );

    if (selectedObj) {
      const filteredAdminMakerList = defaultApproverList.filter(
        (adminMaker) => adminMaker.mobile != selectedObj.mobile
      );
      setAdminMakerList(filteredAdminMakerList);
      setCorporateAdmin(
        fromNullable({
          ...selectedObj,
          role: CorporateUser.RoleEnum.CORPORATE_ADMIN,
        })
      );
    }

    handleDefaultApprover(value);
  };
  const handleAdd = (): void => {
    if (name !== "") {
      const val = name.trim();
      setBusinessUnit([...businessUnit, val]);
      setName("");
    }
  };

  const handleDelete = (index: number): void => {
    const newData = [...businessUnit];
    newData.splice(index, 1);
    setBusinessUnit(newData);
  };

  return (
    <>
      <WhiteCard className="p-3">
        <div className="d-flex justify-content-between">
          <h6 className="text-secondary fw-bold">Set Business Units</h6>
          <p></p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <span>
                <p className="d-inline fs-small fw-bold">
                  Enter the Business Unit name
                </p>
              </span>
              <span className="ms-2">
                <input
                  placeholder="Enter Business Unit"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleAdd();
                    }
                  }}
                />
                <button
                  type="submit"
                  className="btn-gradiant-blue btn-lg text-white fs-small btn btn-primary ms-2"
                  onClick={handleAdd}
                >
                  Add
                </button>

                <div className="mt-2">
                  {businessUnit.map((item: string, index: number) => (
                    <WhiteCard className="mb-1 p-1 w-25 rounded">
                      <div
                        key={index}
                        className="d-flex flex-row justify-content-between ps-2"
                      >
                        {index + 1}. {item}{" "}
                        <div
                          onClick={() => handleDelete(index)}
                          className="pe-2 pe-auto"
                        >
                          <img src="/images/icons/Close.svg" />
                        </div>
                      </div>
                    </WhiteCard>
                  ))}
                </div>
              </span>
            </div>
          </div>
        </div>
      </WhiteCard>

      {detaxData && (
        <WhiteCard className="p-3">
          <div className="d-flex justify-content-between">
            <h6 className="text-secondary fw-bold">Set Claims flow</h6>
            <p></p>
          </div>
          <div className="container">
            <div className="row mb-4">
              <div className="col d-flex align-items-center">
                <span>
                  <p className="d-inline fs-small fw-bold">Old Claim Flow</p>
                </span>
                <span className="ms-2 me-2">
                  <ReactSwitch
                    switchState={isNewClaimFlow}
                    handleSwitch={(data: boolean) => {
                      setNewClaimFlow(data);
                    }}
                  />
                </span>
                <span>
                  <p className="d-inline fs-small fw-bold">New Claim Flow</p>
                </span>
              </div>
            </div>

            {isNewClaimFlow && (
              <>
                <div className="row">
                  <div className="col">
                    <div className="d-flex justify-content-between">
                      <h6 className="text-secondary fw-bold">Set Users</h6>
                      <p></p>
                    </div>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col">
                    <span>
                      <p className="d-inline fs-small fw-bold">
                        Select Corporate Admin Maker
                      </p>
                    </span>
                    <span className="ms-2">
                      <select
                        defaultValue={
                          isSome(adminMaker) ? adminMaker.value.name : ""
                        }
                        onChange={(e) => handleAdminMaker(e.target.value)}
                        style={cardStyle.txtStyle1}
                      >
                        <option
                          value={
                            isSome(adminMaker) ? adminMaker.value.name : ""
                          }
                          disabled
                          hidden
                        >
                          {/* {isSome(defaultApprover)
                        ? defaultApprover.value.name
                        : "Select Approver"} */}
                          {
                            unWrapOption(adminMaker, () => {
                              return { name: "Select Admin Maker" };
                            }).name
                          }
                        </option>
                        {adminMakerList.map((item) => (
                          <option value={item?.mobile} key={item?.mobile}>
                            {item?.name ?? "No Name"}
                          </option>
                        ))}
                      </select>
                    </span>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col">
                    <span className="me-5">
                      <p className="d-inline fs-small fw-bold">
                        Select Corporate Admin
                      </p>
                    </span>
                    <span className="ms-2">
                      <select
                        defaultValue={""}
                        onChange={(e) => handleCorporateAdmin(e.target.value)}
                        style={cardStyle.txtStyle1}
                      >
                        <option
                          value={
                            isSome(corporateAdmin)
                              ? corporateAdmin.value.name
                              : ""
                          }
                          disabled
                          hidden
                        >
                          {
                            unWrapOption(corporateAdmin, () => {
                              return { name: "Select Corporate Admin" };
                            }).name
                          }
                        </option>
                        {corporateAdminList.map((item) => (
                          <option value={item?.mobile} key={item?.mobile}>
                            {item?.name ?? "No Name"}
                          </option>
                        ))}
                      </select>
                    </span>
                  </div>
                </div>

                {isSome(defaultApprover) && (
                  <div className="row mb-4">
                    <div className="col">
                      <span className="me-5">
                        <p className="d-inline fs-small fw-bold">
                          Default Approver
                        </p>
                      </span>
                      <span className="ms-2">
                        {/* <select
                      defaultValue={
                        isSome(defaultApprover)
                          ? defaultApprover.value.name
                          : ""
                      }
                      style={cardStyle.txtStyle1}
                      onChange={(e) => handleDefaultApprover(e.target.value)}
                    >
                      <option
                        value={
                          isSome(defaultApprover)
                            ? defaultApprover.value.name
                            : ""
                        }
                        disabled
                        hidden
                      >
                        {
                          unWrapOption(defaultApprover, () => {
                            return { name: "Select Approver" };
                          }).name
                        }
                      </option>
                      {defaultApproverList.map((item) => (
                        <option value={item?.mobile} key={item?.mobile}>
                          {item?.name ?? "No Name"}
                        </option>
                      ))}
                    </select> */}
                        <p className="d-inline fs-small fw-bold">
                          {defaultApprover.value?.name}
                        </p>
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </WhiteCard>
      )}
    </>
  );
};

const cardStyle = {
  textAreaContainer: {
    height: 200,
    width: 460,
  },
  txtStyle1: {
    width: 300,
    height: 40,
    borderRadius: 3,
  },
};

export default PreApprovalClaimSelection;
