import React, { useState } from "react";
import WhiteCard from "../../../white-card/white-card";
import { AdminApplicationInfo } from "../../../../api/admin/model/adminApplicationInfo";

const CardVariant: React.FC<{
  status: string;
  setSelectOption: (val: string) => void;
  selectOption: string;
  detaxData: boolean;
}> = ({ status, selectOption, setSelectOption, detaxData }) => {
  // const [selectOption, setSelectOption] = useState("RUPAY");
  const handleSelection = (event: any) => {
    setSelectOption(event.target.value);
  };

  return (
    <>
      <WhiteCard className="p-3">
        <div className="d-flex justify-content-between">
          <h6 className="text-secondary fw-bold">Select Card Variant</h6>
          <p></p>
        </div>
        <div className="container">
          <select
            aria-label="Default select example"
            value={selectOption}
            onChange={handleSelection}
            style={{
              appearance: "auto",
              border: "auto",
              background: "initial",
              padding: "initial",
              // Add any other properties you need to reset
              ...cardStyle.txtStyle1, // Merge with additional styles from cardStyle.txtStyle1
            }}
            disabled={
              status === AdminApplicationInfo.ApplicationStatusEnum.LIVE
                ? detaxData
                : false
            }
          >
            <option value="RUPAY">Rupay Card</option>
            <option value="VISA">Visa Card</option>
          </select>
        </div>
      </WhiteCard>
    </>
  );
};

export default CardVariant;
const cardStyle = {
  textAreaContainer: {
    height: 200,
    width: 460,
  },
  txtStyle1: {
    width: 160,
    height: 30,
    borderRadius: 3,
  },
};
