import classNames from "classnames";
import React from "react";
import { DetaxDispatch } from "../../../../state/slice/detax.slice";
import {
  getTitleCase,
  getTitleCaseToStatus,
} from "../../../../utils/common.utils";
import WhiteCard from "../../../white-card/white-card";
import "../common/card-count.scss";

type Props = {
  isActive: boolean;
  count: number;
  label: string;
};

const CountCard: React.FC<Props> = (props) => {
  //console.log(props);
  return (
    <div
      onClick={() => DetaxDispatch.setFilterTypeStatus(props.label || "All")}
      className="card-count"
    >
      <WhiteCard
        className={classNames("cursor-pointer p-2 w-80 status-dot-card", {
          color0: getTitleCaseToStatus(props.label) === "ALL",
          color2: getTitleCaseToStatus(props.label) === "CREATED",
          color1: getTitleCaseToStatus(props.label) === "SUBMITTED",
          color3: getTitleCaseToStatus(props.label) === "PAYMENT_CONFIRMED",
          color7: getTitleCaseToStatus(props.label) === "EXECUTED",
          color9: getTitleCaseToStatus(props.label) === "ON_HOLD",
          color4: getTitleCaseToStatus(props.label) === "CANCELLED",
          color5: getTitleCaseToStatus(props.label) === "REJECTED",
          color12: getTitleCaseToStatus(props.label) === "EXECUTING",
          color13: getTitleCaseToStatus(props.label) === "FAILED",
          color14: getTitleCaseToStatus(props.label) === "PARTIALLY_EXECUTED",
          active: props.isActive,
        })}
      >
        <div className="status-dot"></div>
        <h2 className="text-black text-center mb-0 fw-bold">{props.count}</h2>
        <p className="text-black text-center mb-1 fw-bold fs-xxsmall">
          {getTitleCase(props.label)}
        </p>
      </WhiteCard>
    </div>
  );
};

export default CountCard;
