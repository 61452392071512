import { Button } from "react-bootstrap";
import CustomSearchBar from "../../custom-search/custom-search";
import WhiteCard from "../../white-card/white-card";

const EmployeeSearch: React.FC<{
  searchVal: string;
  setSearchVal: (val: string) => void;
}> = ({ searchVal, setSearchVal }) => {
  return (
    <WhiteCard className="mb-3 mt-0 p-1 rounded">
      <div className="d-flex justify-content-between">
        <CustomSearchBar
          height="auto"
          width="100%"
          text="Search by Name"
          value={searchVal}
          onChange={setSearchVal}
          // onEnter={() => {
          //   onSearch();
          // }}
        />
      </div>
    </WhiteCard>
  );
};

export default EmployeeSearch;
