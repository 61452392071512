import classNames from "classnames";
import React from "react";

import { useAppSelector } from "../../hooks/hooks";
import { Dispatch } from "../../state/dispatch";
import { DetaxReduxStore } from "../../state/slice/detax.slice";
import { tabData, TTab } from "../pages/dashboard/upload-txn/txn.utils";
import "./transaction-tabs.scss";

type TabProps = {
  tabs?: TTab[];
};

const TransactionTabs: React.FC<TabProps> = ({ tabs = tabData }) => {
  const activeTab = useAppSelector(DetaxReduxStore.selectDetaxType);

  return (
    <div id="exTab2" className="p-0 border-bottom">
      <ul className="nav">
        {tabs.map((tab, index) => {
          return (
            <li className="nav-item cursor-pointer" key={index}>
              <a
                className={classNames("nav-link text-secondary fw-bold fs-6 ", {
                  active: tab.tabValue === activeTab,
                })}
                data-toggle="tab"
                onClick={() => {
                  Dispatch.Detax.setDetaxType(tab.tabValue);
                }}
              >
                {tab.tabLabel}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default React.memo(TransactionTabs);
