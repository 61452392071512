/**
 * Ncaish Admin API
 * Apis to super admin operations
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface LoginRequest { 
    mobile?: string;
    type?: LoginRequest.TypeEnum;
}
export namespace LoginRequest {
    export type TypeEnum = 'NCAISH_ADMIN' | 'CORPORATE_APPLICANT' | 'CORPORATE_ADMIN' | 'RETAIL_INVESTOR' | 'CORPORATE_EMPLOYEE' | 'FX_TRIAL_APPLICANT' | 'FX_TRIAL';
    export const TypeEnum = {
        NCAISHADMIN: 'NCAISH_ADMIN' as TypeEnum,
        CORPORATEAPPLICANT: 'CORPORATE_APPLICANT' as TypeEnum,
        CORPORATEADMIN: 'CORPORATE_ADMIN' as TypeEnum,
        RETAILINVESTOR: 'RETAIL_INVESTOR' as TypeEnum,
        CORPORATEEMPLOYEE: 'CORPORATE_EMPLOYEE' as TypeEnum,
        FXTRIALAPPLICANT: 'FX_TRIAL_APPLICANT' as TypeEnum,
        FXTRIAL: 'FX_TRIAL' as TypeEnum
    };
}

