import { isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { AdminApplicationInfo } from "../../../api/admin/model/adminApplicationInfo";
import {
  AdminInsuranceInfo,
  AdminInsuranceInfoData,
  AdminInsuranceInfoReq,
} from "../../../api/admin/model/adminInsuranceInfo";
import { InsuranceProvidersData } from "../../../api/admin/model/insuranceProvidersRes";
import { ProtectionType } from "../../../enum/protection-type";
import { ProtectionService } from "../../../services/protection.service";
import { getLabelFromEnum } from "../../../utils/common.utils";
import { currencyFormat } from "../../../utils/format.utils";
import SuccessPopup from "../../pages/dashboard/common/succes-popup";
import ErrorPopup from "../common/ErrorPopup";
import PolicyDetailsModal from "./policy-details-modal";

interface props {
  details: AdminApplicationInfo;
  id: string;
  protectData?: AdminInsuranceInfoData;
  setProtectData?: (val: AdminInsuranceInfoData) => void;
  inclusions: { key: string; value: string }[];
  exclusions: { key: string; value: string }[];
  grades: { key: string; value: string }[];
  setGrades: (val: { key: string; value: string }[]) => void;
  setInclusions: (val: { key: string; value: string }[]) => void;
  setExclusions: (val: { key: string; value: string }[]) => void;
  providersData?: Array<InsuranceProvidersData>;
  activeProtect: ProtectionType;
}
const closeIcon = "/images/close.svg";

const OnboardServiceProtect: React.FC<props> = ({
  id,
  details,
  setProtectData = () => {},
  protectData,
  inclusions,
  exclusions,
  grades,
  setExclusions,
  setInclusions,
  setGrades,
  providersData,
  activeProtect,
}) => {
  const [inputData, setInputData] = useState({ key: "", value: "" });
  const [policyEdit, setPolicyEdit] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [successPopup, setSuccessPopup] = useState({
    show: false,
    message: "",
  });
  const [error, setError] = useState({
    show: false,
    message: "",
  });
  const [inputInclusionData, setInputInclusionData] = useState({
    key: "",
    value: "",
  });

  const [inputExclusionData, setInputExclusionData] = useState({
    key: "",
    value: "",
  });

  const handleInputChange = (e: {
    target: { name: string; value: string };
  }) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  const handleInclusionInputChange = (e: {
    target: { name: string; value: string };
  }) => {
    const { name, value } = e.target;
    setInputInclusionData({ ...inputInclusionData, [name]: value });
  };

  const handleExclusionInputChange = (e: {
    target: { name: string; value: string };
  }) => {
    const { name, value } = e.target;
    setInputExclusionData({ ...inputExclusionData, [name]: value });
  };

  const handleAddGrade = () => {
    if (inputData.key && inputData.value) {
      setGrades([
        ...grades.filter((item) => item.key !== inputData.key),
        {
          key: inputData.key,
          value: inputData.value,
        },
      ]);
      setInputData({ key: "", value: "" });
    }
  };

  const handleAddExclusion = () => {
    if (inputExclusionData.key && inputExclusionData.value) {
      setExclusions([
        ...exclusions.filter((item) => item.key !== inputExclusionData.key),
        { key: inputExclusionData.key, value: inputExclusionData.value },
      ]);
      setInputExclusionData({ key: "", value: "" });
    }
  };

  const handleAddInclusion = () => {
    setInclusions([
      ...inclusions.filter((item) => item.key !== inputInclusionData.key),
      { key: inputInclusionData.key, value: inputInclusionData.value },
    ]);
    setInputInclusionData({ key: "", value: "" });
  };

  const handleDeleteInclusion = (key: string) => {
    setInclusions(inclusions.filter((item) => item.key !== key));
  };
  const handleDeleteExclusion = (key: string) => {
    setExclusions(exclusions.filter((item) => item.key !== key));
  };
  const handleDeleteGrades = (key: string) => {
    setGrades(grades.filter((item) => item.key !== key));
  };

  const activeProvider = providersData?.find(
    (x) => x.objectId === protectData?.insurerProviderId
  );

  const updateInsuranceData = async (type: ProtectionType) => {
    let body: AdminInsuranceInfoReq;
    const inclusionsMap = new Map(
      inclusions.map((item) => [item.key, item.value])
    );

    // Convert exclusions array back to a Map
    const exclusionsMap = new Map(
      exclusions.map((item) => [item.key, item.value])
    );

    // Convert grades array back to a Map
    const gradesMap = new Map(
      grades.map((item) => [item.key, parseFloat(item.value)])
    );

    const formData = new FormData();

    delete protectData?.policyDocument;

    delete protectData?.policyLink;

    if (
      activeProtect === ProtectionType.GROUP_MEDICAL &&
      (protectData?.insurerProviderId?.length ?? 0) > 0 &&
      (protectData?.insurancePolicyNo?.length ?? 0) > 0 &&
      (activeProvider?.insurerId?.length ?? 0) > 0
    ) {
      body = {
        ...protectData,
        insuranceType: protectData?.insuranceType,
        insurer: activeProvider?.insurerId,
        insurancePolicyNo: protectData?.insurancePolicyNo,
        policyStartDate: protectData?.policyStartDate,
        policyEndDate: protectData?.policyEndDate,
        inclusions: activeProvider?.pdfEnabled
          ? {}
          : Object.fromEntries(inclusionsMap),
        exclusions: activeProvider?.pdfEnabled
          ? {}
          : Object.fromEntries(exclusionsMap),
        grades: Object.fromEntries(gradesMap),
        imidNo: protectData?.imidNo,
        nomineeCount: parseInt(protectData?.nomineeCount ?? "0"),
        relationAllowed:
          parseInt(protectData?.nomineeCount ?? "0") > 0
            ? protectData?.relationAllowed
            : [],
        insuranceCustomerId: parseInt(protectData?.insuranceCustomerId ?? ""),
        insurerProviderId: protectData?.insurerProviderId,
      };
      if (activeProvider?.pdfEnabled && protectData?.policyDocument) {
        formData.append("medicalFile", protectData?.policyDocument[0]);
      }
    } else if (
      activeProtect === ProtectionType.GROUP_LIFE &&
      (protectData?.insurerProviderId?.length ?? 0) > 0 &&
      (protectData?.insurancePolicyNo?.length ?? 0) > 0 &&
      (activeProvider?.insurerId?.length ?? 0) > 0
    ) {
      body = {
        insuranceType: protectData?.insuranceType,
        insurer: activeProvider?.insurerId,
        insurancePolicyNo: protectData?.insurancePolicyNo,
        policyStartDate: protectData?.policyStartDate,
        policyEndDate: protectData?.policyEndDate,
        inclusions: activeProvider?.pdfEnabled
          ? {}
          : Object.fromEntries(inclusionsMap),
        exclusions: activeProvider?.pdfEnabled
          ? {}
          : Object.fromEntries(exclusionsMap),
        nomineeCount: parseInt(protectData?.nomineeCount ?? "0"),
        grades: Object.fromEntries(gradesMap),
        insurerProviderId: protectData?.insurerProviderId,
      };
      if (activeProvider?.pdfEnabled && protectData?.policyDocument) {
        formData.append("lifeFile", protectData?.policyDocument[0]);
      }
    } else {
      alert("Request Model Error");
      return;
    }

    formData.append("insuranceDetails", JSON.stringify([body]));

    const result = await ProtectionService.updateInsuranceData(id, formData);
    if (isSuccess(result)) {
      if (result.value.successful) {
        setSuccessPopup({ show: true, message: result.value.message ?? "" });
      } else {
        setError({ show: true, message: result.value.message ?? "Failed" });
      }
    } else {
      console.log(result);
      // setAppState(initialState);
      setError({ show: true, message: "Failed" });
    }
  };

  useEffect(() => {
    getInsuranceData(activeProtect);
  }, [successPopup.show, activeProtect]);

  const getInsuranceData = async (type: ProtectionType) => {
    const result = await ProtectionService.getInsuranceData(id, type);
    if (isSuccess(result) && result.value.successful) {
      if ((result.value.data?.length ?? 0) > 0) {
        const final = result.value.data?.find(
          (x) => x.insurance?.insuranceType === type
        )?.insurance;
        if (final?.policyLink) {
          setPolicyEdit(false);
        } else {
          setPolicyEdit(true);
        }
        const inclusionsArray = Object.entries(final?.inclusions ?? {}).map(
          ([key, value]) => ({
            key,
            value,
          })
        );

        const exclusionsArray = Object.entries(final?.exclusions ?? {}).map(
          ([key, value]) => ({
            key,
            value,
          })
        );

        const gradesArray = Object.entries(final?.grades ?? {}).map(
          ([key, value]) => ({
            key,
            value: value.toString(),
          })
        );

        if (inclusionsArray.length > 0 || exclusionsArray.length > 0) {
          setInclusions(inclusionsArray);
          setExclusions(exclusionsArray);
          setProtectData({
            ...final,
            inclusions: inclusionsArray,
            exclusions: exclusionsArray,
            grades: gradesArray,
            imidNo: final?.imidNo,
            nomineeCount: final?.nomineeCount?.toString(),
            insuranceCustomerId: final?.insuranceCustomerId?.toString(),
            insuranceType: final?.insuranceType as ProtectionType,
            insurer: final?.insurer,
            insurerProviderId: final?.insurerProviderId,
          });
        } else {
          getDefaultInclusionData(final);
        }

        setGrades(gradesArray);
      } else {
        setProtectData({
          insurer: "",
          insurerProviderId: "",
          imidNo: "",
          insuranceCustomerId: "",
          insurancePolicyNo: "",
          insuranceAccountNo: "",
          policyStartDate: "",
          policyEndDate: "",
          inclusions: [],
          exclusions: [],
          nomineeCount: "",
          insuranceType: type,
          relationAllowed: [],
          grades: [],
          accountBankNumber: "",
          ifscCode: "",
          beneficiaryName: "",
          policyDocument: [],
        });
        setGrades([]);
        setInclusions([]);
        setExclusions([]);
      }
    } else {
      setProtectData({
        insurer: "",
        insurerProviderId: "",
        imidNo: "",
        insuranceCustomerId: "",
        insurancePolicyNo: "",
        insuranceAccountNo: "",
        policyStartDate: "",
        policyEndDate: "",
        inclusions: [],
        exclusions: [],
        nomineeCount: "",
        insuranceType: type,
        relationAllowed: [],
        grades: [],
        accountBankNumber: "",
        ifscCode: "",
        beneficiaryName: "",
        policyDocument: [],
      });
      setGrades([]);
      setInclusions([]);
      setExclusions([]);
      if (inclusions.length === 0 || exclusions.length === 0) {
        getDefaultInclusionData(undefined, true);
      }
      // setAppState(initialState);
    }
  };

  const getDefaultInclusionData = async (
    data?: AdminInsuranceInfo,
    val?: boolean
  ) => {
    const result = await ProtectionService.getAllPredefinedCoverages();
    if (isSuccess(result)) {
      const inclusionsArray = Object.entries(
        result.value?.inclusions ?? {}
      ).map(([key, value]) => ({
        key,
        value,
      }));

      const exclusionsArray = Object.entries(result.value.exclusions ?? {}).map(
        ([key, value]) => ({
          key,
          value,
        })
      );

      const gradesArray = Object.entries(data?.grades ?? {}).map(
        ([key, value]) => ({
          key,
          value: value.toString(),
        })
      );

      if (val) {
        setProtectData({
          ...protectData,
          inclusions: inclusionsArray,
          exclusions: exclusionsArray,
        });

        setInclusions(inclusionsArray);
        setExclusions(exclusionsArray);
      } else {
        setProtectData({
          ...data,
          inclusions: inclusionsArray,
          exclusions: exclusionsArray,
          grades: gradesArray,
          imidNo: data?.imidNo,
          nomineeCount: data?.nomineeCount?.toString(),
          insuranceCustomerId: data?.insuranceCustomerId?.toString(),
          insuranceType: data?.insuranceType as ProtectionType,
          insurer: data?.insurer,
          insurerProviderId: data?.insurerProviderId,
        });

        setInclusions(inclusionsArray);
        setExclusions(exclusionsArray);
      }
    }
  };

  const relationShip = activeProvider?.relationshipAllowed?.map((y) => ({
    label: getLabelFromEnum(y),
    value: y,
  }));

  return (
    <>
      <div style={{ display: "flex", flexWrap: "wrap" }} className="row">
        {/* <div className="form-group col-md-4 mb-3">
          <p className="m-0" style={{ fontWeight: "600" }}>
            Policy Type <span className="text-danger">*</span>
          </p>

          <select
            className="form-control"
            value={protectData?.insuranceType}
            onChange={(e) => {
              setProtectData({
                ...protectData,
                insuranceType: e.target.value as ProtectionType, // Storing the selected value
              });
            }}
          >
            <option disabled value={""}>
              Select Policy Type
            </option>
            {Object.entries(ProtectionType).map((x) => (
              <option value={x[1]}>{x[1]}</option>
            ))}
          </select>
        </div> */}
        <div className="form-group col-md-4 mb-3">
          <p className=" m-0" style={{ fontWeight: "600" }}>
            Insurer <span className="text-danger">*</span>
          </p>

          <select
            className="form-control"
            value={protectData?.insurerProviderId}
            onChange={(e) => {
              setProtectData({
                ...protectData,
                insurerProviderId: e.target.value,
                nomineeCount: "",
                relationAllowed: [],
              });
            }}
          >
            <option value={""}>Select Insurer</option>
            {Array.isArray(providersData) &&
              providersData
                .filter((x) => x.insuranceType === activeProtect)
                .map((obj, i) => (
                  <option value={obj.objectId} key={i}>
                    {obj.insurerName}{" "}
                    {obj.endorsementOnline ? "Online" : "Offline"}
                  </option>
                ))}
          </select>
        </div>

        <div className="form-group col-md-4 mb-3">
          <p className="m-0" style={{ fontWeight: "600" }}>
            Policy Number <span className="text-danger">*</span>
          </p>
          <input
            className="form-control"
            type="text"
            value={protectData?.insurancePolicyNo}
            onChange={(e) =>
              setProtectData({
                ...protectData,
                insurancePolicyNo: e.target.value,
              })
            }
          />
        </div>
        {activeProtect === ProtectionType.GROUP_MEDICAL && (
          <div className="form-group col-md-4 mb-3 ">
            <p className="m-0" style={{ fontWeight: "600" }}>
              IMID Number
            </p>
            <input
              className="form-control"
              type="text"
              value={protectData?.imidNo}
              onChange={(e) =>
                setProtectData({
                  ...protectData,
                  imidNo: e.target.value,
                })
              }
            />
          </div>
        )}
        {activeProtect === ProtectionType.GROUP_MEDICAL && (
          <div className="form-group col-md-4 mb-3">
            <p className="m-0" style={{ fontWeight: "600" }}>
              Customer ID
            </p>
            <input
              className="form-control"
              type="text"
              value={protectData?.insuranceCustomerId}
              onChange={(e) =>
                setProtectData({
                  ...protectData,
                  insuranceCustomerId: isNaN(parseInt(e.target.value))
                    ? ""
                    : parseInt(e.target.value).toString(),
                })
              }
            />
          </div>
        )}
        {activeProtect === ProtectionType.GROUP_MEDICAL && (
          <div className="form-group col-md-4 mb-3">
            <p className="m-0" style={{ fontWeight: "600" }}>
              CDBG Account Number
            </p>
            <input
              className="form-control"
              type="text"
              value={protectData?.insuranceAccountNo}
              onChange={(e) =>
                setProtectData({
                  ...protectData,
                  insuranceAccountNo: e.target.value,
                })
              }
            />
          </div>
        )}
        <div className="form-group col-md-4 mb-3">
          <p className="m-0" style={{ fontWeight: "600" }}>
            Policy Start Date <span className="text-danger">*</span>
          </p>
          <input
            className="form-control"
            type="date"
            value={protectData?.policyStartDate}
            onChange={(e) =>
              setProtectData({
                ...protectData,
                policyStartDate: e.target.value,
              })
            }
          />
        </div>
        <div className="form-group col-md-4 mb-3">
          <p className="m-0" style={{ fontWeight: "600" }}>
            Policy End Date <span className="text-danger">*</span>
          </p>
          <input
            className="form-control"
            type="date"
            value={protectData?.policyEndDate}
            onChange={(e) =>
              setProtectData({
                ...protectData,
                policyEndDate: e.target.value,
              })
            }
          />
        </div>
      </div>
      <div
        className="col-md-12 mb-3 mt-3"
        style={{ border: "1px solid #6d6d6d" }}
      />
      <div className="row">
        {details.applicationStatus ===
          AdminApplicationInfo.ApplicationStatusEnum.LIVE && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className="mb-3"
            >
              <p className="m-0" style={{ fontWeight: "600" }}>
                Policy Document
              </p>
              {activeProvider?.pdfEnabled && (
                <>
                  {!policyEdit && (
                    <div>
                      <button
                        className="btn-gradiant-blue text-white px-3 py-2 me-2"
                        onClick={() => {
                          setPolicyEdit(true);
                        }}
                      >
                        Upload
                      </button>
                      <button
                        className="btn-gradiant-blue text-white px-3 py-2"
                        onClick={() => {
                          setShowDetails(true);
                        }}
                      >
                        View
                      </button>
                    </div>
                  )}
                  {policyEdit && (protectData?.policyLink?.length ?? 0) > 0 && (
                    <div>
                      <button
                        className="btn-gradiant-blue text-white px-3 py-2 me-2"
                        onClick={() => {
                          setPolicyEdit(false);
                          setProtectData({
                            ...protectData,
                            policyDocument: [],
                          });
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
            {activeProvider?.pdfEnabled && (
              <>
                {policyEdit && (
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      let arr: File[] = [];
                      acceptedFiles.forEach((file) => {
                        if (file.name.split(".").pop() === "pdf")
                          arr = [...arr, file];
                      });
                      setProtectData({ ...protectData, policyDocument: arr });
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section
                        className="p-3 text-center my-4 mx-auto wrap-file-upload "
                        role="button"
                        style={{
                          border: "2px dashed #d0d0db",
                          width: "80%",
                          borderRadius: "1rem",
                        }}
                      >
                        <div {...getRootProps()}>
                          <input {...getInputProps()} accept=".pdf" />
                          <svg
                            id="Group_2844"
                            width="60"
                            data-name="Group 2844"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 158.798 190.158"
                          >
                            <path
                              id="Path_6474"
                              data-name="Path 6474"
                              d="M258.215,314.354H215.523A12.651,12.651,0,0,1,202.872,301.7V246.6h-43.22l77.866-77.772,77.23,77.275h-43.88v55.6A12.651,12.651,0,0,1,258.215,314.354Z"
                              transform="translate(-157.192 -168.825)"
                              fill="#cecece"
                            />
                            <rect
                              id="Rectangle_365"
                              data-name="Rectangle 365"
                              width="158.798"
                              height="21.5"
                              rx="10.75"
                              transform="translate(0 168.659)"
                              fill="#cecece"
                            />
                          </svg>
                          <div
                            className="w-25 px-4 mx-auto mt-2"
                            style={{ fontSize: 16 }}
                          >
                            <p>Drag and drop here </p>
                            <p>or</p>
                            <p className="">Browse Files</p>
                          </div>
                          <ul className="files-list">
                            {protectData?.policyDocument?.map((file, fk) => (
                              <li key={fk}>{file.name}</li>
                            ))}
                          </ul>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                )}
                {(protectData?.policyDocument?.length ?? 0) > 0 && (
                  <div className="mb-4" style={{ textAlign: "right" }}>
                    <button
                      className="btn-ncaish-light text-white px-3 py-2"
                      onClick={() => {
                        setProtectData({ ...protectData, policyDocument: [] });
                      }}
                    >
                      Remove
                    </button>
                  </div>
                )}
              </>
            )}
          </>
        )}
        {!activeProvider?.pdfEnabled && (
          <>
            <div className="form-group col-md-6 mb-3">
              <div className="">
                <p className="m-0" style={{ fontWeight: "600" }}>
                  Enter Inclusion
                </p>
                <div className=" d-flex flex-row justify-content-between">
                  <input
                    className="form-control"
                    type="text"
                    name="key"
                    style={{
                      alignSelf: "stretch",
                      width: "48%",
                    }}
                    value={inputInclusionData.key}
                    placeholder="Inclusion Name"
                    onChange={handleInclusionInputChange}
                  />
                  <input
                    className="form-control"
                    type="text"
                    name="value"
                    style={{
                      alignSelf: "stretch",
                      width: "48%",
                    }}
                    value={inputInclusionData.value}
                    placeholder="Inclusion Description"
                    onChange={handleInclusionInputChange}
                  />
                </div>
              </div>

              <div className="">
                <button
                  className="btn-gradiant-blue text-white px-3  py-2 mt-2"
                  onClick={handleAddInclusion}
                  disabled={
                    inputInclusionData.key.length === 0 ||
                    inputInclusionData.value.length === 0
                  }
                >
                  Add
                </button>
              </div>

              <div>
                <div className="m-2">
                  {inclusions.length > 0 && <h5>Added Inclusions:</h5>}
                  <ul className="list-group">
                    {inclusions.map((obj, index) => (
                      <li
                        key={index}
                        className="list-group-item d-flex flex-column align-items-start"
                      >
                        <p
                          style={{ color: "black", fontSize: 14 }}
                          className="m-0 fw-bold"
                        >
                          {obj.key}
                        </p>
                        <p
                          style={{ color: "#6D6D6D", fontSize: 14 }}
                          className="m-0"
                        >
                          {obj.value}
                        </p>{" "}
                        <button
                          onClick={() => handleDeleteInclusion(obj.key)}
                          style={{
                            backgroundColor: "transparent",
                            border: 0,
                            position: "absolute",
                            right: 7,
                          }}
                        >
                          <img src={closeIcon} />
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="form-group col-md-6 mb-3">
              <div className="">
                <p className="m-0" style={{ fontWeight: "600" }}>
                  Enter Exclusion
                </p>
                <div className="d-flex flex-row justify-content-between">
                  <input
                    className="form-control"
                    type="text"
                    name="key"
                    style={{
                      alignSelf: "stretch",
                      width: "48%",
                    }}
                    value={inputExclusionData.key}
                    placeholder="Exclusion Name"
                    onChange={handleExclusionInputChange}
                  />
                  <input
                    className="form-control"
                    type="text"
                    name="value"
                    style={{
                      alignSelf: "stretch",
                      width: "48%",
                    }}
                    value={inputExclusionData.value}
                    placeholder="Exclusion Description"
                    onChange={handleExclusionInputChange}
                  />
                </div>
              </div>

              <div className="">
                <button
                  className="btn-gradiant-blue text-white px-3  py-2 mt-2 mt-2"
                  onClick={handleAddExclusion}
                  disabled={
                    inputExclusionData.key.length === 0 ||
                    inputExclusionData.value.length === 0
                  }
                >
                  Add
                </button>
              </div>

              <div>
                <div className="m-2">
                  {exclusions.length > 0 && <h5>Added Exclusions:</h5>}
                  <ul className="list-group">
                    {exclusions.map((obj, index) => (
                      <li
                        key={index}
                        className="list-group-item d-flex flex-column align-items-start"
                      >
                        <p
                          style={{ color: "black", fontSize: 14 }}
                          className="m-0 fw-bold"
                        >
                          {obj.key}
                        </p>
                        <p
                          style={{ color: "#6D6D6D", fontSize: 14 }}
                          className="m-0"
                        >
                          {obj.value}
                        </p>
                        <button
                          onClick={() => handleDeleteExclusion(obj.key)}
                          style={{
                            backgroundColor: "transparent",
                            border: 0,
                            position: "absolute",
                            right: 7,
                          }}
                        >
                          <img src={closeIcon} />
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="col-md-12 mb-3" style={{ border: "1px solid #6d6d6d" }} />
      <div className="row">
        <div className="form-group col-md-6 mb-3">
          <p className="m-0" style={{ fontWeight: "600" }}>
            {activeProtect === ProtectionType.GROUP_MEDICAL
              ? "Dependent Counts Allowed"
              : "Max No. of Nominees"}{" "}
            <span className="text-danger">*</span>
          </p>
          <input
            className="form-control"
            type="number"
            value={protectData?.nomineeCount}
            onChange={(e) =>
              setProtectData({
                ...protectData,
                nomineeCount: isNaN(parseInt(e.target.value))
                  ? ""
                  : parseInt(e.target.value).toString(),
              })
            }
          />
          {activeProtect === ProtectionType.GROUP_MEDICAL &&
            parseInt(protectData?.nomineeCount ?? "") === 0 && (
              <p className="m-0" style={{ fontSize: 14, color: "#105099" }}>
                Only self-insurance will be covered
              </p>
            )}
        </div>
        {activeProtect === ProtectionType.GROUP_MEDICAL &&
          parseInt(protectData?.nomineeCount ?? "") !== 0 && (
            <div className="form-group col-md-6 mb-3">
              <p className="m-0" style={{ fontWeight: "600" }}>
                Dependent Relationships Allowed{" "}
                <span className="text-danger">*</span>
              </p>
              <Select
                isMulti
                styles={{
                  container: (base: any) => ({
                    ...base,
                    alignSelf: "stretch",
                  }),
                }}
                options={relationShip}
                value={protectData?.relationAllowed?.map((item) => ({
                  value: item,
                  label: item,
                }))} // Map to the expected format
                onChange={(selectedOptions: any) => {
                  setProtectData({
                    ...protectData,
                    relationAllowed: selectedOptions.map(
                      (option: any) => option.value
                    ), // Extract values as strings
                  });
                }}
                // className="form-control"
                closeMenuOnSelect={false}
                // components={animatedComponents}
              />
            </div>
          )}
      </div>
      <div className="col-md-12 mb-3" style={{ border: "1px solid #6d6d6d" }} />
      <div className="row">
        <div className="form-group col-md-6 mb-3">
          <p className="m-0" style={{ fontWeight: "600" }}>
            Enter Grade <span className="text-danger">*</span>
          </p>
          <div className="d-flex flex-row justify-content-between">
            <input
              className="form-control"
              type="text"
              name="key"
              style={{
                alignSelf: "stretch",
                width: "48%",
              }}
              value={inputData.key}
              placeholder="Grade"
              onChange={handleInputChange}
            />
            <input
              className="form-control"
              type="text"
              name="value"
              style={{
                alignSelf: "stretch",
                width: "48%",
              }}
              value={inputData.value}
              placeholder="Sum Insured"
              onChange={handleInputChange}
            />
          </div>

          <button
            className="btn-gradiant-blue text-white px-3  py-2 mt-2 mt-2"
            onClick={handleAddGrade}
            disabled={
              inputData.key.length === 0 || inputData.value.length === 0
            }
          >
            Add
          </button>

          {grades.length > 0 && <h5>Added Grades:</h5>}
          <ul className="list-group">
            {grades.map((obj, index) => (
              <li
                key={index}
                className="list-group-item d-flex flex-column align-items-start"
              >
                <p
                  style={{ color: "black", fontSize: 14 }}
                  className="m-0 fw-bold"
                >
                  {obj.key}
                </p>
                <p style={{ color: "#6D6D6D", fontSize: 14 }} className="m-0">
                  {currencyFormat(parseFloat(obj.value))}
                </p>{" "}
                <button
                  onClick={() => handleDeleteGrades(obj.key)}
                  style={{
                    backgroundColor: "transparent",
                    border: 0,
                    position: "absolute",
                    right: 7,
                  }}
                >
                  <img src={closeIcon} />
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {activeProtect === ProtectionType.GROUP_MEDICAL && (
        <>
          <div
            className="col-md-12 mb-3"
            style={{ border: "1px solid #6d6d6d" }}
          />
          <div className="row">
            <div className="form-group col-md-4 mb-3">
              <p className="m-0" style={{ fontWeight: "600" }}>
                Account Number
              </p>
              <input
                className="form-control"
                value={protectData?.accountBankNumber}
                onChange={(e) =>
                  setProtectData({
                    ...protectData,
                    accountBankNumber: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group col-md-4 mb-3">
              <p className="m-0" style={{ fontWeight: "600" }}>
                IFSC Code
              </p>
              <input
                className="form-control"
                value={protectData?.ifscCode}
                onChange={(e) =>
                  setProtectData({
                    ...protectData,
                    ifscCode: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group col-md-4 mb-3">
              <p className="m-0" style={{ fontWeight: "600" }}>
                Beneficiary Name
              </p>
              <input
                className="form-control"
                value={protectData?.beneficiaryName}
                onChange={(e) =>
                  setProtectData({
                    ...protectData,
                    beneficiaryName: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </>
      )}
      {details?.applicationStatus ===
        AdminApplicationInfo.ApplicationStatusEnum.LIVE && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
          className="me-2"
        >
          <button
            className="btn-gradiant-blue text-white px-3  py-2 mt-2 me-2"
            onClick={() => {
              updateInsuranceData(activeProtect);
            }}
          >
            Save
          </button>
        </div>
      )}
      <PolicyDetailsModal
        show={showDetails}
        onClose={() => {
          setShowDetails(false);
        }}
        policyLink={protectData?.policyLink}
      />
      <SuccessPopup
        message={successPopup.message}
        show={successPopup.show}
        onClose={(flag) => setSuccessPopup({ show: false, message: "" })}
      />
      <ErrorPopup
        errorText={error.message}
        show={error.show}
        onHide={(flag) => {
          setError({ show: false, message: "" });
        }}
      />
    </>
  );
};

export default OnboardServiceProtect;
