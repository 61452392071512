export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number
) => {
  let timeout: NodeJS.Timeout;

  const debounced = (...args: Parameters<F>) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export const fromPublic = (path: string): string => {
  return `${path}`;
};

export const pathName = (screen: string) => {
  return `/${screen.toLowerCase()}`;
};

export const validateName = (name: string): boolean => {
  return /^[a-zA-Z\s\.]{1,255}$/gi.test(name);
};
export const validateEmail = (email: string): boolean => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi.test(
    email
  );
};
export const validateMobile = (mobile: string | number): boolean => {
  return /^[0-9]{10}$/gi.test(mobile.toString());
};

export const adminTableHeight = (pagination = false) => {
  let height = 0;
  if (document) {
    let body = document.querySelector("body");
    let el1 = document.querySelector("#sub-header");
    let el2 = document.querySelector(".page-heading");
    let el3 = document.querySelector("#custom-search");
    let el4 = document.querySelector(".card-counts");
    height =
      (body?.clientHeight || 0) -
      ((el1?.clientHeight || 0) +
        (el2?.clientHeight || 0) +
        (el3?.clientHeight || 0) +
        (el4?.clientHeight || 0));
  }
  let minusHeight = pagination ? 170 : 150;
  let res = height - minusHeight;
  if (height - 120 > 0) return res + "px";
  else return "auto";
};

export const downloadImage = (file: any) => {
  console.log(file);
  fetch(file, {
    method: "GET",
    headers: {},
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        let fileName = file.replace(/\?.*/gi, "").split("/");
        fileName = fileName[fileName.length - 1];
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export function titleCase(inputStr: string) {
  let str = inputStr.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

export function getTitleCase(inputStr: string) {
  return titleCase(inputStr).replace("_", " ");
}

export function getTitleCaseToStatus(inputStr: string) {
  return inputStr.trim().toUpperCase().replace(" ", "_");
}

export const formatString = (val: string) => {
  const formattedName = val
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedName;
};

export const getLabelFromEnum = (val: string) => {
  const formattedName = val
    .toLowerCase()
    .replace(/_/g, " ") // Replace underscores with spaces
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedName;
};
