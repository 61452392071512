import React from "react";
import { Modal } from "react-bootstrap";
import { TEligibleUserinfo } from "../../../../../models/batches.models";
import { unWrapOption } from "../../../../../utils/option.utils";

// this popup is for create batch transaction
const TrasactionPopup: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSubmit: () => void;
  users: TEligibleUserinfo[];
}> = ({ show, closeModal, onSubmit, users }) => {
  return (
    <>
      <Modal show={show} onHide={() => closeModal()} centered size="lg">
        <Modal.Header>
          <Modal.Title> More Details Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body p-0">
            <div className="row border rounded-10 p-2 m-1">
              <div className="col-md-6">
                <h6 className="text-secondary">Total No. of Employee</h6>
                <h4 className="text-black mb-0">{users.length}</h4>
              </div>
              <div className="col-md-6 text-end">
                <h6 className="text-secondary">Total Amount (INR)</h6>
                <h4 className="text-black mb-0">
                  {users.reduce(
                    (curr, acc) =>
                      curr + unWrapOption(acc.amountToAdd, () => 0),
                    0
                  )}
                </h4>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn-ncaish-light text-white px-3 py-2"
            onClick={() => closeModal()}
          >
            Cancel
          </button>
          <button
            className="btn-gradiant-blue text-white px-3 py-2"
            onClick={onSubmit}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TrasactionPopup;
