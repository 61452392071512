import React from "react";
import CustomSearchBar from "../../custom-search/custom-search";
import WhiteCard from "../../white-card/white-card";
import { Button } from "react-bootstrap";

const CorporateSearch: React.FC<{
  searchVal: string;
  setSearchVal: (val: string) => void;
  onSearch?: () => void;
}> = ({ searchVal, setSearchVal, onSearch = () => {} }) => {
  return (
    <WhiteCard className="mb-4 mt-2 p-1 rounded">
      <div className="d-flex justify-content-between">
        <CustomSearchBar
          height="auto"
          width="100%"
          text="Search by Corporate Name"
          value={searchVal}
          onChange={setSearchVal}
          onEnter={() => {
            onSearch();
          }}
        />
        <Button
          className="btn-gradiant-blue btn-lg text-white fs-small m-1"
          onClick={() => onSearch()}
        >
          Search
        </Button>
      </div>
    </WhiteCard>
  );
};

export default CorporateSearch;
