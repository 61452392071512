/**
 * Ncaish Admin API
 * Apis to super admin operations
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CorporateServiceInfo {
  type?: CorporateServiceInfo.TypeEnum;
  name?: string;
  img?: string;
  description?: string;
  selected?: boolean;
}
export namespace CorporateServiceInfo {
  export type TypeEnum =
    | "FX"
    | "FAFC"
    | "DETAX_CARD"
    | "NPS"
    | "AA"
    | "MEMBERSHIP"
    | "WEALTH"
    | "PROTECTION";
  export const TypeEnum = {
    FX: "FX" as TypeEnum,
    FAFC: "FAFC" as TypeEnum,
    DETAX_CARD: "DETAX_CARD" as TypeEnum,
    NPS: "NPS" as TypeEnum,
    AA: "AA" as TypeEnum,
    MEMBERSHIP: "MEMBERSHIP" as TypeEnum,
    WEALTH: "WEALTH" as TypeEnum,
    PROTECTION: "PROTECTION" as TypeEnum,
  };
}
