import { isSuccess } from "@devexperts/remote-data-ts";
import { none } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AdminMembershipSubscriptionList } from "../../../api/admin/model/membership/AdminMembershipSubscriptionList";
import { MembershipService } from "../../../services/membership.service";
import { unWrapOption } from "../../../utils/option.utils";

const DATA = {
  categoriesWithBrand: {
    food: ["Zomato", "Swiggy", "logesh"],
    Health: ["Practo", "Appolo"],
  },
};

const ExecutePopup: React.FC<{
  cId?: string;
  show: boolean;
  onHide: (show: boolean) => void;
  // membershipInfo: any;
  onExecute: (
    amtRecieved?: number,
    transId?: string,
    transDate?: string,
    paymentMadeDate?: string
  ) => void;
}> = ({
  cId,
  show,
  onHide,
  // membershipInfo,
  onExecute,
}) => {
  const dateRef = React.createRef<ReactDatePicker>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [subscriptionList, setSubscriptionList] =
    useState<AdminMembershipSubscriptionList>();

  const data = DATA.categoriesWithBrand;

  const handleSubmit = () => {
    let finalDate: string;
    let date: string;
    let month: string;
    if (startDate) {
      const d = startDate.getDate();
      const m = startDate.getMonth() + 1;

      date = d < 10 ? `0${d}` : `${d}`;
      month = m < 10 ? `0${m}` : `${m}`;
      finalDate = `${date}-${month}-${startDate.getFullYear()}`;
    } else {
      console.log("<<access deinied due to wrong input>>");
      return;
    }
    onExecute(0, "", "", finalDate);
  };

  const getSubscriptionList = async () => {
    if (cId) {
      const result =
        await MembershipService.getMembershipSubcriptionListWithCId(cId);

      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          // return result;
          // setSubscriptionList(result);
          const val: AdminMembershipSubscriptionList = {
            Food: [],
            Health: [],
          };
          setSubscriptionList(unWrapOption(result.value.data, () => val));
        } else {
          // Dispatch.App.setCommonError({
          //   showError: true,
          //   errorText: unWrapOption(result.value.message, () => ""),
          // });
          return none;
        }
      } else {
        console.log("Error MembershipDetailsExecutePopup: ", result);
        return none;
      }
    }
  };

  useEffect(() => {
    if (show) {
      getSubscriptionList();
    }
  }, [show]);

  return (
    <Modal show={show} onHide={() => onHide(false)} centered>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="text-center text-black fw-semibold fs-6">
                Confirm Payment Details
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="text-sm-start text-center text-black fs-6">
                Confirm if the payment has been made to the following providers
              </p>
            </div>
          </div>
          <div className="row mb-2" style={{ paddingLeft: "2rem" }}>
            {subscriptionList &&
              Object.values(subscriptionList).map((item1, index1) =>
                item1.map((item2: string, index2: Number) => (
                  <div className="form-check" key={`${index1}+${index2}`}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label className="form-check-label">{item2}</label>
                  </div>
                ))
              )}
          </div>
          <div className="row mb-2">
            <div className="col-sm-5 col-12 d-flex align-items-center">
              <p className="my-1">Payment Made On</p>
            </div>
            <div className="col d-flex align-items-stretch">
              <div
                className="w-100 mx-sm-0 mx-2 border border-dark row"
                onClick={() => {
                  if (dateRef.current) {
                    dateRef.current.setFocus();
                  }
                }}
              >
                <div className="col p-0 d-flex align-items-center">
                  <div className="my-1 p-1 w-100  hide-border-none">
                    <ReactDatePicker
                      ref={dateRef}
                      name="dateRef"
                      maxDate={new Date()}
                      selected={startDate}
                      // onChangeRaw={(e) => {
                      //   e.preventDefault();
                      // }}
                      dateFormat={"dd/MM/yyyy"}
                      placeholderText={"dd/mm/yyyy"}
                      onChange={(date) => setStartDate(date)}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex justify-content-center">
              <button
                className="btn-ncaish-light text-white fs-small px-3 py-2 w-75 justify-content-center"
                onClick={() => onHide(false)}
              >
                CANCEL
              </button>
            </div>
            <div className="col d-flex justify-content-center">
              <button
                className="btn-gradiant-blue btn-lg text-white fs-small btn btn-primary px-3 py-2 w-75 justify-content-center"
                onClick={handleSubmit}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ExecutePopup;
