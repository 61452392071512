import { Form, Formik } from "formik";
import React from "react";

interface Props {
  validation: any;
  initialValue: any;
  handleSubmit: any;
  children: any;
}

const FormikComponent: React.FC<Props> = ({
  children,
  validation,
  initialValue,
  handleSubmit,
  ...otherProps
}) => {
  return (
    <div>
      <Formik
        initialValues={initialValue}
        validationSchema={validation}
        onSubmit={(values) => handleSubmit(values)}
        {...otherProps}
      >
        <Form {...otherProps}>{children}</Form>
      </Formik>
    </div>
  );
};

export default FormikComponent;
