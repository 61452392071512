import React, { useEffect } from "react";
import WhiteCard from "../../white-card/white-card";
import DetaxPlusSubscription from "./subscription details/detax-plus-subscription";
import DetaxSubscription from "./subscription details/detax-subscription";

const Detax = "/images/detax.png";
const DetaxPlus = "/images/detaxPlus.png";

const CorporateEmployeeSubscription: React.FC<{
  cId?: string;
  show: (val: boolean) => void;
}> = ({ cId, show }) => {
  return (
    <WhiteCard className="p-3">
      <div className="row">
        <div className="col d-flex justify-content-between">
          <h6 className="text-secondary fw-bold">Subscription Details</h6>
        </div>
        <div className="row">
          <div className="col-11">
            <div className="row">
              <div className=" col-md-3">
                <div className="row">
                  <div className="col-2 col-md-2 text-right">
                    <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                      <img
                        src={Detax}
                        className="img-fluid ml-auto mr-auto"
                        width="18"
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="col-9 col-md-9 ps-0 text-left">
                    <p className="mb-0 ncash_fs-12 text-muted">Detax</p>
                    <DetaxSubscription cId={cId} />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row">
                  <div className="col-2 col-md-2 text-right">
                    <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                      <img
                        src={DetaxPlus}
                        className="img-fluid ml-auto mr-auto"
                        width="18"
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="col-9 col-md-9 ps-0 text-left d-flex justify-content-start">
                    <div className="row">
                      <div className="col-6">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-start">
                            <p className="mb-0 ncash_fs-12 text-muted me-1">
                              Detax +
                            </p>
                          </div>
                          <div className="col-12 d-flex justify-content-start">
                            <DetaxPlusSubscription cId={cId} />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <a
                          href="#"
                          onClick={() => show(true)}
                          className="ncash_font-14 link-primary"
                        >
                          Click here
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WhiteCard>
  );
};

export default CorporateEmployeeSubscription;
