/**
 * Ncaish Admin API
 * Apis to super admin operations
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AdminBrInfo } from "./adminBrInfo";
import { AdminCorpInfo } from "./adminCorpInfo";
import { ApplicantInfo } from "./applicantInfo";
import { CorporateServiceInfo } from "./corporateServiceInfo";
import { CorporateUser } from "./models";

export interface AdminApplicationInfo {
  corporateInfo?: AdminCorpInfo;
  applicantInfo?: ApplicantInfo;
  adminData?: Array<CorporateUser>;
  brInfo?: AdminBrInfo;
  services?: Array<CorporateServiceInfo>;
  applicationStatus?: AdminApplicationInfo.ApplicationStatusEnum;
  accountStatus?: AdminApplicationInfo.AccountStatusEnum;
  appCode?: string;
  claimsNew?: boolean;
  businessUnit?: string[];
  userInfo?: CorporateUser[];
  provider?: string;
}

export interface SelectedServicesInfo {
  type: CorporateServiceInfo.TypeEnum;
  name: string;
  img: any;
  description: string;
  selected: boolean;
}
export namespace AdminApplicationInfo {
  export type ApplicationStatusEnum =
    | "CIN_VERIFIED"
    | "SIGNUP_COMPLETED"
    | "SIGNATORY_UPDATED"
    | "SERVICE_SELECTION_COMPLETED"
    | "BR_SENT"
    | "REVIEW_PENDING"
    | "LIVE"
    | "REJECTED"
    | "EXPIRED";
  export const ApplicationStatusEnum = {
    CIN_VERIFIED: "CIN_VERIFIED" as ApplicationStatusEnum,
    SIGNUP_COMPLETED: "SIGNUP_COMPLETED" as ApplicationStatusEnum,
    SIGNATORY_UPDATED: "SIGNATORY_UPDATED" as ApplicationStatusEnum,
    SERVICE_SELECTION_COMPLETED:
      "SERVICE_SELECTION_COMPLETED" as ApplicationStatusEnum,
    BR_SENT: "BR_SENT" as ApplicationStatusEnum,
    REVIEW_PENDING: "REVIEW_PENDING" as ApplicationStatusEnum,
    LIVE: "LIVE" as ApplicationStatusEnum,
    REJECTED: "REJECTED" as ApplicationStatusEnum,
    EXPIRED: "EXPIRED" as ApplicationStatusEnum,
  };
  export type AccountStatusEnum = "ACTIVE" | "INACTIVE" | "BLOCKED";
  export const AccountStatusEnum = {
    ACTIVE: "ACTIVE" as AccountStatusEnum,
    INACTIVE: "INACTIVE" as AccountStatusEnum,
    BLOCKED: "BLOCKED" as AccountStatusEnum,
  };
}
