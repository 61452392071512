/**
 * Ncaish Admin API
 * Apis to super admin operations
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CorporateUser } from "./corporateUser";

export interface BrReceipientInfo {
  type?: BrReceipientInfo.TypeEnum;
  status?: BrReceipientInfo.StatusEnum;
  info?: CorporateUser;
}
export namespace BrReceipientInfo {
  export type TypeEnum = "DIRECTOR" | "SIGNATORY";
  export const TypeEnum = {
    DIRECTOR: "DIRECTOR" as TypeEnum,
    SIGNATORY: "SIGNATORY" as TypeEnum,
  };
  export type StatusEnum = "PENDING" | "SIGNED";
  export const StatusEnum = {
    PENDING: "PENDING" as StatusEnum,
    SIGNED: "SIGNED" as StatusEnum,
  };
}
