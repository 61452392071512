/**
 * Ncaish Admin API
 * Apis to super admin operations
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AccountVerification } from './accountVerification';


export interface AdminTrialInfo { 
    id?: string;
    companyName?: string;
    type?: AdminTrialInfo.TypeEnum;
    name?: string;
    email?: string;
    mobile?: string;
    verification?: AccountVerification;
    remarks?: string;
    startDate?: string;
    endDate?: string;
    state?: AdminTrialInfo.StateEnum;
}
export namespace AdminTrialInfo {
    export type TypeEnum = 'FX' | 'FAFC';
    export const TypeEnum = {
        FX: 'FX' as TypeEnum,
        FAFC: 'FAFC' as TypeEnum
    };
    export type StateEnum = 'PENDING' | 'LIVE' | 'EXPIRED';
    export const StateEnum = {
        PENDING: 'PENDING' as StateEnum,
        LIVE: 'LIVE' as StateEnum,
        EXPIRED: 'EXPIRED' as StateEnum
    };
}

