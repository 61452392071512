import React, { useState } from "react";
import { format, parseISO } from "date-fns";
import { Modal } from "react-bootstrap";

import { unWrapOption } from "../../../../../utils/option.utils";

export interface initiateRequest {
  amount: string;
  modeOfPayment: string;
  date: string;
  referenceId: string;
}

// this popup is for initiate transaction
const InitiateTransactionPopup: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSubmit: (req: initiateRequest) => void;
}> = ({ show, closeModal, onSubmit }) => {
  const [amount, setAmount] = useState<string>("");
  const [modeOfPayment, setModeOfPayment] = useState<string>("NEFT");
  const [date, setDate] = useState<string>();
  const [referenceId, setReferenceID] = useState<string>("");

  console.log(format(new Date(), "dd-MM-yyyy"));
  //   console.log(
  //     amount + ",  " + modeOfPayment + ",  " + date &&
  //       format(parseISO(date || "10-05-2022"), "dd-MM-yyyy") + ",  " + referenceId
  //   );
  return (
    <>
      <Modal show={show} onHide={() => closeModal()} centered size="lg">
        <Modal.Header>
          <Modal.Title> Transactions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-md-6 mb-3">
                <input
                  required
                  value={amount}
                  onChange={(e) => setAmount(e.target.value.toString())}
                  type="number"
                  className="form-control form-control-lg"
                  placeholder="Amount"
                />
              </div>
              <div className="col-md-6">
                <select
                  value={modeOfPayment}
                  className="form-control form-control-lg"
                  onChange={(e) => setModeOfPayment(e.target.value)}
                >
                  <option value="NEFT">NEFT</option>
                  <option value="IMPS">IMPS</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <input
                  type="date"
                  className="form-control form-control-lg border-0"
                  value={date}
                  style={{ height: 49 }}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <input
                  required
                  value={referenceId}
                  onChange={(e) => setReferenceID(e.target.value)}
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Reference ID"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn-ncaish-light text-white px-3 py-2"
            onClick={() => closeModal()}
          >
            Cancel
          </button>
          <button
            className="btn-gradiant-blue text-white px-3 py-2"
            onClick={() =>
              onSubmit({ amount, modeOfPayment, date: date || "", referenceId })
            }
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InitiateTransactionPopup;
