import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "././reject-popup.scss";

const ResetUserPassword: React.FC<{
  show: boolean;
  data: {
    name: string;
    uid: string;
    corpName: string;
    email: string;
  };
  onHide: (show: boolean) => void;
  onApprove: (data: {
    name: string;
    uid: string;
    corpName: string;
    email: string;
  }) => void;
}> = ({ show, onHide, onApprove, data }) => {
  return (
    <Modal show={show} onHide={() => onHide(false)} centered>
      <Modal.Header>
        <p className="m-0" style={{ fontWeight: "bold", fontSize: 16 }}>
          Reset Password
        </p>
      </Modal.Header>
      <Modal.Body>
        <p style={{ fontSize: 14 }} className="m-0">
          The app password for{" "}
          <span style={{ fontWeight: "bold" }}>{data.name}</span> will be reset
          to the first four letters of the user's name followed by ddmmyyyy
          which is the DOB.
        </p>
      </Modal.Body>

      <Modal.Footer className=" d-flex justify-content-right">
        <Button
          className="btn-gradiant-gray btn-lg text-white fs-small m-1 me-2"
          variant="secondary"
          onClick={() => onHide(false)}
        >
          Cancel
        </Button>
        <Button
          className="btn-gradiant-blue btn-lg text-white fs-small m-1 me-2"
          onClick={() =>
            onApprove({
              name: data.name,
              uid: data.uid,
              corpName: data.corpName,
              email: data.email,
            })
          }
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResetUserPassword;
