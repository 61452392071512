export enum AmplitudeEvent {
  TABBAR_ONBOARDING = "TabBar - Onboarding",
  TABBAR_CORPORATE = "TabBar - Corporate",
  TABBAR_EMPLOYEES = "TabBar - Employees",
  TABBAR_TRIAL = "TabBar - Trial",
  TABBAR_BATCH = "TabBar - Batch",
  TABBAR_KIT = "TabBar - Kit",
  TABBAR_MEMBERSHIPS = "TabBar - Memberships",
  ONBOARDING_SEARCH = "Onboarding Search",
  ONBOARDING_STATUS = "Onboarding Status",
  CORPORATE_SEARCH = "Corporate Search",
  CORPORATE_STATUS = "Corporate Status",
  APPLICATION_DETAILS = "Application Details",
  CORPORATE_DETAILS = "Corporate Details",
  REMOVE_CORP_ADMIN = "Remove Corp Admin",
  ADD_CORP_ADMIN = "Add Corp Admin",
  CORPORATE_APPROVED = "Corporate Approved",
  CORPORATE_REJECTED = "Corporate Rejected",
  UPDATE_SERVICE_SELECTED = "Update Service Selected",
  UPDATE_PFM_CBO_CHO = "Update Pfm, cbo and cho",
  ADD_CATEGORY = "Add Category",
  REMOVE_CATEGORY = "Remove Category",
  CREATE_CUSTOMIZE_CATEGORY = "Create Customize category",
  CREATE_SET_MAX_LIMIT = "Create Set Max limit",
  DELETE_SET_MAX_LIMIT = "Delete Set Max limit",
  SWITCHING = "Switching",
  EMPLOYEES_SEARCH = "Employees Search",
  EMPLOYEES_STATUS = "Employees Status",
  RESET_USER_PASSWORD = "Reset User password",
  BATCHES = "Batches",
  BATCH_DETAILS = "Batch Details",
  BATCH_APPROVED = "Batch Approved",
  BATCH_REJECTED = "Batch Rejected",
  BATCH_EXECUTED = "Batch Executed",
  BATCH_CANCELLED = "Batch Cancelled",
  ADDING_BUSINESS_UNIT = "Adding Business Unit",
  NEW_CLAIM_FLOW = "New Claim Flow",
  ANNUAL_LIMIT = "Annual Limit",
  DELIVERY_UPLOAD = "Delivery Upload",
  UPDATE_CAMPAIGN = "Update Campaign",
  CREATE_CAMPAIGN = "Create Campaign",
  GET_CAMPAIGN_BY_ID = "Get Campaign By Id",
  SEND_NOTIFICATION = "Send Notifications",
}
