import classNames from "classnames";
import React from "react";
import { MTab, tabData } from "./onboard-service.utils";
import { CorporateServiceInfo } from "../../../api/admin/model/corporateServiceInfo";
import { SelectedServicesInfo } from "../../../api/admin/model/adminApplicationInfo";

type TabProps = {
  tabs?: MTab[];
  activeTab?: CorporateServiceInfo.TypeEnum;
  setActiveTab?: (tabType: CorporateServiceInfo.TypeEnum) => void;
  selectedServices?: Array<SelectedServicesInfo>;
};

const OnboardServiceTab: React.FC<TabProps> = ({
  tabs = tabData,
  activeTab,
  setActiveTab = () => {},
  selectedServices,
}) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div id="exTab2" className="p-0 border-bottom mb-3">
        <ul className="nav">
          {tabs
            .filter(
              (x) =>
                (!selectedServices?.find(
                  (x) => x.type === CorporateServiceInfo.TypeEnum.DETAX_CARD
                )?.selected
                  ? x.tabValue !== CorporateServiceInfo.TypeEnum.DETAX_CARD
                  : true) &&
                (!selectedServices?.find(
                  (x) => x.type === CorporateServiceInfo.TypeEnum.NPS
                )?.selected
                  ? x.tabValue !== CorporateServiceInfo.TypeEnum.NPS
                  : true) &&
                (!selectedServices?.find(
                  (x) => x.type === CorporateServiceInfo.TypeEnum.PROTECTION
                )?.selected
                  ? x.tabValue !== CorporateServiceInfo.TypeEnum.PROTECTION
                  : true)
            )
            .map((tab, index) => {
              return (
                <li className="nav-item cursor-pointer" key={index}>
                  <a
                    className={classNames(
                      "nav-link text-secondary fw-bold fs-6 ",
                      {
                        active: tab.tabValue === activeTab,
                      }
                    )}
                    data-toggle="tab"
                    onClick={() => {
                      setActiveTab(tab.tabValue);
                    }}
                  >
                    {tab.tabLabel}
                  </a>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default OnboardServiceTab;
