import { isSuccess } from "@devexperts/remote-data-ts";
import { option } from "fp-ts";
import { fold, isSome, none, Option, some } from "fp-ts/lib/Option";
import { useMutation, useQuery } from "react-query";
import { DetaxBatchCountInfo } from "../../../../../api/detax/model/detaxBatchCountInfo";
import {
  TDetaxAdminBatchInfo,
  TDetaxBatchCountInfo,
  TDetaxBatchInfo,
  TDetaxTransactionBatchMembers,
  TEligibleUserinfo,
} from "../../../../../models/batches.models";
import { DetaxTypeKind } from "../../../../../models/detax-type-kind";
import { BatchesService } from "../../../../../services/batches.service";
import { unWrapOption } from "../../../../../utils/option.utils";

export function useGetDetaxBatchesCount(type: DetaxTypeKind) {
  return useQuery<Option<TDetaxBatchCountInfo[]>, Error>(
    ["GetDetaxBatchesCount", type],
    async () => {
      const result = await BatchesService.getDetaxBatchCounts(type);
      if (isSuccess(result)) {
        if (result.value.successful) {
          const counts = option.isSome(result.value.data)
            ? option.some(addAllCount(result.value.data.value))
            : none;
          return counts;
        } else {
          return none;
        }
      } else {
        return none;
      }
    },
    { refetchOnWindowFocus: false }
  );
}

export function useNpsCount(type: DetaxTypeKind) {
  return useQuery<Option<TDetaxBatchCountInfo[]>, Error>(
    ["GetNpsCount", type],
    async () => {
      const result = await BatchesService.getNpsCount(type);
      if (isSuccess(result)) {
        if (result.value.successful) {
          // console.log("batches-status count :: ", result?.value?.data);
          const counts = option.isSome(result.value.data)
            ? option.some(addAllCount(result.value.data.value))
            : none;
          return counts;
        } else {
          return none;
        }
      } else {
        return none;
      }
    },
    { refetchOnWindowFocus: false }
  );
}

export function useGetBatchesByStatus(
  type: DetaxTypeKind,
  batchStatus: string,
  pn: number,
  rc: number
) {
  return useQuery<TDetaxAdminBatchInfo[], Error>(
    ["useGetBatchesByStatus", type, batchStatus],
    async () => {
      const result = await BatchesService.getCorporateBatchByStatus({
        type,
        batchStatus,
        pn,
        rc,
      });
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          return BatchesService.parseDetaxAdminBatchInfo(result.value.data);
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    { refetchOnWindowFocus: false }
  );
}

export function useGetBatchesByStatusPageCount(
  type: DetaxTypeKind,
  batchStatus: string,
  pn: number,
  rc: number
) {
  return useQuery<number, Error>(
    ["useGetBatchesByStatusPageCount", type, batchStatus],
    async () => {
      const result = await BatchesService.getCorporateBatchByStatus({
        type,
        batchStatus,
        pn,
        rc,
      });
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false) === true) {
          const countOption = fold(
            () => none,
            (num: any) => some(num)
          )(result.value.recordCount);

          let count = 0;
          if (countOption._tag === "Some") {
            count = countOption.value;
          }

          // DetaxDispatch.setBatches(batches);
          return count;
        } else {
          return 0; // Return a default value when not successful
        }
      } else {
        return 0; // Return a default value when not successful
      }
    },
    { refetchOnWindowFocus: false }
  );
}

export function useGetBatchesForNps(
  type: DetaxTypeKind,
  batchStatus: string,
  pn: number,
  rc: number
) {
  return useQuery<TDetaxAdminBatchInfo[], Error>(
    ["useGetBatchesForNps", type, batchStatus],
    async () => {
      const result = await BatchesService.getCorporateBatchForNps({
        type,
        batchStatus,
        pn,
        rc,
      });
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          return BatchesService.parseDetaxAdminBatchInfo(result.value.data);
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    { refetchOnWindowFocus: false }
  );
}

export function useGetEligibleUser(type: DetaxTypeKind) {
  return useQuery<TEligibleUserinfo[], Error>(
    ["useGetEligibleUser", type],
    async () => {
      const result = await BatchesService.getEligibleUsers(type);
      if (isSuccess(result)) {
        return result.value;
      } else {
        return [];
      }
    },
    { refetchOnWindowFocus: false }
  );
}

//get Corporate Batch Detail By BatchId
export function useGetBatchDetailByBatchId(batchId: string) {
  return useQuery<TDetaxBatchInfo, Error>(
    ["useGetBatchDetailByBatchId", batchId],
    async () => {
      const result = await BatchesService.getTransactionBatchDetailByBatchId(
        batchId
      );
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          return BatchesService.parseDetaxBatchDetailsInfo(result.value.data);
        } else {
          return {
            batchId: none,
            batchCode: none,
            type: none,
            txnCount: none,
            amount: none,
            status: none,
            createdAt: none,
            createdBy: none,
            paymentInfo: none,
          } as TDetaxBatchInfo;
        }
      } else {
        return {
          batchCode: none,
          type: none,
          txnCount: none,
          amount: none,
          status: none,
          createdAt: none,
          createdBy: none,
          paymentInfo: none,
        } as TDetaxBatchInfo;
      }
    },
    { refetchOnWindowFocus: false }
  );
}

//get Corporate Batch Detail By BatchId
export function useGetBatchEmployeesByBatchId(batchId: string) {
  return useQuery<TDetaxTransactionBatchMembers, Error>(
    ["useGetBatchEmployeesByBatchId", batchId],
    async () => {
      const result = await BatchesService.getCorporateBatchEmployeesByBatchId(
        batchId
      );
      if (isSuccess(result)) {
        const { successful, type, fbaTxns, taTxns, npsTxns, eaTxns, gcTxns } =
          result.value;
        if (unWrapOption(successful, () => false)) {
          //return BatchesService.parseDetaxBatchDetailsInfo(result.value.data);
          return {
            type: isSome(type) ? type.value.toString() : DetaxTypeKind.FBA,
            FBA: isSome(fbaTxns)
              ? fbaTxns.value.map(BatchesService.parseDetaxCardBatchMemberInfo)
              : [],
            TA: isSome(taTxns)
              ? taTxns.value.map(BatchesService.parseDetaxCardBatchMemberInfo)
              : [],
            EA: isSome(eaTxns)
              ? eaTxns.value.map(BatchesService.parseDetaxCardBatchMemberInfo)
              : [],
            NPS: isSome(npsTxns)
              ? npsTxns.value.map(BatchesService.parseDetaxCardBatchMemberInfo)
              : [],
            GC: isSome(gcTxns)
              ? gcTxns.value.map(BatchesService.parseDetaxCardBatchMemberInfo)
              : [],
          };
        } else {
          return {} as TDetaxTransactionBatchMembers;
        }
      } else {
        return {} as TDetaxTransactionBatchMembers;
      }
    },
    { refetchOnWindowFocus: false, refetchOnMount: "always" }
  );
}

export const useCreateTransactionBatchMutation = () => {
  return useMutation(BatchesService.createTransactionBatch);
};

export const useInitiateTransactionMutation = () => {
  return useMutation(BatchesService.executeBatch);
};

export const useInitiateTransactionMutationNps = () => {
  return useMutation(BatchesService.executeNpsBatch);
};
function addAllCount(data: DetaxBatchCountInfo[]) {
  const allCount: TDetaxBatchCountInfo = {
    status: "All",
    label: "All",
    count: data.reduce((a, c) => a + (c.count || 0), 0),
  };
  const counts: TDetaxBatchCountInfo[] = [
    allCount,
    ...data.map((d) => ({
      status: d.status || "",
      label: d.label || "",
      count: d.count || 0,
    })),
  ];
  return counts;
}
