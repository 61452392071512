/**
 * Ncaish Admin API
 * Apis to super admin operations
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AdminBrInfo } from './adminBrInfo';
import { AdminCorpInfo } from './adminCorpInfo';
import { ApplicantInfo } from './applicantInfo';
import { CorporateServiceInfo } from './corporateServiceInfo';


export interface AdminCorporateInfo { 
    corporateInfo?: AdminCorpInfo;
    applicantInfo?: ApplicantInfo;
    brInfo?: AdminBrInfo;
    services?: Array<CorporateServiceInfo>;
    applicationStatus?: AdminCorporateInfo.ApplicationStatusEnum;
    accountStatus?: AdminCorporateInfo.AccountStatusEnum;
    corpCode?: string;
}
export namespace AdminCorporateInfo {
    export type ApplicationStatusEnum = 'CIN_VERIFIED' | 'SIGNUP_COMPLETED' | 'SIGNATORY_UPDATED' | 'SERVICE_SELECTION_COMPLETED' | 'BR_SENT' | 'REVIEW_PENDING' | 'LIVE' | 'REJECTED' | 'EXPIRED';
    export const ApplicationStatusEnum = {
        CINVERIFIED: 'CIN_VERIFIED' as ApplicationStatusEnum,
        SIGNUPCOMPLETED: 'SIGNUP_COMPLETED' as ApplicationStatusEnum,
        SIGNATORYUPDATED: 'SIGNATORY_UPDATED' as ApplicationStatusEnum,
        SERVICESELECTIONCOMPLETED: 'SERVICE_SELECTION_COMPLETED' as ApplicationStatusEnum,
        BRSENT: 'BR_SENT' as ApplicationStatusEnum,
        REVIEWPENDING: 'REVIEW_PENDING' as ApplicationStatusEnum,
        LIVE: 'LIVE' as ApplicationStatusEnum,
        REJECTED: 'REJECTED' as ApplicationStatusEnum,
        EXPIRED: 'EXPIRED' as ApplicationStatusEnum
    };
    export type AccountStatusEnum = 'ACTIVE' | 'INACTIVE' | 'BLOCKED';
    export const AccountStatusEnum = {
        ACTIVE: 'ACTIVE' as AccountStatusEnum,
        INACTIVE: 'INACTIVE' as AccountStatusEnum,
        BLOCKED: 'BLOCKED' as AccountStatusEnum
    };
}

