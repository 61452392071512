/**
 * Ncaish Admin API
 * Apis to super admin operations
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import { RemoteData } from "@devexperts/remote-data-ts";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { ApiClient } from "../../api-client";
import { APIError } from "../../errors";

//@ts-ignore
//import * as models from '../model/models';
import { ActivateDetaxRequest } from "../model/activateDetaxReq";
import { AdminApplicationListResponse } from "../model/adminApplicationListResponse";
import { ApplicationApprovalRequest } from "../model/applicationApprovalRequest";
import { ApplicationCountApiResponse } from "../model/applicationCountApiResponse";
import {
  ApplicationDetailApiResponse,
  SelectedServicesApiResponse,
} from "../model/applicationDetailApiResponse";
import {
  CreateMaxLimitResponse,
  CreateReqBody,
  DeleteMaxLimitResponse,
  DeleteReqBody,
  GetEmployeesRes,
  GetMaxLimitResponse,
} from "../model/maxLimitResponse";
import { AdminListOfCategoriesApiResponse } from "../model/onboarding/adminListOfCategoriesApiResponse";
import { AdminListOfSchemesApiResponse } from "../model/onboarding/adminListOfSchemes";
import { CorpFundReq } from "../model/corpFundReq";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NcaishAdminApplicationApiService {
  /**
   * Api to fetch status wise applications count
   */
  export const applicationCounts1 = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ApplicationCountApiResponse>> => {
    const path = `/secure/corporates/applications/count`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to get application details
   * @param id -
   */
  export const detail2 = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ApplicationDetailApiResponse>> => {
    const path = `/secure/corporates/applications/${encodeURIComponent(
      String(id)
    )}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to fetch status wise applications list
   * @param state -
   */
  export const fetchByState2 = async (
    state: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminApplicationListResponse>> => {
    const path = `/secure/corporates/applications/list/${encodeURIComponent(
      String(state)
    )}`
      .replace(/^\//, "")
      .replace("{" + "state" + "}", String(state));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to get application details
   * @param body -
   * @param id -
   */
  export const handleApproval = async (
    body: ApplicationApprovalRequest,
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ApplicationDetailApiResponse>> => {
    const path = `/secure/corporates/applications/approval/${encodeURIComponent(
      String(id)
    )}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];

    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const updateService = async (
    body: string[],
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ApplicationDetailApiResponse>> => {
    const path = `/secure/corporates/${encodeURIComponent(
      String(id)
    )}/updateSelectedServices`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const updateCorpFund = async (
    data: CorpFundReq,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ApplicationDetailApiResponse>> => {
    const path = `/secure/nps/users/admin/corpFund`.replace(/^\//, "");
    // .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [
      { key: "corpFund", value: data.corpFund },
      { key: "cboCode", value: data.cbo },
      { key: "choCode", value: data.cho },
      { key: "cid", value: data.cid },
      { key: "services", value: data.services },
      { key: "retireAge", value: data.retireAge },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getAllSchemes = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminListOfSchemesApiResponse>> => {
    const path = `/secure/nps/users/admin/getAllSchemes`;
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getAllAvailableCategories = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminListOfCategoriesApiResponse>> => {
    const path = `/secure/detax/card/transactions/fbaCategories`;
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getAllCorporateCategories = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminListOfCategoriesApiResponse>> => {
    const path = `/secure/corporates/categories/${encodeURIComponent(
      String(id)
    )}/existing`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");
    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getSelectedServices = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, SelectedServicesApiResponse>> => {
    const path = `/secure/corporates/${encodeURIComponent(
      String(id)
    )}/notSelectedServices`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");
    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getMaxLimitData = async (
    cid: string,
    catid: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetMaxLimitResponse>> => {
    const path = `/secure/detax/cards/${encodeURIComponent(
      String(cid)
    )}/getMaxLimitData`.replace(/^\//, "");
    // .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [{ key: "categoryId", value: catid }];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const createMaxLimit = async (
    cid: string,
    type: string,
    body: CreateReqBody,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CreateMaxLimitResponse>> => {
    const path = `/secure/detax/cards/${encodeURIComponent(
      String(cid)
    )}/updateMaxLimitData`.replace(/^\//, "");
    // .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [{ key: "type", value: type }];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const activateOnlyDetax = async (
    cid: string,
    body: ActivateDetaxRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ApplicationDetailApiResponse>> => {
    const path =
      `/secure/corporates/applications/activate-detax/${cid}`.replace(
        /^\//,
        ""
      );

    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const deleteMaxLimit = async (
    cid: string,
    type: string,
    body: DeleteReqBody,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DeleteMaxLimitResponse>> => {
    const path = `/secure/detax/cards/${encodeURIComponent(
      String(cid)
    )}/deleteMaxLimitData`.replace(/^\//, "");
    // .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [{ key: "type", value: type }];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "delete",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const enableAnnualLimit = async (
    cid: string,

    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DeleteMaxLimitResponse>> => {
    const path = `/secure/detax/${cid}/convertCorpToAnnaulLimit`.replace(
      /^\//,
      ""
    );
    // .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getEmployeesWithCid = async (
    cid: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetEmployeesRes>> => {
    const path = `/secure/detax/cards/${encodeURIComponent(
      String(cid)
    )}/getAllEmployeesData`.replace(/^\//, "");
    // .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}

//export default new NcaishAdminApplicationApiService()
