import { remoteData } from "@devexperts/remote-data-ts";
import { fromNullable, isSome, none, Option } from "fp-ts/lib/Option";
import { NcaishTransactionBatchApiService } from "../api/detax/api/ncaishTransactionBatchApi.service";
import { CreateTransactionBatchRequest } from "../api/detax/model/createTransactionBatchRequest";
import {
  DetaxAdminBatchDetailResponse,
  NpsBatchListResponse,
} from "../api/detax/model/detaxAdminBatchDetailResponse";
import { DetaxAdminBatchInfo } from "../api/detax/model/detaxAdminBatchInfo";
import { DetaxAdminBatchListResponse } from "../api/detax/model/detaxAdminBatchListResponse";
import { DetaxBatchCountApiResponse } from "../api/detax/model/detaxBatchCountApiResponse";
import { DetaxBatchCountInfo } from "../api/detax/model/detaxBatchCountInfo";
import { DetaxBatchInfo } from "../api/detax/model/detaxBatchInfo";
import { DetaxBatchMember } from "../api/detax/model/detaxBatchMember";
import { DetaxCardBatchMemberInfo } from "../api/detax/model/detaxCardBatchMemberInfo";
import { DetaxCardRechargeEligibleUsersResponse } from "../api/detax/model/detaxCardRechargeEligibleUsersResponse";
import { DetaxTransactionBatchDetailResponse } from "../api/detax/model/detaxTransactionBatchDetailResponse";
import { GetDetaxTransactionBatchMembersResponse } from "../api/detax/model/getDetaxTransactionBatchMembersResponse";
import { NpsRechargeEligibleUsersResponse } from "../api/detax/model/npsRechargeEligibleUsersResponse";
import {
  TDetaxAdminBatchDetailResponse,
  TDetaxAdminBatchInfo,
  TDetaxAdminBatchListResponse,
  TDetaxBatchCountApiResponse,
  TDetaxBatchInfo,
  TDetaxCardBatchMemberInfo,
  TDetaxTransactionBatchDetailResponse,
  TEligibleUserinfo,
  TGetDetaxTransactionBatchMembersResponse,
} from "../models/batches.models";
import { DetaxTypeKind } from "../models/detax-type-kind";

export namespace BatchesService {
  // this will call on load of the Batch page
  export const getDetaxBatchCounts = async (type: DetaxTypeKind) => {
    const result = await NcaishTransactionBatchApiService.getDetaxBatchCounts(
      type.toString()
    );
    return remoteData.map(
      result,
      (response: DetaxBatchCountApiResponse): TDetaxBatchCountApiResponse => {
        return {
          successful: fromNullable(response.successful),
          message: fromNullable(response.message),
          data: fromNullable(response.data),
        };
      }
    );
  };

  export const getNpsCount = async (type: DetaxTypeKind) => {
    const result = await NcaishTransactionBatchApiService.getNpsCount(
      type.toString()
    );
    return remoteData.map(
      result,
      (response: DetaxBatchCountApiResponse): TDetaxBatchCountApiResponse => {
        return {
          successful: fromNullable(response.successful),
          message: fromNullable(response.message),
          data: fromNullable(response.data),
        };
      }
    );
  };
  // get upload txn table data by type and status
  export const getCorporateBatchByStatus = async ({
    type,
    batchStatus = "ALL",
    pn,
    rc,
  }: {
    type: DetaxTypeKind;
    batchStatus?: string;
    pn?: number;
    rc?: number;
  }) => {
    const status = batchStatus.toLowerCase() === "all" ? "" : batchStatus;
    const result = await NcaishTransactionBatchApiService.getBatchesByStatus(
      type.toString(),
      status,
      "",
      "",
      "",
      pn,
      rc
    );
    return remoteData.map(
      result,
      (response: DetaxAdminBatchListResponse): TDetaxAdminBatchListResponse => {
        return {
          successful: fromNullable(response.successful),
          message: fromNullable(response.message),
          data: fromNullable(response.data),
          pageCount: fromNullable(response.pageCount),
          recordCount: fromNullable(response.recordCount),
        };
      }
    );
  };

  export const getCorporateBatchForNps = async ({
    type,
    batchStatus = "ALL",
    pn,
    rc,
  }: {
    type: DetaxTypeKind;
    batchStatus?: string;
    pn?: number;
    rc?: number;
  }) => {
    const status = batchStatus.toLowerCase() === "all" ? "" : batchStatus;
    const result = await NcaishTransactionBatchApiService.getBatchesForNps(
      type.toString(),
      status,
      "",
      "",
      "",
      pn,
      rc
    );
    return remoteData.map(
      result,
      (response: DetaxAdminBatchListResponse): TDetaxAdminBatchListResponse => {
        return {
          successful: fromNullable(response.successful),
          message: fromNullable(response.message),
          data: fromNullable(response.data),
          pageCount: fromNullable(response.pageCount),
          recordCount: fromNullable(response.recordCount),
        };
      }
    );
  };
  //TEligibleUserinfo
  export const getFBAEligibleUsers = async () => {
    const result = await NcaishTransactionBatchApiService.getFbaEligibleUsers();

    return remoteData.map(
      result,
      (
        response: DetaxCardRechargeEligibleUsersResponse
      ): TEligibleUserinfo[] => {
        const users: TEligibleUserinfo[] = response.successful
          ? response.data
            ? response.data.map((d) => ({
                uid: fromNullable(d.uid),
                name: fromNullable(d.name),
                email: fromNullable(d.email),
                mobile: fromNullable(d.mobile),
                pranNo: none,
                cboCode: none,
                lastTransaction: fromNullable(d.lastTransaction),
                limit: fromNullable(d.limit),
                amountToAdd: fromNullable(d.amountToAdd),
                isChecked: false,
              }))
            : []
          : [];

        return users;
      }
    );
  };

  export const getTAEligibleUsers = async () => {
    const result =
      await NcaishTransactionBatchApiService.getTaReimbursementEligibleUsers();

    return remoteData.map(
      result,
      (
        response: DetaxCardRechargeEligibleUsersResponse
      ): TEligibleUserinfo[] => {
        const users: TEligibleUserinfo[] = response.successful
          ? response.data
            ? response.data.map((d) => ({
                uid: fromNullable(d.uid),
                name: fromNullable(d.name),
                email: fromNullable(d.email),
                mobile: fromNullable(d.mobile),
                pranNo: none,
                cboCode: none,
                lastTransaction: fromNullable(d.lastTransaction),
                limit: fromNullable(d.limit),
                amountToAdd: fromNullable(d.amountToAdd),
                isChecked: false,
              }))
            : []
          : [];

        return users;
      }
    );
  };

  export const getNPSEligibleUsers = async () => {
    const result =
      await NcaishTransactionBatchApiService.npsRechargeEligibleUsers();

    return remoteData.map(
      result,
      (response: NpsRechargeEligibleUsersResponse): TEligibleUserinfo[] => {
        const users: TEligibleUserinfo[] = response.successful
          ? response.data
            ? response.data.map((d) => ({
                uid: fromNullable(d.uid),
                name: fromNullable(d.name),
                email: fromNullable(d.email),
                mobile: fromNullable(d.mobile),
                pranNo: none,
                limit: none,
                cboCode: fromNullable(d.cboCode),
                lastTransaction: fromNullable(d.lastTransaction),
                amountToAdd: fromNullable(d.amountToAdd),
                isChecked: false,
              }))
            : []
          : [];

        return users;
      }
    );
  };

  export const getEligibleUsers = async (type: DetaxTypeKind) => {
    if (type === DetaxTypeKind.NPS) {
      return getNPSEligibleUsers();
    }

    if (type === DetaxTypeKind.TA) {
      return getTAEligibleUsers();
    }

    return getFBAEligibleUsers();
  };

  export const createTransactionBatch = async (
    req: Required<CreateTransactionBatchRequest>
  ) => {
    const result =
      await NcaishTransactionBatchApiService.createTransactionBatch({
        type: req.type,
        data: req.data,
      });
    return remoteData.map(result, parseDetaxTransactionBatchDetailResponse);
  };

  export const getTransactionBatchDetailByBatchId = async (batchId: string) => {
    const result =
      await NcaishTransactionBatchApiService.getTransactionBatchById(batchId);
    return remoteData.map(
      result,
      (
        response: DetaxAdminBatchDetailResponse
      ): TDetaxAdminBatchDetailResponse => {
        return {
          successful: fromNullable(response.successful),
          message: fromNullable(response.message),
          data: fromNullable(response.data),
        };
      }
    );
  };

  export const getNpsBatchDetailByBatchId = async (batchId: string) => {
    const result = await NcaishTransactionBatchApiService.getNpsBatchById(
      batchId
    );
    return remoteData.map(
      result,
      (
        response: DetaxAdminBatchDetailResponse
      ): TDetaxAdminBatchDetailResponse => {
        return {
          successful: fromNullable(response.successful),
          message: fromNullable(response.message),
          data: fromNullable(response.data),
        };
      }
    );
  };

  export const getNpsBatchList = async (
    batchId: string,
    pn: number,
    rc: number
  ) => {
    const result = await NcaishTransactionBatchApiService.getNpsBatchList(
      batchId,
      pn,
      rc
    );
    return remoteData.map(result, (response: NpsBatchListResponse) => response);
  };

  export const getNpsContributionReport = async (
    batchId: string,
    sheet: number
  ) => {
    const result =
      await NcaishTransactionBatchApiService.getNpsContributionReport(
        batchId,
        sheet
      );
    return remoteData.map(result, (response: Blob) => response);
  };

  //getCorporateTransactionBatchMembers  Show Transaction details employees
  export const getCorporateBatchEmployeesByBatchId = async (
    batchId: string
  ) => {
    const result =
      await NcaishTransactionBatchApiService.getTransactionBatchMembers(
        batchId
      );
    return remoteData.map(result, parseDetaxBatchMemberResponse);
  };

  // Initiate Transaction
  export const executeBatch = async (req: {
    // amount: number;
    // mode: InitiateDetaxBatchApiRequest.ModeEnum;
    // time: number;
    // refId: string;
    batchId: string;
  }) =>
    //req: Required<InitiateDetaxBatchApiRequest>,
    // batchId: string
    {
      const result = await NcaishTransactionBatchApiService.executeBatch(
        // {
        //   amount: req.amount,
        //   time: req.time,
        //   refId: req.refId,
        //   mode: req.mode,
        // },
        { remarks: "Executed" },
        req.batchId
      );
      return remoteData.map(result, parseDetaxTransactionBatchDetailResponse);
    };

  export const executeNpsBatch = async (req: {
    // amount: number;
    // mode: InitiateDetaxBatchApiRequest.ModeEnum;
    // time: number;
    // refId: string;
    batchId: string;
  }) =>
    //req: Required<InitiateDetaxBatchApiRequest>,
    // batchId: string
    {
      const result = await NcaishTransactionBatchApiService.executeNpsBatch(
        // {
        //   amount: req.amount,
        //   time: req.time,
        //   refId: req.refId,
        //   mode: req.mode,
        // },
        { remarks: "Executed" },
        req.batchId
      );
      return remoteData.map(result, parseDetaxTransactionBatchDetailResponse);
    };

  export const rejectBatch = async (batchId: string, remarks: string = "") => {
    const result = await NcaishTransactionBatchApiService.rejectbatch(
      { remarks },
      batchId
    );
    return remoteData.map(
      result,
      (response: DetaxAdminBatchDetailResponse) => ({
        successful: fromNullable(response.successful),
        message: fromNullable(response.message),
        data: fromNullable(response.data),
      })
    );
  };

  export const rejectNpsBatch = async (
    batchId: string,
    remarks: string = ""
  ) => {
    const result = await NcaishTransactionBatchApiService.rejectNpsbatch(
      { remarks },
      batchId
    );
    return remoteData.map(
      result,
      (response: DetaxAdminBatchDetailResponse) => ({
        successful: fromNullable(response.successful),
        message: fromNullable(response.message),
        data: fromNullable(response.data),
      })
    );
  };

  export const approveBatch = async (batchId: string) => {
    const result = await NcaishTransactionBatchApiService.approveBatch(
      { remarks: "Approved" },
      batchId
    );
    return remoteData.map(
      result,
      (response: DetaxAdminBatchDetailResponse) => ({
        successful: fromNullable(response.successful),
        message: fromNullable(response.message),
        data: fromNullable(response.data),
      })
    );
  };

  export const approveNpsBatch = async (batchId: string) => {
    const result = await NcaishTransactionBatchApiService.approveNpsBatch(
      { remarks: "Approved" },
      batchId
    );
    return remoteData.map(
      result,
      (response: DetaxAdminBatchDetailResponse) => ({
        successful: fromNullable(response.successful),
        message: fromNullable(response.message),
        data: fromNullable(response.data),
      })
    );
  };

  export const uploadTransaction = async (req: {
    data: Array<DetaxBatchMember>;
    batchId: string;
  }) => {
    const result = await NcaishTransactionBatchApiService.updateBatchMember(
      { data: req.data },
      req.batchId
    );
    return remoteData.map(result, parseDetaxBatchMemberResponse);
  };

  export const parseDetaxBatchCountInfo = (
    data: Option<DetaxBatchCountInfo[]>
  ) => {};

  export const parseDetaxTransactionBatchDetailResponse = (
    response: DetaxTransactionBatchDetailResponse
  ): TDetaxTransactionBatchDetailResponse => {
    return {
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      data: fromNullable(response.data),
    };
  };

  export const parseDetaxBatchInfo = (
    data: Option<DetaxBatchInfo[]>
  ): TDetaxBatchInfo[] => {
    const parseData: TDetaxBatchInfo[] = isSome(data)
      ? data.value.map((d) => ({
          batchId: fromNullable(d.batchId),
          batchCode: fromNullable(d.batchCode),
          type: fromNullable(d.type),
          txnCount: fromNullable(d.txnCount),
          amount: fromNullable(d.amount),
          status: fromNullable(d.status),
          paymentInfo: fromNullable(d.paymentInfo),

          createdAt: fromNullable(d.createdAt),
          updatedAt: fromNullable(d.updatedAt),
          initiatedAt: fromNullable(d.initiatedAt),
          cancelledAt: fromNullable(d.cancelledAt),
          approvedAt: fromNullable(d.approvedAt),
          executedAt: fromNullable(d.executedAt),
          createdBy: fromNullable(d.createdBy),
          initiatedBy: fromNullable(d.initiatedBy),
          cancelledBy: fromNullable(d.cancelledBy),
          approvedBy: fromNullable(d.approvedBy),
          executedBy: fromNullable(d.executedBy),
          timeline: fromNullable(d.timeline),
          corpInfo: fromNullable(d.corpInfo),
        }))
      : [];
    return parseData;
  };

  export const parseDetaxAdminBatchInfo = (
    data: Option<DetaxAdminBatchInfo[]>
  ): TDetaxAdminBatchInfo[] => {
    const parseData: TDetaxAdminBatchInfo[] = isSome(data)
      ? data.value.map((d) => ({
          batchId: fromNullable(d.batchId),
          batchCode: fromNullable(d.batchCode),
          type: fromNullable(d.type),
          txnCount: fromNullable(d.txnCount),
          amount: fromNullable(d.amount),
          status: fromNullable(d.status),
          paymentInfo: fromNullable(d.paymentInfo),
          corpInfo: fromNullable(d.corpInfo),

          createdAt: fromNullable(d.createdAt),
          updatedAt: fromNullable(d.updatedAt),
          createdBy: fromNullable(d.createdBy),

          initiatedBy: fromNullable(d.initiatedBy),
          cancelledBy: fromNullable(d.cancelledBy),
          approvedBy: fromNullable(d.approvedBy),
          executedBy: fromNullable(d.executedBy),
        }))
      : [];
    return parseData;
  };

  export const parseDetaxBatchDetailsInfo = (
    data: Option<DetaxBatchInfo>
  ): TDetaxBatchInfo => {
    const parseData: TDetaxBatchInfo = isSome(data)
      ? {
          batchId: fromNullable(data.value.batchId),
          batchCode: fromNullable(data.value.batchCode),
          type: fromNullable(data.value.type),
          txnCount: fromNullable(data.value.txnCount),
          amount: fromNullable(data.value.amount),
          status: fromNullable(data.value.status),
          paymentInfo: fromNullable(data.value.paymentInfo),
          createdAt: fromNullable(data.value.createdAt),
          updatedAt: fromNullable(data.value.updatedAt),
          initiatedAt: fromNullable(data.value.initiatedAt),
          cancelledAt: fromNullable(data.value.cancelledAt),
          approvedAt: fromNullable(data.value.approvedAt),
          executedAt: fromNullable(data.value.executedAt),
          createdBy: fromNullable(data.value.createdBy),
          initiatedBy: fromNullable(data.value.initiatedBy),
          cancelledBy: fromNullable(data.value.cancelledBy),
          approvedBy: fromNullable(data.value.approvedBy),
          executedBy: fromNullable(data.value.executedBy),
          timeline: fromNullable(data.value.timeline),
          corpInfo: fromNullable(data.value.corpInfo),
        }
      : {
          batchId: none,
          batchCode: none,
          type: none,
          txnCount: none,
          amount: none,
          status: none,
          paymentInfo: none,
          createdAt: none,
          updatedAt: none,
          initiatedAt: none,
          cancelledAt: none,
          approvedAt: none,
          executedAt: none,
          createdBy: none,
          initiatedBy: none,
          cancelledBy: none,
          approvedBy: none,
          executedBy: none,
          timeline: none,
          corpInfo: none,
        };
    return parseData;
  };

  export const parseDetaxCardBatchMemberInfo = (
    data: DetaxCardBatchMemberInfo
  ): TDetaxCardBatchMemberInfo => {
    return {
      uid: fromNullable(data.uid),
      name: fromNullable(data.name),
      email: fromNullable(data.email),
      mobile: fromNullable(data.mobile),
      cardNo: fromNullable(data.cardNo),
      limit: fromNullable(data.limit),
      amountToAdd: fromNullable(data.amountToAdd),
      status: fromNullable(data.status),
      isChecked: false,
    };
  };
}
export const parseDetaxBatchMemberResponse = (
  response: GetDetaxTransactionBatchMembersResponse
): TGetDetaxTransactionBatchMembersResponse => {
  return {
    successful: fromNullable(response.successful),
    message: fromNullable(response.message),
    type: fromNullable(response.type),
    fbaTxns: fromNullable(response.fbaTxns),
    taTxns: fromNullable(response.taTxns),
    eaTxns: fromNullable(response.eaTxns),
    npsTxns: fromNullable(response.npsTxns),
    gcTxns: fromNullable(response.gcTxns),
  };
};
