import { CorporateServiceInfo } from "../../../api/admin/model/corporateServiceInfo";

export type MTab = {
  tabLabel: string;
  tabValue: CorporateServiceInfo.TypeEnum;
};

export const tabData: MTab[] = [
  {
    tabLabel: "Detax Card",
    tabValue: CorporateServiceInfo.TypeEnum.DETAX_CARD,
  },
  {
    tabLabel: "NPS",
    tabValue: CorporateServiceInfo.TypeEnum.NPS,
  },
  {
    tabLabel: "Protection",
    tabValue: CorporateServiceInfo.TypeEnum.PROTECTION,
  },
];
