import { isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import { NpsBatchListInfo } from "../../../../../api/detax/model/detaxAdminBatchInfo";
import { BatchesService } from "../../../../../services/batches.service";
import { currencyFormat } from "../../../../../utils/format.utils";
import NoTableData from "../../common/no-table-data";
import {
  ITableData,
  TablePagination,
} from "../../common/table/table-pagination";

const ContributionTable: React.FC<{
  listData: NpsBatchListInfo[];
  batchId: string;
}> = ({ batchId }) => {
  const [listData, setListData] = useState<NpsBatchListInfo[]>([]);
  const [paging, setPaging] = React.useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });
  useEffect(() => {
    setPaging({ ...paging, page: 1, limit: 10, total: 100000 });
  }, [batchId]);
  useEffect(() => {
    const run = async () => {
      const result = await BatchesService.getNpsBatchList(
        batchId,
        paging.page,
        paging.limit
      );

      if (isSuccess(result)) {
        setListData(result.value?.data || []);
        setPaging({ ...paging, total: result.value.recordCount ?? 10 });
      } else {
        console.log("Error BatchDetailsByBatchId: ", result);
        // Dispatch.App.setCommonError({
        //   showError: true,
        //   errorText: "Something went wrong!",
        // });
      }
    };
    // if (activeTab === "NPS") {
    run();
    // }
  }, [paging.page, paging.limit, paging.total]);

  const rows = (data: NpsBatchListInfo[]) => {
    return data.length > 0
      ? data.map((item, index) => (
          <tr key={index}>
            <td>
              {/* <Link
                className="text-secondary ncash_nav-link"
                to={
                  RoutePaths.DashboardTransaction +
                  "/" +
                  unWrapOption(item.batchId, () => "")
                }
              > */}
              {item.pranNumber}
              {/* </Link> */}
            </td>
            <td>{item.subName}</td>
            <td>{currencyFormat(item.empContribution)}</td>
            <td className="text-center text-nowrap">
              {currencyFormat(item.subContribution)}
            </td>
            <td className="text-center text-nowrap">
              {item.payMonth} {item.payYear}
            </td>
            <td>{item.contributionType}</td>
            <td>{item.arrearRemarks ?? "-"}</td>
            <td>{currencyFormat(item.totalCharges)}</td>
            <td>
              {currencyFormat(
                item.totalCharges + item.empContribution + item.subContribution
              )}
            </td>
          </tr>
        ))
      : [
          <tr>
            <td className="text-secondary" colSpan={7}>
              <img
                src={process.env.PUBLIC_URL + "/No-data-found.png"}
                alt="no data found"
                className="img-fluid"
              />
            </td>
          </tr>,
        ];
  };

  const tblData = (tblData: NpsBatchListInfo[]) => {
    // let result = [];
    // let startFrom = (paging.page - 1) * paging.limit;
    // let endAt = paging.page * paging.limit;
    // result = tblData.slice(startFrom, endAt);
    return tblData;
  };

  //   if (isLoadingStatus || isLoadingNps || isFetchingStatus || isFetchingNps) {
  //     return <TableLoader />;
  //   }

  //   if (isErrorStatus || isErrorNps) {
  //     return <div>Error</div>;
  //   }

  //console.log("detaxBasicInfos: ", detaxBasicInfos);

  return (
    // <div className="custom-table" style={{ maxHeight: adminTableHeight() }}>
    <div>
      {listData && listData.length > 0 ? (
        <TablePagination
          data={paging}
          changePage={(data) => setPaging((p) => ({ ...p, page: data }))}
          changeLimit={(data) =>
            setPaging((p) => ({ ...p, limit: data, page: 1 }))
          }
        >
          <table>
            <thead>
              <tr>
                <th scope="col">PRAN Number</th>
                <th scope="col">Subscriber Name</th>
                <th scope="col">Employer Contribution</th>
                <th scope="col">Subscriber's Contribution</th>
                <th scope="col"> Month and Year</th>
                <th scope="col">Contribution Type</th>
                <th scope="col">Arrear Remarks</th>
                <th scope="col">Total Charges</th>
                <th scope="col">Total Amount</th>
              </tr>
            </thead>

            <tbody>{listData && rows(tblData(listData))}</tbody>
          </table>
        </TablePagination>
      ) : (
        <NoTableData />
      )}
    </div>
  );
};

export default ContributionTable;
