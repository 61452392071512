import { isSuccess } from "@devexperts/remote-data-ts";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  CorporateServiceInfo,
  SelectedServicesInfo,
} from "../../../api/admin/model/models";
import { AdminCorporateInfo } from "../../../api/corporate/model/adminCorporateInfo";
import PageLoader from "../../../page-loader";
import { CorporateService } from "../../../services/corporate.service";
import { OnboardingService } from "../../../services/onboarding.service";
import { RoutePaths } from "../../../utils/route.utils";
import PageTitle from "../../common/page-title";
import FailurePopup from "../../pages/dashboard/common/failure-popup";
import SuccessPopup from "../../pages/dashboard/common/succes-popup";
import AddRemoveUser from "../common/add-remove-user";
import BrInfo from "../common/br-info";
import CompanyDetails from "../common/company-details";
import DetaxCardServiceDetails from "../common/detax-card-service-details";
import ServiceSelected from "../common/service-selected";
import UserDetailsInfo from "../common/user-details-info";
import Heading from "../heading";
import CorporateEmployeeSubscription from "./corporate-employee-subscription";
import AddNewSubscriptionPopUp from "./utils/add-new-subscription-popup";
import DetaxPlusPopup from "./utils/detax-plus-popup";
import ShowExistingUserPopup from "./utils/show-existing-user-popup";

const CorporateDetails: React.FC = () => {
  const params = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [detaxPlusPopUp, setDetaxPlusPopUp] = useState(false);
  const [addUserPopUp, setAddUserPopUp] = useState(false);
  const [showUserPopUp, setShowUserPopUp] = useState(false);
  const [successPopUp, setShowSuccessPopUp] = useState(false);
  const [failurePopUp, setShowFailurePopUp] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [selectedServices, setSelectedServices] = useState<
    SelectedServicesInfo[]
  >([]);
  const [onlyProtection, setOnlyProtection] = useState<boolean>(false);

  const [details, setDetails] = useState<AdminCorporateInfo>({
    corporateInfo: {
      cin: "",
      name: "",
      incDate: "",
      regAdd: "",
      rocCode: "",
      regNum: "",
      directors: [],
      cboCode: "",
      choCode: "",
      retireAge: "",
    },
    applicantInfo: {
      name: "",
      mobile: "",
      email: "",
      emailVerified: true,
      mobileVerified: true,
    },
    brInfo: {
      status: "PENDING",
      document: "",
      sentOn: "",
      signedOn: "",
      receipients: [],
    },
    services: [],
    applicationStatus: "CIN_VERIFIED",
    accountStatus: "ACTIVE",
    corpCode: "",
  });

  useEffect(() => {
    const run = async () => {
      if (params?.id) {
        setIsLoading(true);
        const result = await CorporateService.detail(params?.id);
        if (isSuccess(result)) {
          if (result.value.successful) {
            setDetails((prev) => (prev = result.value.data || prev));
          }
        }
        const resultselectservices =
          await OnboardingService.getSelectedServices(params.id);
        if (
          isSuccess(resultselectservices) &&
          resultselectservices.value.data
        ) {
          setSelectedServices(resultselectservices.value.data);
          if (
            resultselectservices.value.data.filter(
              (service) =>
                service.type === CorporateServiceInfo.TypeEnum.PROTECTION &&
                service.selected === true
            ).length === 1 &&
            resultselectservices.value.data.filter(
              (service) => service.selected === true
            ).length === 1
          ) {
            setOnlyProtection(true);
          }
        } else {
          // setAppState(initialState);
        }

        setIsLoading(false);
      }
    };
    run();
  }, [params.id]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <PageTitle title={`Corporate details | ${details.corpCode}`} />
      <Heading
        title="Corporate details"
        detail={details.corpCode}
        status={details.accountStatus}
        links={[RoutePaths.NavigateToCorporate]}
      />
      {details.corporateInfo && (
        <CompanyDetails
          agreement={details.brInfo?.document}
          corporateInfo={details.corporateInfo}
        />
      )}
      {details.applicantInfo && (
        <UserDetailsInfo applicantInfo={details.applicantInfo} />
      )}
      {details.brInfo && details.brInfo.receipients && (
        <BrInfo brInfo={details.brInfo} />
      )}
      <AddRemoveUser />
      {details.services && (
        <ServiceSelected
          id=""
          selectedServices={selectedServices}
          setSelectedServices={(data: SelectedServicesInfo[]) =>
            setSelectedServices(data)
          }
          applicationStatus={""}
          editService={false}
          setEditService={() => {}}
          details={details}
        />
      )}
      {!onlyProtection && (
        <DetaxCardServiceDetails
          walletInfo={details.walletInfo}
          cid={details.corporateInfo?.cid || ""}
        />
      )}
      {!onlyProtection && (
        <CorporateEmployeeSubscription
          cId={details.corporateInfo?.cid || ""}
          show={(val: boolean) => setDetaxPlusPopUp(val)}
        />
      )}
      <DetaxPlusPopup
        show={detaxPlusPopUp}
        onHide={(val: boolean) => setDetaxPlusPopUp(val)}
        showUser={() => {
          setDetaxPlusPopUp(false);
          setShowUserPopUp(true);
        }}
        addUser={() => {
          setDetaxPlusPopUp(false);
          setAddUserPopUp(true);
        }}
      />
      <ShowExistingUserPopup
        cId={details.corporateInfo?.cid || ""}
        show={showUserPopUp}
        onHide={() => {
          setDetaxPlusPopUp(true);
          setShowUserPopUp(false);
        }}
      />
      <AddNewSubscriptionPopUp
        cId={details.corporateInfo?.cid || ""}
        show={addUserPopUp}
        onHide={() => {
          setDetaxPlusPopUp(true);
          setAddUserPopUp(false);
        }}
        setSuccess={(message: string) => {
          setAddUserPopUp(false);
          setMessage(message);
          setShowSuccessPopUp(true);
        }}
        setFailure={(message: string) => {
          setAddUserPopUp(false);
          setMessage(message);
          setShowFailurePopUp(true);
        }}
      />
      <SuccessPopup
        message={message}
        show={successPopUp}
        onClose={(show: boolean) => {
          setAddUserPopUp(false);
          setShowSuccessPopUp(show);
        }}
      />
      <FailurePopup
        message={message}
        show={failurePopUp}
        onClose={(show: boolean) => {
          setShowFailurePopUp(show);
        }}
      />
    </>
  );
};

export default CorporateDetails;
