import { remoteData } from "@devexperts/remote-data-ts";
import {
  GetInsuranceDetailsResponse,
  InclusionExclusionResponse,
} from "../api/admin/model/adminInsuranceInfo";
import { InsuranceProvidersRes } from "../api/admin/model/insuranceProvidersRes";
import { NcaishProtectionService } from "../api/insurance/api/ncaishProtectionApi.service";
import {
  GetProtectionTxnData,
  GetProtectionTxnsResponse,
} from "../api/insurance/model/getCorpAdminProtectionTxnResponse";
import { ProtectionDashboardResponse } from "../api/insurance/model/protectionDashboardResponse";
import { BaseResponse } from "../model/baseResponse";
import { ProtectionType } from "../enum/protection-type";

export namespace ProtectionService {
  export const getProtectionDashboard = async (pn: number, rc: number) => {
    const result = await NcaishProtectionService.getProtectionDashboard(pn, rc);

    return remoteData.map(
      result,
      (response: ProtectionDashboardResponse) => response
    );
  };

  export const getCorpAdminTxnRequest = async (cid?: string) => {
    const result = await NcaishProtectionService.getCorpAdminTxnRequest(cid);

    return remoteData.map(
      result,
      (response: GetProtectionTxnsResponse) => response
    );
  };

  export const updateCorpAdminTxnRequest = async (
    cid?: string,
    body?: GetProtectionTxnData
  ) => {
    const result = await NcaishProtectionService.updateCorpAdminTxnRequest(
      cid,
      body
    );

    return remoteData.map(result, (response: BaseResponse) => response);
  };

  export const getAllPredefinedCoverages = async () => {
    const result = await NcaishProtectionService.getAllPredefinedCoverages();
    return remoteData.map(
      result,
      (response: InclusionExclusionResponse) => response
    );
  };

  export const updateInsuranceData = async (cid: string, body: FormData) => {
    const result = await NcaishProtectionService.updateInsuranceData(cid, body);
    return remoteData.map(result, (response: BaseResponse) => response);
  };

  export const getInsuranceData = async (cid: string, type: ProtectionType) => {
    const result = await NcaishProtectionService.getInsuranceData(cid, type);
    return remoteData.map(
      result,
      (response: GetInsuranceDetailsResponse) => response
    );
  };

  export const getInsuranceProviders = async () => {
    const result = await NcaishProtectionService.getInsuranceProviders();
    return remoteData.map(
      result,
      (response: InsuranceProvidersRes) => response
    );
  };
}
