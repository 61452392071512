import { isSuccess } from "@devexperts/remote-data-ts";
import ExcelJS from "exceljs";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import { GetEmployeesDataEmployeesRes } from "../../../../api/admin/model/maxLimitResponse";
import { GetUserCategoryDataData } from "../../../../model/getUserCategoryResponse";
import { TAllAvailableCategories } from "../../../../models/onboarding.models";
import { OnboardingService } from "../../../../services/onboarding.service";
import { unWrapOption } from "../../../../utils/option.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import ReuseIcon from "../../../common/reuse-icons/reuse-icons";
import NoTableData from "../../../pages/dashboard/common/no-table-data";
import {
  ITableData,
  TablePagination,
} from "../../../pages/dashboard/common/table/table-pagination";
import WhiteCard from "../../../white-card/white-card";
import DashboardPageLoader from "../../common/dashboard-page-loader";
import ErrorPopup from "../../common/ErrorPopup";
import TableLoader from "../../common/table-loader";
import EmployeeSearch from "../../employees/employee-search";

// same as import { EmployeePayload } from './employeePayload';
interface IPayload extends Record<string, string | string[] | undefined> {
  error?: string[];
}

interface IData extends Record<string, string | string[] | undefined> {
  error: string[];
}
const UserCatUpload: React.FC = () => {
  const params = useParams<{ id: string }>();
  const [importData, setImportData] = useState<IPayload[] | []>([]);
  const [excelData, setExcelData] = useState<File[]>([]);
  const [showSuccess, setShowSuccess] = useState({
    show: false,
    message: "",
  });
  const [searchVal, setSearchVal] = useState("");
  const navigate = useNavigate();
  const [existCat, setExistCat] = useState<Array<TAllAvailableCategories>>();
  const [employeeList, setEmployeeList] = useState<
    GetEmployeesDataEmployeesRes[]
  >([]);
  const [catData, setCatData] = useState<
    Array<{ [key: string]: Array<GetUserCategoryDataData> }>
  >([]);
  const [actionState, setActionState] = useState(false);
  const [showError, setShowError] = useState(false);
  const [responseError, setResponseError] = useState({
    show: false,
    message: "",
  });
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });
  const [load, setLoad] = useState(false);

  useEffect(() => {
    fetchCat();
    fetchEmp();
  }, []);

  useEffect(() => {
    if ((existCat?.length ?? 0) > 0) {
      singleCatFetch();
    }
  }, [existCat]);

  const fetchEmp = async () => {
    const result = await OnboardingService.getEmployeesWithCid(params.id ?? "");
    if (isSuccess(result) && result.value.data.length > 0) {
      setEmployeeList(result.value?.data[0].employees);
    } else {
      // setAppState(initialState);
    }
  };

  const singleCatFetch = async () => {
    setLoad(true);

    const data: Record<string, Array<GetUserCategoryDataData>>[] = [];

    if (existCat) {
      const promises = existCat.map(async (x) => {
        const fbaCategoryId = unWrapOption(x.fbaCategoryId, () => "");

        const fetchedData = await fetchUserCat(fbaCategoryId);
        console.log("test", fetchedData);

        if (
          !fetchedData ||
          (Array.isArray(fetchedData) && fetchedData.length === 0)
        ) {
          console.warn(`No data found for category ID: ${fbaCategoryId}`);
          return null; // Skip this entry
        }

        const singleCategoryData: Array<GetUserCategoryDataData> = fetchedData;

        return { [fbaCategoryId]: singleCategoryData };
      });

      // Wait for all promises and filter out `null` results
      const results = (await Promise.all(promises)).filter(
        (result): result is { [key: string]: Array<GetUserCategoryDataData> } =>
          result !== null
      );

      // Push valid results to data array
      data.push(...results);
    }

    // Update the state with the processed data
    setCatData(data);

    setLoad(false);
  };

  const updateCat = async () => {
    setActionState(true);
    const transformed: { [key: string]: string[] } = {};

    for (const user of importData) {
      for (const [key, value] of Object.entries(user)) {
        if (
          key !== "uid" &&
          key !== "name" &&
          key !== "error" &&
          value === "Yes" &&
          typeof user.uid === "string"
        ) {
          if (!transformed[key]) {
            transformed[key] = [];
          }
          transformed[key].push(user.uid);
        }
      }
    }

    console.log(transformed);

    const promises = Object.entries(transformed).map(([categoryId, userIds]) =>
      onSubmit(params.id ?? "", categoryId, "specific", userIds.join())
    );

    const result = await Promise.all(promises);

    if (result.every((x) => x?.successful === true)) {
      setShowSuccess({
        show: true,
        message: `${"Upload Success"}`,
      });
    } else {
      setResponseError({
        show: true,
        message: `Update Failed - ${result
          .filter((x) => !x.successful)
          .map(
            (x) =>
              existCat?.find(
                (y) => unWrapOption(y.fbaCategoryId, () => "") === x.message
              )?.name
          )
          .map((z) => z && unWrapOption(z, () => ""))
          .join(", ")} `,
      });
    }

    setActionState(false);
  };

  const fetchCat = async () => {
    if (params.id) {
      const result = await OnboardingService.getAllCorporateCategories(
        params.id
      );

      if (isSuccess(result) && result.value) {
        setExistCat(result.value);
      } else {
        console.log("Error", result);
      }
    }
  };

  const fetchUserCat = async (catId: string) => {
    const result = await OnboardingService.getUserCategoryData(
      params.id ?? "",
      catId
    );
    if (isSuccess(result)) {
      if (result.value.successful) {
        return result.value.data;
      } else {
        // alert(result.value.message);
      }
    } else {
      // setAppState(initialState);
    }
  };

  let nameRegEx = new RegExp(".*" + searchVal + ".*", "gi");

  useEffect(() => {
    if (searchVal.length > 0) {
      setPaging((p) => ({
        ...p,

        total: (searchVal.length > 0 ? importData : tblData(importData)).filter(
          (x) =>
            searchVal.length > 0
              ? nameRegEx.test(x?.name?.toString() || "")
              : true
        ).length,
        page: 1,
        limit: (searchVal.length > 0 ? importData : tblData(importData)).filter(
          (x) =>
            searchVal.length > 0
              ? nameRegEx.test(x?.name?.toString() || "")
              : true
        ).length,
      }));
    }
  }, [searchVal.length]);

  const validateData = (data: IData[] | []) => {
    if (data.length > 0) {
      let newData: [] | IData[] = data.map((item) => {
        item = { ...item, error: [] };

        console.log("Invalid", item);

        existCat?.forEach((x) => {
          if (
            Object.entries(item).find(
              (y) => y[0] === unWrapOption(x.fbaCategoryId, () => "")
            )
          ) {
            const categoryId = unWrapOption(x.fbaCategoryId, () => "");
            const value = item[categoryId];

            if (
              typeof value === "string" &&
              ![
                "Yes",
                "No",
                "Ineligible - Regime",
                "Invalid Tax Regime",
              ].includes(value)
            )
              item.error = [
                ...item.error,
                unWrapOption(x.fbaCategoryId, () => ""),
              ];
          }
        });

        return item;
      });

      return newData;
    } else {
      return data;
    }
  };

  const importExcel = async () => {
    const excelSchema: any = {
      Id: { prop: "uid", type: String, required: true },
      Name: { prop: "name", type: String, required: true },
    };

    existCat?.forEach((x) => {
      const categoryName = unWrapOption(x.name, () => "");

      if (categoryName) {
        excelSchema[categoryName] = {
          prop: unWrapOption(x.fbaCategoryId, () => ""), // Set the property name in camelCase or snake_case
          type: String,
          required: false, // Set to `true` if a category should be required, otherwise `false`
        };
      }
    });

    console.log(excelSchema); // Print to check the final schema structure

    setShowError(false);
    if (excelData.length > 0) {
      let data: any[] = [];
      let rowData: any[] = [];
      let filesArr = [...excelData];

      for (let i = 0; i < filesArr.length; i++) {
        let file = filesArr[i];
        let rows = await readXlsxFile(file, {
          schema: excelSchema,
        });
        /* delete rows[0];
        rows = rows.filter((i) => i); */
        //  console.log("importExcel ::", rows);
        rowData = rows.rows;
        rowData = rowData.map((row: any) => ({ ...row, error: [] }));
      }
      console.log("rowData: ", rowData);
      rowData = validateData(rowData);

      let payload: IPayload[] | [] = [];
      payload = rowData;
      // rowData.map((item) => {
      //   payload = [
      //     ...payload,
      //     {
      //       kitNo: item.kitNo,
      //       awbNumber: item.awbNumber,
      //       error: item.error,
      //     },
      //   ];
      // });

      if (payload.length == 0) setShowError(true);
      console.log("payload:  ", payload);
      setImportData(payload);
      setExcelData([]);
      setPaging((p) => ({ ...p, total: payload.length, page: 1, limit: 10 }));
    }
  };

  const tblData = (tblData: IPayload[] | []) => {
    let result = [];
    let startFrom = (paging.page - 1) * paging.limit;
    let endAt = paging.page * paging.limit;
    result = tblData.slice(startFrom, endAt);

    return result;
  };

  const onSubmit = async (
    cid: string,
    catId: string,
    type: string,
    uid: string
  ) => {
    setShowError(false);
    setResponseError({ show: false, message: "" });

    const result = await OnboardingService.updateUserCategoryData(
      cid,
      catId,
      type,
      uid
    );

    if (isSuccess(result)) {
      if (result.value.successful) {
        console.log(catId, result.value.data);
        return result.value;
      } else {
        return { message: catId, successful: false };
      }
    } else {
      return { message: catId, successful: false };
    }
  };

  if (load) {
    return <TableLoader />;
  }

  const generateExcelSheet = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    const headers = [
      { header: "Id", width: 0 },
      { header: "Name", width: 20 },
    ].concat(
      existCat?.map((cat) => ({
        header: unWrapOption(cat.name, () => ""),
        width: 20,
      })) ?? []
    );

    worksheet.columns = headers;

    worksheet.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ACACAC" },
      };
      cell.protection = {
        locked: true,
      };
    });

    if (employeeList) {
      for (const employee of employeeList) {
        const row = [
          employee.uid,
          employee.empName,
          ...(existCat?.map((category) => {
            const categoryId = unWrapOption(category.fbaCategoryId, () => "");
            const categoryData = catData.find((data) => data[categoryId]);

            const categoryEmployees =
              categoryData?.[categoryId]
                ?.filter((x) => x.type === "specific")
                .flatMap((x) => x.employees.map((y) => y.empName)) ?? [];
            const allFound = categoryData?.[categoryId]?.find(
              (x) => x.type === "all"
            );

            return categoryEmployees.includes(employee.empName)
              ? "Yes"
              : allFound
              ? "Yes"
              : "No";
          }) ?? []),
        ];

        const newRow = worksheet.addRow(row);

        newRow.getCell(1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ACACAC" },
        };
        newRow.getCell(2).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ACACAC" },
        };
        newRow.getCell(1).protection = {
          locked: true,
        };
        newRow.getCell(2).protection = {
          locked: false,
        };

        existCat?.forEach((category, index) => {
          const columnIndex = index + 3;

          if (!["OLD_REGIME", "NEW_REGIME"].includes(employee.taxRegime)) {
            newRow.getCell(columnIndex).dataValidation = {
              type: "list",
              allowBlank: false,
              formulae: ['"Invalid Tax Regime"'],
              showErrorMessage: true,
              errorTitle: "Tax Regime",
              error: "Invalid",
            };
            newRow.getCell(columnIndex).value = "Invalid Tax Regime";
            newRow.getCell(columnIndex).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF9D9D" },
            };
            newRow.getCell(columnIndex).protection = {
              locked: true,
            };
          } else if (
            employee.taxRegime === "NEW_REGIME" &&
            !unWrapOption(category.newRegime, () => false)
          ) {
            newRow.getCell(columnIndex).dataValidation = {
              type: "list",
              allowBlank: false,
              formulae: ['"Ineligible - Regime"'],
              showErrorMessage: true,
              errorTitle: "Ineligible - Regime",
              error: "New Regime",
            };
            newRow.getCell(columnIndex).value = "Ineligible - Regime";
            newRow.getCell(columnIndex).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF9D9D" },
            };
            newRow.getCell(columnIndex).protection = {
              locked: true,
            };
          } else {
            newRow.getCell(columnIndex).dataValidation = {
              type: "list",
              allowBlank: true,
              formulae: ['"Yes,No"'],
              showErrorMessage: true,
              errorTitle: "Invalid Selection",
              error: "Please select Yes or No from the dropdown.",
            };
            newRow.getCell(columnIndex).protection = {
              locked: false, // Unlock editable cells
            };
          }
        });
      }
    }

    // Protect the worksheet
    worksheet.protect("Figital@543", {
      selectLockedCells: false,
      selectUnlockedCells: true,
    });

    // Generate the Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      // Download the file
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Set User Category.xlsx";
      a.click();
    });
  };

  console.log("import data", importData);

  return (
    <>
      <h1 className="page-heading fw-bold">
        Bulk Customise Category for Employees
      </h1>
      {importData && importData && importData.length === 0 && (
        <WhiteCard className="mt-4">
          <div className="d-flex justify-content-between">
            <div className="steps">
              <h3 className="page-heading mb-3">
                {" "}
                Step - 1<span> Download the template</span>
              </h3>
            </div>

            <ReuseIcon className="mb-3">
              <svg
                className="comman-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="47.54"
                height="43.884"
                viewBox="0 0 47.54 43.884"
                onClick={generateExcelSheet}
              >
                <path
                  id="download"
                  d="M36.57,38.4a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,36.57,38.4Zm7.313,0a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,43.883,38.4ZM47.54,32v9.142A2.732,2.732,0,0,1,44.8,43.884H2.743a2.645,2.645,0,0,1-1.943-.8A2.645,2.645,0,0,1,0,41.141V32a2.645,2.645,0,0,1,.8-1.943,2.645,2.645,0,0,1,1.943-.8H16.028l3.857,3.885a5.516,5.516,0,0,0,7.77,0l3.885-3.885H44.8A2.732,2.732,0,0,1,47.54,32ZM38.255,15.743a1.648,1.648,0,0,1-.4,2l-12.8,12.8a1.8,1.8,0,0,1-2.572,0l-12.8-12.8a1.648,1.648,0,0,1-.4-2,1.708,1.708,0,0,1,1.685-1.115h7.313V1.829A1.758,1.758,0,0,1,18.826.543,1.758,1.758,0,0,1,20.112,0h7.313a1.758,1.758,0,0,1,1.286.543,1.758,1.758,0,0,1,.543,1.286v12.8h7.313a1.706,1.706,0,0,1,1.685,1.115Z"
                  fill="#103458"
                />
              </svg>
            </ReuseIcon>
          </div>
        </WhiteCard>
      )}

      <WhiteCard className="mt-4">
        {importData.length > 0 && (
          <EmployeeSearch
            searchVal={searchVal}
            setSearchVal={(val) => {
              setSearchVal(val);
            }}
          />
        )}
        <div className="steps">
          {/* <h3 className="mb-3">Uploaded Sheet</h3> */}
          {importData.length > 0 ? (
            <>
              {(searchVal.length > 0 ? importData : tblData(importData)).filter(
                (x) =>
                  searchVal.length > 0
                    ? nameRegEx.test(x?.name?.toString() || "")
                    : true
              ).length > 0 ? (
                <TablePagination
                  data={paging}
                  changePage={(data) =>
                    setPaging((p) => ({ ...p, page: data }))
                  }
                  changeLimit={(data) =>
                    setPaging((p) => ({ ...p, limit: data, page: 1 }))
                  }
                >
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" style={{ textAlign: "left" }}>
                          Name
                        </th>
                        {existCat?.map((x) => (
                          <th scope="col">{unWrapOption(x.name, () => "")}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {(searchVal.length > 0 ? importData : tblData(importData))
                        .filter((x) =>
                          searchVal.length > 0
                            ? nameRegEx.test(x?.name?.toString() || "")
                            : true
                        )
                        .map((item, key) => (
                          <tr
                            //   className={classNames({
                            //     "error-row": item.error && item.error.length > 0,
                            //   })}
                            style={
                              item.error && item.error.length > 0
                                ? { backgroundColor: "#ffb4c5" }
                                : {}
                            }
                            key={key}
                          >
                            <td
                              style={
                                item.error && item.error.includes("name")
                                  ? {
                                      backgroundColor: "#ffb4c5",
                                      textAlign: "left",
                                    }
                                  : { textAlign: "left" }
                              }
                            >
                              {item.name}
                            </td>
                            {existCat?.map((y) => (
                              <td
                                style={
                                  item.error &&
                                  item.error.includes(
                                    Object.entries(item).find(
                                      (x) =>
                                        x[0] ===
                                        unWrapOption(y.fbaCategoryId, () => "")
                                    )?.[0] ?? ""
                                  )
                                    ? { backgroundColor: "#ffb4c5" }
                                    : {
                                        backgroundColor: [
                                          "Ineligible - Regime",
                                          "Invalid Tax Regime",
                                        ].includes(
                                          Object.entries(item).find(
                                            (x) =>
                                              x[0] ===
                                              unWrapOption(
                                                y.fbaCategoryId,
                                                () => ""
                                              )
                                          )?.[1] as string
                                        )
                                          ? "#D9EBFF"
                                          : Object.entries(item).find(
                                              (x) =>
                                                x[0] ===
                                                unWrapOption(
                                                  y.fbaCategoryId,
                                                  () => ""
                                                )
                                            )?.[1] === "Yes"
                                          ? "#C6F5DF"
                                          : "#FFA7001A",
                                      }
                                }
                              >
                                {
                                  Object.entries(item).find(
                                    (x) =>
                                      x[0] ===
                                      unWrapOption(y.fbaCategoryId, () => "")
                                  )?.[1]
                                }
                              </td>
                            ))}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </TablePagination>
              ) : (
                <NoTableData />
              )}
              <div className="action"></div>
            </>
          ) : (
            <>
              <h3 className="page-heading mb-3">
                Step - 2
                <p style={{ fontSize: 15, marginTop: 5 }}>
                  Upload the filled in template
                </p>
              </h3>

              <Dropzone
                onDrop={(acceptedFiles) => {
                  let arr: File[] = [];
                  acceptedFiles.forEach((file) => {
                    if (file.name.split(".").pop() == "xlsx")
                      arr = [...arr, file];
                  });
                  setExcelData(arr);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section
                    className="p-3 text-center my-4 mx-auto wrap-file-upload "
                    role="button"
                    style={{
                      border: "2px dashed #d0d0db",
                      width: "80%",
                      borderRadius: "1rem",
                    }}
                  >
                    <div {...getRootProps()}>
                      <input {...getInputProps()} accept=".xlsx" />
                      <svg
                        id="Group_2844"
                        width="60"
                        data-name="Group 2844"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 158.798 190.158"
                      >
                        <path
                          id="Path_6474"
                          data-name="Path 6474"
                          d="M258.215,314.354H215.523A12.651,12.651,0,0,1,202.872,301.7V246.6h-43.22l77.866-77.772,77.23,77.275h-43.88v55.6A12.651,12.651,0,0,1,258.215,314.354Z"
                          transform="translate(-157.192 -168.825)"
                          fill="#cecece"
                        />
                        <rect
                          id="Rectangle_365"
                          data-name="Rectangle 365"
                          width="158.798"
                          height="21.5"
                          rx="10.75"
                          transform="translate(0 168.659)"
                          fill="#cecece"
                        />
                      </svg>
                      <div
                        className="w-25 px-4 mx-auto mt-2"
                        style={{ fontSize: 16 }}
                      >
                        <p>Drag and drop here </p>
                        <p>or</p>
                        <p className="">Browse Files</p>
                      </div>
                      <ul className="files-list">
                        {excelData.map((file, fk) => (
                          <li key={fk}>{file.name}</li>
                        ))}
                      </ul>
                    </div>
                  </section>
                )}
              </Dropzone>

              <div className="w-25 mx-auto text-center">
                {excelData.length > 0 && (
                  <button
                    style={{ marginRight: "20px" }}
                    className="btn-ncaish-light text-white px-3 py-2"
                    onClick={() => setExcelData([])}
                  >
                    Clear
                  </button>
                )}
                {excelData.length > 0 && (
                  <button
                    disabled={excelData.length == 0}
                    className="btn-gradiant-blue text-white w-50 px-3 py-2"
                    onClick={() => importExcel()}
                  >
                    Upload
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </WhiteCard>
      {importData && importData && importData.length > 0 && (
        <div className="mb-4" style={{ textAlign: "right" }}>
          <button
            className="btn-ncaish-light text-white px-3 py-2"
            onClick={() => setImportData([])}
          >
            Remove
          </button>
          <button
            disabled={
              importData
                .map((obj) => obj.error)
                .map((obj) => (obj?.length === 0) === true)
                .filter((obj) => obj === false).length !== 0 || actionState
            }
            style={{ marginLeft: "10px" }}
            className="btn-gradiant-blue text-white px-3 py-2"
            onClick={() => updateCat()}
          >
            {actionState && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
            )}
            Submit
          </button>
        </div>
      )}

      <SuccessPopup
        show={showSuccess.show}
        onClose={() => {
          setShowSuccess({ show: false, message: "" });
          navigate(RoutePaths.NavigateToOnboardingDetails + "/" + params.id);
        }}
        message={showSuccess.message}
      />

      <ErrorPopup
        errorText={
          showError
            ? `Invalid data! Download the template and enter the employee delivery details in the required format`
            : responseError.message
        }
        show={showError || responseError.show}
        onHide={(flag) => {
          setShowError(flag);
          setResponseError({ show: false, message: "" });
        }}
      />
    </>
  );
};

export default UserCatUpload;

export const SuccessPopup: React.FC<{
  message?: string;
  show?: boolean;
  onClose: (show: boolean) => void;
  txtSmall?: boolean;
  failedData?: string;
}> = ({
  message = "Successful!",
  show = false,
  onClose,
  txtSmall = false,
  failedData,
}) => {
  return (
    <>
      <Modal show={show} onClose={() => onClose(false)} centered>
        <Modal.Header className="d-flex flex-column justify-content-center mt-3">
          <div className="text-center rounded-circle bg-success  text-light  fs-2 ncaish-w-50">
            &#10004;
          </div>
          <Modal.Title
            className="text-success mt-3 mb-3"
            style={
              txtSmall
                ? { fontSize: 20, textAlign: "center" }
                : { textAlign: "center" }
            }
          >
            {message}

            {failedData && (
              <p className="m-0 text-danger" style={{ fontSize: 14 }}>
                Failed: {failedData}
              </p>
            )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer
          className="ncaish-bg-grey d-flex justify-content-center pointer"
          onClick={() => onClose(false)}
        >
          <p
            className="light fw-bold"
            style={
              txtSmall
                ? { fontSize: 14, textAlign: "center" }
                : { textAlign: "center" }
            }
          >
            OK
          </p>
          {/* <button
            className="primary-bg btn success-popup-button"
            onClick={() => onClose(false)}
            style={
              txtSmall
                ? { fontSize: 14, textAlign: "center" }
                : { textAlign: "center" }
            }
          >
            OK
          </button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};
