/**
 * Ncaish Admin API
 * Apis to super admin operations
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BrReceipientInfo } from './brReceipientInfo';


export interface AdminBrInfo { 
    status?: AdminBrInfo.StatusEnum;
    document?: string;
    sentOn?: string;
    signedOn?: string;
    receipients?: Array<BrReceipientInfo>;
}
export namespace AdminBrInfo {
    export type StatusEnum = 'PENDING' | 'SENT' | 'PARTIALLY_SIGNED' | 'SIGNED';
    export const StatusEnum = {
        PENDING: 'PENDING' as StatusEnum,
        SENT: 'SENT' as StatusEnum,
        PARTIALLYSIGNED: 'PARTIALLY_SIGNED' as StatusEnum,
        SIGNED: 'SIGNED' as StatusEnum
    };
}

