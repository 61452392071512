/**
 * Ncaish Admin API
 * Apis to super admin operations
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AdminCorporateCountInfo { 
    label?: string;
    status?: AdminCorporateCountInfo.StatusEnum;
    count?: number;
}
export namespace AdminCorporateCountInfo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'BLOCKED';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum,
        BLOCKED: 'BLOCKED' as StatusEnum
    };
}

