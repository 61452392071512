import { RemoteData } from "@devexperts/remote-data-ts";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { CampaignTabKind } from "../../../components/pages/dashboard/settings/campaign.utils";
import { DeliveryUploadRequest } from "../../../model/deliveryUploadRequest";
import { DeliveryUploadResponse } from "../../../model/deliveryUploadResponse";
import { ApiClient } from "../../api-client";
import { APIError } from "../../errors";
import { BaseResponse } from "../model/baseResponse";
import { CampaignCreateResponse } from "../model/campaignCreateResponse";
import { CampaignListResponse } from "../model/campaignListResponse";
import { IMPSFeesRes } from "../model/impsFeesRes";
import { ReplaceCardRes } from "../model/replaceCardDetails";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

export namespace NcaishSettingsApiService {
  export const createCampaign = async (
    title: string,
    body: string,
    cids: Array<string>,
    images: any,
    campaignState: CampaignTabKind,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CampaignCreateResponse>> => {
    const path = `/secure/corporates/campaign/create`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    let formData = new FormData();

    formData.append("images", images);
    formData.append("title", title);
    formData.append("body", body);
    formData.append("cids", cids.join(","));
    formData.append("campaignState", campaignState);

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: formData,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getCampaignList = async (
    state: CampaignTabKind,
    pn: number,
    rc: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CampaignListResponse>> => {
    const path = `/secure/corporates/campaign/get-all`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "state",
        value: state,
      },
      {
        key: "pn",
        value: pn,
      },
      { key: "rc", value: rc },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getCampaignById = async (
    campaignId: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CampaignCreateResponse>> => {
    const path = `/secure/corporates/campaign/get/${campaignId}`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const updateCampaignById = async (
    campaignId: string,
    title: string,
    body: string,
    cids: Array<string>,
    images: any,
    campaignState: CampaignTabKind,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CampaignCreateResponse>> => {
    const path = `/secure/corporates/campaign/update/${campaignId}`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    let formData = new FormData();

    if (typeof images !== "string") {
      formData.append("images", images);
    }
    formData.append("title", title);
    formData.append("body", body);
    formData.append("cids", cids.join(","));
    formData.append("campaignState", campaignState);

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: formData,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const deliveryUpload = async (
    body: Array<DeliveryUploadRequest>,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DeliveryUploadResponse>> => {
    const path = `/secure/detax/cards/addTrackingNo`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const sendNotification = async (
    cid: string,
    body: FormData,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, BaseResponse>> => {
    const path = `/secure/admin/sendNotification/${cid}`.replace(/^\//, "");

    const allQueryParams: TQueryParam[] = [];

    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getReplaceCardDetails = async (
    from: string,
    to: string,
    pn: number,
    rc: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ReplaceCardRes>> => {
    const path = `/secure/corporates/fee-deduction/report`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      { key: "from", value: from },
      { key: "to", value: to },
      { key: "pn", value: pn },
      { key: "rc", value: rc },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getIMPSFees = async (
    from: string,
    to: string,
    pn: number,
    rc: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, IMPSFeesRes>> => {
    const path = `/secure/corporates/imps-fee/report`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      { key: "from", value: from },
      { key: "to", value: to },
      { key: "pn", value: pn },
      { key: "rc", value: rc },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}
