import { useState } from "react";
import CorporateSearch from "./corporate-search";
import CorporateStatusCount from "./corporate-status-count";
import CorporateTable from "./corporate-table";

import Heading from "../heading";
import PageTitle from "../../common/page-title";
import { AdminCorporateCountInfo } from "../../../api/corporate/model/adminCorporateCountInfo";

const Corporate: React.FC = () => {
  const [curTbl, setCurTbl] = useState<AdminCorporateCountInfo | null>(null);
  const [searchVal, setSearchVal] = useState("");
  const [search, setSearch] = useState(false);
  return (
    <>
      <PageTitle title="Corporates" />
      <Heading title="Corporates" />
      <CorporateSearch
        searchVal={searchVal}
        setSearchVal={setSearchVal}
        onSearch={() => {
          setSearch(!search);
        }}
      />
      <CorporateStatusCount setCurTbl={setCurTbl} curTbl={curTbl} />
      {curTbl && (
        <CorporateTable searchVal={searchVal} curTbl={curTbl} search={search} />
      )}
    </>
  );
};

export default Corporate;
