/**
 * Ncaish Corporate apis
 * Api to manage corporate onboarding ,application and employee management
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import { RemoteData } from "@devexperts/remote-data-ts";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { ApiClient } from "../../api-client";
import { APIError } from "../../errors";

//@ts-ignore
//import * as models from '../model/models';
import { AdminCorporateSubscriptionListApiResponse } from "../../admin/model/adminCorporateSubscriptionListApiResponse";
import { AddSubscriptionPayload } from "../../admin/model/corporate/addSubscriptionPayload";
import { AdminCorporateUpdateSubscriptionApiResponse } from "../../admin/model/corporate/adminCorporateUpdateSubscriptionApiResponse";
import {
  AddRemoveUserResponse,
  AddUserRequest,
} from "../model/addremoveuserresponse";
import { AdminCorpCountApiResponse } from "../model/adminCorpCountApiResponse";
import { AdminCorporateListApiResponse } from "../model/adminCorporateListApiResponse";
import { CorporateCardWalletResponse } from "../model/corporateCardWalletResponse";
import { CorporateDetailApiResponse } from "../model/corporateDetailApiResponse";
import { DashboardResponse } from "../model/dashboardResponse";
import { GetM2pWalletResponse } from "../model/getM2pWalletResponse";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NcaishCorporateApiService {
  /**
   *
   * @param id -
   */
  export const activateWallet = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CorporateCardWalletResponse>> => {
    const path = `/secure/corporates/${encodeURIComponent(
      String(id)
    )}/activate/wallet`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to fetch status wise corporate count
   */
  export const applicationCounts = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminCorpCountApiResponse>> => {
    const path = `/secure/corporates/count`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to get corporate detail
   * @param id -
   */
  export const detail = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CorporateDetailApiResponse>> => {
    const path = `/secure/corporates/${encodeURIComponent(String(id))}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  //    Add or Remove User

  /**
   * Api to get corporate detail
   * @param id -
   */
  export const addremoveUser = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AddRemoveUserResponse>> => {
    const path = `/secure/admin/getCorpAdmins/${encodeURIComponent(String(id))}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param body -
   */
  export const addUser = async (
    body: AddUserRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AddRemoveUserResponse>> => {
    const path = `/secure/corporates/admin`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to get corporate detail
   * @param id -
   */
  export const removeUser = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AddRemoveUserResponse>> => {
    const path = `/secure/corporates/admin/remove/${encodeURIComponent(
      String(id)
    )}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param id -
   */
  export const fetchBalance = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetM2pWalletResponse>> => {
    const path = `/secure/corporates/${encodeURIComponent(
      String(id)
    )}/detax/card/fetch/balance`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to fetch status wise corporate list
   * @param status -
   */
  export const fetchByState = async (
    status?: string,
    pn?: number,
    rc?: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminCorporateListApiResponse>> => {
    const path = `/secure/corporates/list`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "status",
        value: status,
      },
      {
        key: "pn",
        value: pn,
      },
      {
        key: "rc",
        value: rc,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const fetchByStateSearch = async (
    keyword?: string,
    status?: string,
    pn?: number,
    rc?: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminCorporateListApiResponse>> => {
    const path = `/secure/corporates/search/all/corporates`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "keyword",
        value: keyword,
      },
      {
        key: "status",
        value: status,
      },
      {
        key: "pn",
        value: pn,
      },
      {
        key: "rc",
        value: rc,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   */
  export const fetchMyBalance = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetM2pWalletResponse>> => {
    const path = `/secure/corporates/me/detax/card/balance`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   */
  export const getDashboardData = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DashboardResponse>> => {
    const path = `/secure/corporates/me/dashboard`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getCorporateSubscriptionList = async (
    cId?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<
    RemoteData<APIError, AdminCorporateSubscriptionListApiResponse>
  > => {
    const path =
      `/secure/corporates/membership/admin/getAll/membershipEntry/${cId}`.replace(
        /^\//,
        ""
      );

    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;

    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  export const updateCorporateSubscriptionList = async (
    subscriptionList: AddSubscriptionPayload,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<
    RemoteData<APIError, AdminCorporateUpdateSubscriptionApiResponse>
  > => {
    const path =
      `/secure/corporates/membership/admin/update/subscriptions`.replace(
        /^\//,
        ""
      );

    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;

    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: subscriptionList,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}
