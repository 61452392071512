export interface AllEmployeesStatusCountInfo {
  label?: AllEmployeesStatusCountInfo.StateEnum;
  status?: AllEmployeesStatusCountInfo.StatusEnum;
  count?: number;
}

export namespace AllEmployeesStatusCountInfo {
  export type StateEnum =
    | "All"
    | "Acceptance Pending"
    | "Kyc Pending"
    | "Kyc Initiated"
    | "Kyc Submitted"
    | "Kyc Rejected"
    | "Activation Pending"
    | "Activated"
    | "Deactivated"
    | "Blocked"
    | "Expired";

  export const StateEnum = {
    All: "All" as StateEnum,
    Acceptance_Pending: "Acceptance Pending" as StateEnum,
    Kyc_Pending: "Kyc Pending" as StateEnum,
    Kyc_Initiated: "Kyc Initiated" as StateEnum,
    Kyc_Submitted: "Kyc Submitted" as StateEnum,
    Kyc_Rejected: "Kyc Rejected" as StateEnum,
    Activation_Pending: "Activation Pending" as StateEnum,
    Activated: "Activated" as StateEnum,
    Deactivated: "Deactivated" as StateEnum,
    Blocked: "Blocked" as StateEnum,
    Expired: "Expired" as StateEnum,
  };

  export type StatusEnum =
    | "ALL"
    | "ACCEPTANCE_PENDING"
    | "KYC_PENDING"
    | "KYC_INITIATED"
    | "KYC_UNDER_REVIEW"
    | "KYC_REJECTED"
    | "ACTIVATION_PENDING"
    | "ACTIVATED"
    | "DEACTIVATED"
    | "BLOCKED"
    | "EXPIRED";

  export const StatusEnum = {
    ALL: "ALL" as StatusEnum,
    ACCEPTANCE_PENDING: "ACCEPTANCE_PENDING" as StatusEnum,
    KYC_PENDING: "KYC_PENDING" as StatusEnum,
    KYC_INITIATED: "KYC_INITIATED" as StatusEnum,
    KYC_UNDER_REVIEW: "KYC_UNDER_REVIEW" as StatusEnum,
    KYC_REJECTED: "KYC_REJECTED" as StatusEnum,
    ACTIVATION_PENDING: "ACTIVATION_PENDING" as StatusEnum,
    ACTIVATED: "ACTIVATED" as StatusEnum,
    DEACTIVATED: "DEACTIVATED" as StatusEnum,
    BLOCKED: "BLOCKED" as StatusEnum,
    EXPIRED: "EXPIRED" as StatusEnum,
  };
}
