import { isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import { AmplitudeInstance } from "../../..";
import {
  AdminApplicationInfo,
  SelectedServicesInfo,
} from "../../../api/admin/model/adminApplicationInfo";
import { CorpFundReq } from "../../../api/admin/model/corpFundReq";
import { AdminListOfScheme } from "../../../api/admin/model/onboarding/adminListOfSchemes";
import { AmplitudeEvent } from "../../../enum/amplitude";
import { OnboardingService } from "../../../services/onboarding.service";
import SuccessPopup from "../../pages/dashboard/common/succes-popup";
import { CustomOptionType } from "../common/ServicePopup";
import { NpsData } from "./onboard-details";

interface props {
  details: AdminApplicationInfo;
  id: string;
  npsData: NpsData;
  setNpsData: (val: NpsData) => void;
  selectedServices: SelectedServicesInfo[];
}

const OnboardServiceNps: React.FC<props> = ({
  details,
  id,
  npsData,
  setNpsData,
  selectedServices,
}) => {
  const [schemes, setSchemes] = useState<AdminListOfScheme[]>([]);
  const [successPopup, setSuccessPopup] = useState({
    show: false,
    message: "",
  });
  useEffect(() => {
    const run = async () => {
      const result = await OnboardingService.getAllSchemes();
      if (isSuccess(result)) {
        setSchemes(result.value.data ?? []);
      } else {
        // setAppState(initialState);
      }
    };

    run();
  }, [details.corporateInfo]);

  const schemeOptions: CustomOptionType[] = schemes?.map((obj) => ({
    label: obj.fundName,
    value: obj.fundName,
  }));

  const updateCorpFund = async () => {
    const req: CorpFundReq = {
      corpFund: npsData.fund,
      cbo: npsData.cbo,
      cho: npsData.cho,
      cid: id,
      services: selectedServices
        .filter((x) => x.selected)
        .map((y) => y.type)
        .join(","),
      retireAge: npsData.age,
    };
    const result = await OnboardingService.updateCorpFund(req);
    if (isSuccess(result) && result.value.successful) {
      setSuccessPopup({ show: true, message: result.value.message ?? "" });
      AmplitudeInstance.logEvent(AmplitudeEvent.UPDATE_PFM_CBO_CHO, {
        timeStamp: new Date(),
        applicationName: details.corporateInfo?.name,
        applicationCode: details.appCode,
        pfm: npsData.fund,
        cho: npsData.cho,
        cbo: npsData.cbo,
        id: id,
      });
    } else {
      // setAppState(initialState);
    }
  };
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="m-2">
          <p className="m-0" style={{ fontWeight: "600" }}>
            PFM Name <span className="text-danger">*</span>
          </p>

          <select
            className="form-control"
            value={npsData.fund}
            onChange={(e) =>
              setNpsData({
                ...npsData,
                fund: e.target.value,
              })
            }
          >
            {[
              ...[{ label: "No Preference", value: "No Preference" }],
              ...schemeOptions,
            ].map((x) => (
              <option value={x.value}>{x.label}</option>
            ))}
          </select>
        </div>
        <div className="m-2">
          <p className="m-0" style={{ fontWeight: "600" }}>
            CBO No. <span className="text-danger">*</span>
          </p>
          <input
            className="form-control"
            type="text"
            value={npsData.cbo}
            onChange={(e) =>
              setNpsData({
                ...npsData,
                cbo: isNaN(parseInt(e.target.value))
                  ? ""
                  : parseInt(e.target.value).toString(),
              })
            }
          />
        </div>
        <div className="m-2">
          <p className="m-0" style={{ fontWeight: "600" }}>
            CHO No. <span className="text-danger">*</span>
          </p>
          <input
            className="form-control"
            type="text"
            value={npsData.cho}
            onChange={(e) =>
              setNpsData({
                ...npsData,
                cho: isNaN(parseInt(e.target.value))
                  ? ""
                  : parseInt(e.target.value).toString(),
              })
            }
          />
        </div>
        <div className="m-2">
          <p className="m-0" style={{ fontWeight: "600" }}>
            Retire Age <span className="text-danger">*</span>
          </p>
          <input
            className="form-control"
            type="text"
            value={npsData.age}
            onChange={(e) =>
              setNpsData({
                ...npsData,
                age: isNaN(parseInt(e.target.value))
                  ? ""
                  : parseInt(e.target.value).toString(),
              })
            }
          />
        </div>
      </div>
      {details?.applicationStatus ===
        AdminApplicationInfo.ApplicationStatusEnum.LIVE && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
          className="me-2"
        >
          <button
            className="btn-gradiant-blue text-white px-3  py-2 mt-2 me-2"
            disabled={
              npsData.fund.length > 0 &&
              npsData.cbo.length > 0 &&
              npsData.cho.length > 0
                ? false
                : true
            }
            onClick={() => {
              updateCorpFund();
            }}
          >
            Save
          </button>
        </div>
      )}
      <SuccessPopup
        message={successPopup.message}
        show={successPopup.show}
        onClose={(flag) => setSuccessPopup({ show: false, message: "" })}
      />
    </>
  );
};

export default OnboardServiceNps;
