import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import {
  fold,
  fromNullable,
  isNone,
  isSome,
  none,
  Option,
  some,
} from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AmplitudeInstance } from "../../..";
import { AdminCorporateBasicInfo } from "../../../api/admin/model/adminCorporateBasicInfo";
import { AdminCorporateCountInfo } from "../../../api/corporate/model/adminCorporateCountInfo";
import { SwitchAccountApiRequest } from "../../../api/oauth/model/switchAccountApiRequest";
import { AmplitudeEvent } from "../../../enum/amplitude";
import { useAppSelector } from "../../../hooks/hooks";
import { AuthService } from "../../../services/auth.service";
import { CorporateService } from "../../../services/corporate.service";
import { getTitleCase } from "../../../utils/common.utils";
import { RoutePaths } from "../../../utils/route.utils";
import { selectLoginId } from "../../app/app.slice";
import NoTableData from "../../pages/dashboard/common/no-table-data";
import TableLoader from "../../pages/dashboard/common/table-loader";
import {
  ITableData,
  TablePagination,
} from "../../pages/dashboard/common/table/table-pagination";
import ErrorPopup from "../common/ErrorPopup";
import SwitchAccountPopup from "../common/switch-account-popup";
import "../heading.scss";
import StatusText from "../status-text";

const CorporateTable: React.FC<{
  curTbl?: AdminCorporateCountInfo | null;
  searchVal: string;
  search: boolean;
}> = ({ curTbl, searchVal, search }) => {
  const [items, setItems] = useState<Option<AdminCorporateBasicInfo[]>>(none);
  const [showSwitchAccountPopup, setShowSwitchAccountPopup] = useState(false);
  const [selectedCorporateId, setSelectedCorporateId] = useState<string>("");
  const [selectedCorporateName, setSelectedCorporateName] =
    useState<string>("");
  const [error, setError] = useState<string>("");
  const [filteredData, setFilteredData] =
    useState<Option<AdminCorporateBasicInfo[]>>(none);
  const [lastPage, setLastPage] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });
  // const [searchVal, setSearchVal] = useState(" ");
  useEffect(() => {
    setPaging((p) => ({
      ...p,
      total: 10000,
      page: 1,
      limit: 10,
    }));
  }, [curTbl, search]);
  useEffect(() => {
    const run = async () => {
      if (curTbl?.status) {
        setItems(none);
        const tblResult = await CorporateService.fetchByState(
          curTbl?.status,
          paging.page,
          paging.limit
        );
        if (isSuccess(tblResult)) {
          if (tblResult.value.successful) {
            setPaging((p) => ({
              ...p,
              total: tblResult.value.recordCount,
              page: paging.page,
              limit: paging.limit,
            }));
            // setItems(some(tblResult.value.data));
            setFilteredData(some(tblResult.value.data || []));
            AmplitudeInstance.logEvent(AmplitudeEvent.CORPORATE_STATUS, {
              timeStamp: new Date(),
              status: curTbl?.status,
            });
          }
          setItems(some(tblResult.value.data || []));
        }
      }
    };
    const search = async () => {
      if (curTbl?.status) {
        setItems(none);
        const tblResult = await CorporateService.fetchByStateSearch(
          searchVal,
          curTbl?.status,
          paging.page,
          paging.limit
        );
        if (isSuccess(tblResult)) {
          if (tblResult.value.successful) {
            setPaging((p) => ({
              ...p,
              total: tblResult.value.recordCount,
              page: paging.page,
              limit: paging.limit,
            }));
            // setItems(some(tblResult.value.data));
            setFilteredData(some(tblResult.value.data || []));
            AmplitudeInstance.logEvent(AmplitudeEvent.CORPORATE_SEARCH, {
              timeStamp: new Date(),
              searchVal: searchVal,
            });
            AmplitudeInstance.logEvent(AmplitudeEvent.CORPORATE_STATUS, {
              timeStamp: new Date(),
              status: curTbl?.status,
            });
          }
          setItems(some(tblResult.value.data || []));
        }
      }
    };

    if (searchVal.length === 0) {
      run();
    } else {
      search();
    }
  }, [paging.page, paging.limit, paging.total]);
  // SET PAGINATION
  const tblData = (tblData: AdminCorporateBasicInfo[]) => {
    // let result = [];
    // let startFrom = (paging.page - 1) * paging.limit;
    // let endAt = paging.page * paging.limit;
    // result = tblData.slice(startFrom, endAt);
    return tblData;
  };

  const loginId = useAppSelector(selectLoginId);

  const handleSwitchAccountToCorporate = async () => {
    if (selectedCorporateId) {
      const requestPaylaod = {
        uid: loginId,
        cid: selectedCorporateId,
        switchTo: SwitchAccountApiRequest.SwitchToEnum.CORPORATE,
      };

      const result = await AuthService.switchAccountTokenUrl(requestPaylaod);

      if (isSuccess(result)) {
        if (result.value.successful) {
          console.log(result.value.url);
          const url = fromNullable(result.value.url);
          if (isSome(url)) {
            const urlArray = url.value.split("/");

            const urlToSwitch =
              `${process.env.REACT_APP_CORPORATE_DOMAIN_URL}` + urlArray.pop();

            AmplitudeInstance.logEvent(AmplitudeEvent.SWITCHING, {
              timeStamp: new Date(),
              corporateName: selectedCorporateName,
              request: requestPaylaod,
              url: urlToSwitch,
            });

            //localStorage.clear();
            window.location.href = urlToSwitch;
          } else {
            setError("Url not found to switch.");
          }
          setShowSwitchAccountPopup(false);
        } else {
          setError(
            result.value.message || "You are not permitted to switch accounts."
          );
          setShowSwitchAccountPopup(false);
        }
      } else {
        //setError("Something went wrong.");
        setShowSwitchAccountPopup(false);
      }
    }
  };

  if (isNone(items)) {
    return <TableLoader />;
  }

  return (
    <div
      className="row mt-2 ms-1 me-1 p-3 border rounded-15"
      style={{ background: "white" }}
    >
      <div>
        {pipe(
          filteredData,
          fold(
            () => [<NoTableData />],
            (data) =>
              data.length
                ? [
                    <TablePagination
                      data={paging}
                      changePage={(data) =>
                        setPaging((p) => ({ ...p, page: data }))
                      }
                      changeLimit={(data) =>
                        setPaging((p) => ({ ...p, limit: data, page: 1 }))
                      }
                    >
                      <table>
                        <thead>
                          <tr>
                            <th scope="col">Corporate Code</th>
                            <th scope="col">Corporate Name</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Email id</th>
                            <th scope="col">Created At</th>
                            <th scope="col">Update At</th>
                            <th scope="col" style={{ zIndex: "100" }}>
                              Status
                            </th>
                            <th scope="col"></th>
                          </tr>
                        </thead>

                        <tbody>
                          {tblData(data).map((item, key) => (
                            <tr key={key}>
                              <td>
                                <Link
                                  className="text-secondary ncash_nav-link"
                                  to={
                                    RoutePaths.NavigateToCorporateDetails +
                                    "/" +
                                    item.id
                                  }
                                  onClick={() => {
                                    AmplitudeInstance.logEvent(
                                      AmplitudeEvent.CORPORATE_DETAILS,
                                      {
                                        timeStamp: new Date(),
                                        applicationName: item.companyName,
                                        corpCode: item.corpCode,
                                      }
                                    );
                                  }}
                                >
                                  {item.corpCode === "" ? "-" : item.corpCode}
                                </Link>
                              </td>
                              <td>
                                {item.companyName === ""
                                  ? "-"
                                  : item.companyName}
                              </td>
                              <td>
                                {item.applicantInfo?.name === ""
                                  ? "-"
                                  : item.applicantInfo?.name}
                              </td>
                              <td>
                                {item.applicantInfo?.email === ""
                                  ? "-"
                                  : item.applicantInfo?.email}
                              </td>
                              <td className="text-center text-nowrap">
                                {item.createdAt || "-"}
                              </td>
                              <td className="text-center text-nowrap">
                                {item.updatedAt || "-"}
                              </td>
                              <td
                                className="text-center text-nowrap"
                                style={{ zIndex: "200" }}
                              >
                                <StatusText
                                  text={getTitleCase(
                                    item.status?.replace("_", " ") || ""
                                  )}
                                  className={classNames({
                                    color7: item.status == "ACTIVE",
                                    color6: item.status == "INACTIVE",
                                    color8: item.status == "BLOCKED",
                                  })}
                                />
                              </td>
                              <td>
                                <button
                                  className=""
                                  onClick={() => {
                                    setShowSwitchAccountPopup(true);
                                    setSelectedCorporateId(item.id || "");
                                    setSelectedCorporateName(
                                      item.companyName ?? ""
                                    );
                                  }}
                                >
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 18 18"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"
                                    ></path>
                                    <path
                                      fill-rule="evenodd"
                                      d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                                    ></path>
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TablePagination>,
                  ]
                : [<NoTableData />]
          )
        )}
      </div>
      <SwitchAccountPopup
        show={showSwitchAccountPopup}
        onHide={() => setShowSwitchAccountPopup(false)}
        onApprove={() => handleSwitchAccountToCorporate()}
      />
      {error && (
        <ErrorPopup
          errorText={error}
          show={!!error}
          onHide={(show) => {
            setError("");
          }}
        />
      )}
    </div>
  );
};

export default CorporateTable;
