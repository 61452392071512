import { isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Dropzone from "react-dropzone";
import Select, { ActionMeta, MultiValue } from "react-select";
import makeAnimated from "react-select/animated";
import { CampaignData } from "../../../../api/detax/model/campaignCreateResponse";
import { CorporateService } from "../../../../services/corporate.service";
import { SettingsService } from "../../../../services/settings.service";
import ErrorPopup from "../../../admin/common/ErrorPopup";
import { Option } from "../../../admin/onboarding/common/set-max-limit";
import SuccessPopup from "../common/succes-popup";
import { CampaignLabel, CampaignTabKind } from "./campaign.utils";
import { AmplitudeInstance } from "../../../..";
import { AmplitudeEvent } from "../../../../enum/amplitude";

const backIcon = "/images/back.svg";

const editIcon = "/images/edit.svg";
const defaultIcon = "/images/default.svg";

interface props {
  id: string;
  onClose: () => void;
}

const initialData: CampaignData = {
  body: "The content of your campaign is present here.",
  id: "",
  updatedby: null,
  createdBy: null,
  deletedBy: null,
  objectId: "",
  deletedAt: 0,
  updatedAt: 0,
  createdAt: Math.floor(Date.now() / 1000),
  archive: false,
  images: defaultIcon,
  title: "New Campaign",
  cids: [],
  campaignState: CampaignTabKind.LIVE,
  startDate: "",
  endDate: "",
};

const CampaignDetails: React.FC<props> = ({ id, onClose }) => {
  const animatedComponents = makeAnimated();
  const [corporateList, setCorporateList] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option[]>([]);
  const [showEdit, setShowEdit] = useState(false);
  const [successPopup, setSuccessPopup] = useState({
    show: false,
    message: "",
  });
  const [err, setErr] = useState<{ show: boolean; message: string }>({
    show: false,
    message: "",
  });
  const [archive, setArchive] = useState(false);
  const [data, setData] = useState<CampaignData>(initialData);
  const [image, setImage] = useState<any>();
  const handleMultiSelectChange = (
    newValue: MultiValue<Option>,
    actionMeta: ActionMeta<Option>
  ): void => {
    setSelectedOption(newValue as Option[]);

    setData({ ...data, cids: newValue.map((obj) => obj.value) });
  };

  useEffect(() => {
    getCorporate();
  }, []);

  useEffect(() => {
    if (id.length > 0) {
      getById();
    }
  }, [id]);

  const getById = async () => {
    const result = await SettingsService.getCampaignById(id);
    if (isSuccess(result)) {
      if (result.value.successful) {
        setData(result.value.data);

        AmplitudeInstance.logEvent(AmplitudeEvent.GET_CAMPAIGN_BY_ID, {
          timeStamp: new Date(),
          id: id,
        });

        if (result.value.data.campaignState === CampaignTabKind.DEACTIVATE) {
          setArchive(true);
        } else {
          setArchive(false);
        }
      } else {
        setErr({ show: true, message: result.value.message });
      }
    }
  };

  useEffect(() => {
    const val = corporateList.filter((obj) => data.cids.includes(obj.value));

    setSelectedOption(val);
  }, [data, corporateList]);

  const getCorporate = async () => {
    const result = await CorporateService.fetchByState("ACTIVE", 1, 100000);
    if (isSuccess(result)) {
      if (
        result.value.successful &&
        result.value.data &&
        result.value.data.length > 0
      ) {
        setCorporateList(
          result.value.data?.map((obj) => ({
            label: obj.companyName ?? "",
            value: obj.id ?? "",
          }))
        );
      }
    }
  };

  const updateCampaign = async () => {
    const result = await SettingsService.updateCampaignById(
      id,
      data.title,
      data.body,
      data.cids,
      data.images,
      data.campaignState
    );
    if (isSuccess(result)) {
      if (result.value.successful) {
        setSuccessPopup({ show: true, message: result.value.message });
        AmplitudeInstance.logEvent(AmplitudeEvent.UPDATE_CAMPAIGN, {
          timeStamp: new Date(),
          id: id,
          title: data.title,
          body: data.body,
          cids: data.cids,
          images: data.images,
          state: data.campaignState,
        });
      } else {
        setErr({ show: true, message: result.value.message });
      }
    } else {
      setErr({ show: true, message: "Update Failed" });
    }
  };

  const createCampaign = async () => {
    console.log("Data", data);
    const result = await SettingsService.createCampaign(
      data.title,
      data.body,
      data.cids,
      data.images,
      data.campaignState
    );
    if (isSuccess(result)) {
      if (result.value.successful) {
        setSuccessPopup({ show: true, message: result.value.message });
        AmplitudeInstance.logEvent(AmplitudeEvent.CREATE_CAMPAIGN, {
          timeStamp: new Date(),
          id: id,
          title: data.title,
          body: data.body,
          cids: data.cids,
          images: data.images,
          state: data.campaignState,
        });
      } else {
        setErr({ show: true, message: result.value.message });
      }
    } else {
      setErr({ show: true, message: "Create Failed" });
    }
  };

  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      // setSelectedFileName(file.name);

      // if (file.size > 2097152) {
      //   // 2 MB in bytes
      //   alert("File size exceeds the maximum limit of 2 MB.");
      //   return;
      // }

      if (!file.type.startsWith("image/")) {
        alert("Only image files (JPG, JPEG, PNG) are allowed.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const image = new Image();
        image.onload = () => {
          if (image.width !== 380 || image.height !== 180) {
            alert("Image dimensions must be exactly 380x180 pixels.");
            return;
          }
          setImage(reader.result);
          setData({ ...data, images: file });
        };
        image.src = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div
      style={{
        marginTop: 8,
        marginLeft: 8,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div style={{ width: "60%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            src={backIcon}
            style={{ height: 15, width: 15, cursor: "pointer" }}
            onClick={() => {
              onClose();
              setImage({});
            }}
          />
          <p className="m-0 ms-2" style={{ fontWeight: "bold", fontSize: 18 }}>
            {showEdit ? (
              <input
                type="text"
                value={data.title}
                onChange={(e) => setData({ ...data, title: e.target.value })}
                style={{
                  border: "1px solid #6d6d6d",
                  borderRadius: 3,
                  fontSize: 16,
                }}
                className="px-1"
                onBlur={() => {
                  setShowEdit(false);
                }}
              />
            ) : (
              <>
                {data?.title}
                {!archive && (
                  <span
                    className="ms-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowEdit(true);
                    }}
                  >
                    <img src={editIcon} style={{ width: 15, height: 15 }} />
                  </span>
                )}
              </>
            )}
          </p>
          <div style={{ backgroundColor: "#D6D6D6" }} className="ms-3 px-3 ">
            <p className="m-0" style={{ fontWeight: "bold", fontSize: 16 }}>
              {CampaignLabel(data?.campaignState ?? "")}
            </p>
          </div>
        </div>
        <div className="mt-4">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{}}>
              <p className="m-0 " style={{ fontWeight: "bold", fontSize: 14 }}>
                Created Date
              </p>
              <p className="m-0" style={{ fontSize: 14 }}>
                {epochToDate(data?.createdAt)}
              </p>
            </div>
            <div>
              <p className="m-0 " style={{ fontWeight: "bold", fontSize: 14 }}>
                Description<span className="text-danger">*</span>
              </p>
              <textarea
                style={{
                  minHeight: 80,
                  maxHeight: 80,
                  width: 250,
                  fontSize: 14,
                }}
                disabled={archive}
                value={data?.body}
                onChange={(e) => {
                  setData({ ...data, body: e.target.value });
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 12,
              justifyContent: "space-between",
            }}
          >
            <div style={{}}>
              <p className="m-0 " style={{ fontWeight: "bold", fontSize: 14 }}>
                Image<span className="text-danger">*</span>
              </p>
            </div>
            <div>
              <div
                style={{
                  minHeight: 120,
                  maxHeight: 120,
                  width: 250,
                  border: "2px dashed #d0d0db",
                }}
              >
                <Dropzone
                  onDrop={handleDrop}
                  accept=".jpg, .jpeg, .png"
                  multiple={false}
                  disabled={archive}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section
                      className="p-1 text-center my-1 mx-auto wrap-file-upload "
                      role="button"
                    >
                      <div {...getRootProps()}>
                        <input {...getInputProps()} accept=".png" />
                        <svg
                          id="Group_2844"
                          width="20"
                          data-name="Group 2844"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 158.798 190.158"
                        >
                          <path
                            id="Path_6474"
                            data-name="Path 6474"
                            d="M258.215,314.354H215.523A12.651,12.651,0,0,1,202.872,301.7V246.6h-43.22l77.866-77.772,77.23,77.275h-43.88v55.6A12.651,12.651,0,0,1,258.215,314.354Z"
                            transform="translate(-157.192 -168.825)"
                            fill="#cecece"
                          />
                          <rect
                            id="Rectangle_365"
                            data-name="Rectangle 365"
                            width="158.798"
                            height="21.5"
                            rx="10.75"
                            transform="translate(0 168.659)"
                            fill="#cecece"
                          />
                        </svg>
                        <div
                          className="w-100 px-4 mx-auto py-2"
                          style={{ fontSize: 12 }}
                        >
                          <p className="m-0 ">Drag and drop here </p>
                          <p className="m-0">or</p>
                          <p className="m-0">Browse Files</p>
                        </div>
                        <ul className="files-list">
                          {/* {excelData.map((file, fk) => (
                          <li key={fk}>{file.name}</li>
                        ))} */}
                        </ul>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              <p
                className="m-0 mt-1 "
                style={{ fontWeight: "bold", fontSize: 8 }}
              >
                Image uploaded should be of the following dimension: 380*180
              </p>
            </div>
          </div>
          <div
            className="mt-3"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p className="m-0 " style={{ fontWeight: "bold", fontSize: 14 }}>
                Start Date
              </p>
              <input
                type="date"
                disabled={archive}
                className="mt-1"
                style={{ minHeight: 25, width: 130, padding: 5 }}
              />
            </div>
            <div>
              <p className="m-0 " style={{ fontWeight: "bold", fontSize: 14 }}>
                End Date
              </p>
              <input
                type="date"
                disabled={archive}
                className="mt-1"
                style={{ minHeight: 25, width: 130, padding: 5 }}
              />
            </div>
            <div>
              <p className="m-0 " style={{ fontWeight: "bold", fontSize: 14 }}>
                Status<span className="text-danger">*</span>
              </p>
              <select
                className="mt-1"
                style={{
                  appearance: "auto",
                  border: "auto",
                  background: "initial",
                  padding: 5,
                  minHeight: 34,
                  width: 130,
                  // Add any other properties you need to reset
                }}
                disabled={archive}
                value={data.campaignState}
                onChange={(e) => {
                  setData({
                    ...data,
                    campaignState: e.target.value as CampaignTabKind,
                  });
                }}
              >
                <option value={CampaignTabKind.LIVE}>
                  {CampaignLabel(CampaignTabKind.LIVE)}
                </option>
                <option value={CampaignTabKind.DRAFT}>
                  {CampaignLabel(CampaignTabKind.DRAFT)}
                </option>
                <option value={CampaignTabKind.DEACTIVATE}>
                  {CampaignLabel(CampaignTabKind.DEACTIVATE)}
                </option>
              </select>
            </div>
          </div>
          <div style={{ width: "100%" }} className="mt-3">
            <p className="m-0 " style={{ fontWeight: "bold", fontSize: 14 }}>
              Corporates<span className="text-danger">*</span>
            </p>
            <Select
              options={corporateList}
              value={selectedOption}
              onChange={(newValue, actionMeta) =>
                handleMultiSelectChange(
                  newValue as MultiValue<Option>,
                  actionMeta as ActionMeta<Option>
                )
              }
              isMulti
              // components={animatedComponents}
              className="w-100 mt-1"
              isDisabled={archive}
            />
          </div>
          {!archive && (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
              className="mt-3"
            >
              <Button
                className="btn-gradiant-blue btn-lg text-white fs-small m-1 me-2 p-2"
                style={{ minWidth: 150 }}
                onClick={() => {
                  id.length === 0 ? createCampaign() : updateCampaign();
                }}
              >
                {data.campaignState === CampaignTabKind.DRAFT
                  ? "Update"
                  : data.campaignState === CampaignTabKind.LIVE
                  ? "Make Campaign Live"
                  : "Deactivate"}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          width: "40%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="px-5"
      >
        <div
          style={{ border: "0.5px solid black", width: 280, borderRadius: 15 }}
        >
          <img
            style={{
              minHeight: 140,
              height: 140,
              width: 278,
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              objectFit: "cover",
            }}
            alt="Campaign Image"
            src={image ? image : data.images}
          />
          <div
            style={{
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              borderBottomLeftRadius: 15,
              borderBottomRightRadius: 15,
            }}
            className="py-3"
          >
            <p
              className="m-0 px-2"
              style={{
                textAlign: "center",
                display: "flex",
                // textWrap: "wrap",
                fontSize: 16,
              }}
            >
              {data?.body}
            </p>
            <div
              style={{
                backgroundColor: "#105099",
                borderRadius: 25,
                color: "white",
              }}
              className="px-3 py-2 mt-2"
            >
              Continue
            </div>
          </div>
        </div>
      </div>
      <SuccessPopup
        show={successPopup.show}
        message={successPopup.message}
        onClose={() => {
          setSuccessPopup({ show: false, message: "" });
          onClose();
        }}
      />
      <ErrorPopup
        show={err.show}
        errorText={err.message}
        onHide={() => {
          setErr({ show: false, message: "" });
        }}
      />
    </div>
  );
};

export default CampaignDetails;

export const epochToDate = (epoch: number): string => {
  const date = new Date(epoch); // Convert from seconds to milliseconds
  const split = date.toLocaleDateString().split("/");

  const final = `${split[1]}-${split[0]}-${split[2]}`;

  return final;
};
