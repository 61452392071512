import { Modal } from "react-bootstrap";

const FailurePopup: React.FC<{
  message?: string;
  show?: boolean;
  onClose: (show: boolean) => void;
}> = ({ message = "Failed!", show = false, onClose }) => {
  return (
    <>
      <Modal show={show} onClose={() => onClose(false)} centered>
        <Modal.Header className="d-flex flex-column justify-content-center mt-3">
          <div className="text-center rounded-circle bg-danger text-light  fs-2 ncaish-w-50">
            &#128473;
          </div>
          <Modal.Title className="text-danger mt-3 mb-3">{message}</Modal.Title>
        </Modal.Header>

        <Modal.Footer
          className="ncaish-bg-grey d-flex justify-content-center pointer"
          onClick={() => onClose(false)}
        >
          <p className="light fw-bold">OK</p>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FailurePopup;
