/**
 * Ncaish Admin API
 * Apis to super admin operations
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { NotificatonReceipients } from './notificatonReceipients';


export interface TriggerNotificationRequest { 
    event?: TriggerNotificationRequest.EventEnum;
    data?: { [key: string]: string; };
    receipients?: NotificatonReceipients;
}
export namespace TriggerNotificationRequest {
    export type EventEnum = 'CORPORATE_LOGIN_OTP' | 'CORPORATE_SIGNUP_OTP' | 'LEAD_VERIFICATION_OTP' | 'LEAD_LOGIN_OTP' | 'NCAISH_ADMIN_LOGIN_OTP' | 'APPLICATION_SUBMITTED' | 'ACCOUNT_ACTIVATED' | 'CORPORATE_USER_SIGNUP_SUCCESS' | 'APPLICATION_REJECTED' | 'DETAX_INVITE_SENT' | 'NPS_INVITE_SENT' | 'BATCH_APPROVED' | 'BATCH_EXECUTED' | 'CARD_RECHARGED' | 'INSUFFICIENT_FUND_WALLET' | 'KYC_SUBMITTED' | 'KYC_APPROVAL';
    export const EventEnum = {
        CORPORATELOGINOTP: 'CORPORATE_LOGIN_OTP' as EventEnum,
        CORPORATESIGNUPOTP: 'CORPORATE_SIGNUP_OTP' as EventEnum,
        LEADVERIFICATIONOTP: 'LEAD_VERIFICATION_OTP' as EventEnum,
        LEADLOGINOTP: 'LEAD_LOGIN_OTP' as EventEnum,
        NCAISHADMINLOGINOTP: 'NCAISH_ADMIN_LOGIN_OTP' as EventEnum,
        APPLICATIONSUBMITTED: 'APPLICATION_SUBMITTED' as EventEnum,
        ACCOUNTACTIVATED: 'ACCOUNT_ACTIVATED' as EventEnum,
        CORPORATEUSERSIGNUPSUCCESS: 'CORPORATE_USER_SIGNUP_SUCCESS' as EventEnum,
        APPLICATIONREJECTED: 'APPLICATION_REJECTED' as EventEnum,
        DETAXINVITESENT: 'DETAX_INVITE_SENT' as EventEnum,
        NPSINVITESENT: 'NPS_INVITE_SENT' as EventEnum,
        BATCHAPPROVED: 'BATCH_APPROVED' as EventEnum,
        BATCHEXECUTED: 'BATCH_EXECUTED' as EventEnum,
        CARDRECHARGED: 'CARD_RECHARGED' as EventEnum,
        INSUFFICIENTFUNDWALLET: 'INSUFFICIENT_FUND_WALLET' as EventEnum,
        KYCSUBMITTED: 'KYC_SUBMITTED' as EventEnum,
        KYCAPPROVAL: 'KYC_APPROVAL' as EventEnum
    };
}

