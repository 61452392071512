import PageTitle from "../../common/page-title";
import Heading from "../heading";

import InsuranceTable from "./insurance-table";

const Insurance = () => {
  return (
    <>
      <PageTitle title="Insurance" />
      <Heading title="Insurance" />

      <InsuranceTable />
    </>
  );
};

export default Insurance;
