import { isSuccess } from "@devexperts/remote-data-ts";
import { Option, fold, isNone, none, some } from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect, useState } from "react";
import { CorporateService } from "../../../services/corporate.service";
import {
  ITableData,
  TablePagination,
} from "../../pages/dashboard/common/table/table-pagination";

import { AdminCorporateSubscriptionListInfo } from "../../../api/admin/model/adminCorporateSubscriptionListInfo";
import CustomSearchBarUpgraded from "../../custom-search/custom-search-upgraded";
import NoTableData from "../../pages/dashboard/common/no-table-data";
import TableLoader from "../../pages/dashboard/common/table-loader";

const CorporateSubscriptionTable: React.FC<{ cId: string }> = ({ cId }) => {
  const [items, setItems] =
    useState<Option<AdminCorporateSubscriptionListInfo[]>>(none);
  const [filteredData, setFilteredData] =
    useState<Option<AdminCorporateSubscriptionListInfo[]>>(none);
  const [paging, setPaging] = React.useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });
  // const searchVal = " ";

  const [searchVal, setSearchVal] = useState<string>("");

  const tblData = (tblData: AdminCorporateSubscriptionListInfo[]) => {
    let result = [];
    let startFrom = (paging.page - 1) * paging.limit;
    let endAt = paging.page * paging.limit;
    result = tblData.slice(startFrom, endAt);
    return result;
  };

  useEffect(() => {
    const run = async () => {
      // setIsLoading(true);
      if (cId?.length > 0) {
        setItems(none);
        const tblResult = await CorporateService.getCorporateSubscriptionList(
          cId
        );
        if (isSuccess(tblResult)) {
          if (tblResult.value.successful) {
            setPaging((p) => ({
              ...p,
              total: (tblResult.value.data || []).length,
              page: 1,
              limit: 10,
            }));

            filterData(some(tblResult.value.data || []));
          }
          setItems(some(tblResult.value.data || []));
        }
        // setIsLoading(false);
      }
    };
    run();
  }, []);

  useEffect(() => filterData(items), [searchVal]);

  const filterData = (
    allData: Option<AdminCorporateSubscriptionListInfo[]>
  ) => {
    let data = pipe(
      allData,
      fold(
        () => [],
        (d) => (d.length > 0 ? d : [])
      )
    );

    if (searchVal) {
      let nameRegEx = new RegExp(".*" + searchVal + ".*", "gi");
      let filteredData = data.filter((item) =>
        nameRegEx.test(item?.userName?.toString() || "")
      );

      setPaging((p) => ({ ...p, total: filteredData.length, page: 1 }));
      setFilteredData(some(filteredData));
    } else {
      setPaging((p) => ({ ...p, total: data.length, page: 1 }));
      setFilteredData(allData);
    }
  };

  if (isNone(items)) {
    return <TableLoader />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col border rounded-12">
          <CustomSearchBarUpgraded
            value={searchVal}
            onChange={(val: string) => setSearchVal(val)}
            boxShadow="none"
            height="auto"
            width="60%"
            text="Search by Employee Name "
          />
        </div>
      </div>
      <div
        className="row mt-2 ms-1 me-1 p-3 border rounded-15"
        style={{ background: "white" }}
      >
        <div className="col-12">
          {pipe(
            filteredData,
            fold(
              () => [<NoTableData />],
              (datas) =>
                datas.length
                  ? [
                      <TablePagination
                        data={paging}
                        changePage={(data) =>
                          setPaging((p) => ({ ...p, page: data }))
                        }
                        changeLimit={(data) =>
                          setPaging((p) => ({ ...p, limit: data, page: 1 }))
                        }
                      >
                        <table>
                          <thead>
                            <tr>
                              <th scope="col">Employee</th>
                              <th scope="col">Subscription</th>
                              <th scope="col">Date of Subscription</th>
                              <th scope="col">Date of Expiry</th>
                            </tr>
                          </thead>

                          <tbody>
                            {tblData(datas).map((item, key) =>
                              (item.subscriptionsList?.length || 0) > 0 ? (
                                item.subscriptionsList?.map((item1, key1) => (
                                  <tr key={`${key}${key1}`}>
                                    {key1 === 0 && (
                                      <td
                                        className="text-start"
                                        rowSpan={item.subscriptionsList?.length}
                                      >
                                        {item.userName === ""
                                          ? "-"
                                          : item.userName}
                                      </td>
                                    )}

                                    <td className="text-start">
                                      {item1.subscriptionName === ""
                                        ? "-"
                                        : item1.subscriptionName}
                                    </td>
                                    <td>
                                      {item1.subscriptionStartDate === ""
                                        ? "-"
                                        : item1.subscriptionStartDate}
                                    </td>
                                    <td>
                                      {item1.subscriptionEndDate === ""
                                        ? "-"
                                        : item1.subscriptionEndDate}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr key={`${key}`}>
                                  <td className="text-start">
                                    {item.userName === "" ? "-" : item.userName}
                                  </td>
                                  <td className="text-start">-</td>
                                  <td>-</td>
                                  <td>-</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </TablePagination>,
                    ]
                  : [<NoTableData />]
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default CorporateSubscriptionTable;
