import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { none, Option } from "fp-ts/lib/Option";
import { DetaxAdminBatchInfo } from "../../api/detax/model/detaxAdminBatchInfo";
import { DetaxTypeKind } from "../../models/detax-type-kind";
import { State, store } from "../../state/store";

export interface ITransactionFilter {
  type: DetaxTypeKind;
  status: string;
}

export interface DetaxState {
  detaxType: DetaxTypeKind;
  showUploadTransaction: boolean; // open upload transaction component
  filter: ITransactionFilter;
  batchInfo: Option<DetaxAdminBatchInfo>;
  NpsBatchInfo: Option<DetaxAdminBatchInfo>;
}

export const initialDetaxState: DetaxState = {
  detaxType: DetaxTypeKind.FBA,
  showUploadTransaction: false,
  filter: { type: DetaxTypeKind.FBA, status: "ALL" },
  batchInfo: none,
  NpsBatchInfo: none,
};

const detaxSlice = createSlice({
  name: "detax",
  initialState: initialDetaxState,
  reducers: {
    initializeApp: () => initialDetaxState,

    setDetaxType: (state, { payload: type }: PayloadAction<DetaxTypeKind>) => {
      state.detaxType = type;
      state.filter.type = type;
      state.filter.status = "ALL";
    },

    showUploadTransaction: (state) => {
      state.showUploadTransaction = true;
    },

    hideUploadTransaction: (state) => {
      state.showUploadTransaction = false;
    },

    setFilterTypeStatus: (
      state,
      { payload: status }: PayloadAction<string>
    ) => {
      state.filter.status = status;
    },

    setBatchInfo: (
      state,
      { payload: batchInfo }: PayloadAction<Option<DetaxAdminBatchInfo>>
    ) => {
      state.batchInfo = batchInfo;
    },
    setNpsBatchInfo: (
      state,
      { payload: NpsBatchInfo }: PayloadAction<Option<DetaxAdminBatchInfo>>
    ) => {
      state.NpsBatchInfo = NpsBatchInfo;
    },
  },
});

export namespace DetaxDispatch {
  export const setDetaxType = (payload: DetaxTypeKind) =>
    store.dispatch(detaxSlice.actions.setDetaxType(payload));

  export const showUploadTransaction = () =>
    store.dispatch(detaxSlice.actions.showUploadTransaction());

  export const hideUploadTransaction = () =>
    store.dispatch(detaxSlice.actions.hideUploadTransaction());

  export const setFilterTypeStatus = (payload: string) =>
    store.dispatch(detaxSlice.actions.setFilterTypeStatus(payload));

  export const setBatchInfo = (payload: Option<DetaxAdminBatchInfo>) =>
    store.dispatch(detaxSlice.actions.setBatchInfo(payload));

  export const setNpsBatchInfo = (payload: Option<DetaxAdminBatchInfo>) =>
    store.dispatch(detaxSlice.actions.setNpsBatchInfo(payload));
}

export namespace DetaxReduxStore {
  export const selectDetaxType = (state: State) => state.detax.detaxType;
  export const selectShowUploadTransaction = (state: State) =>
    state.detax.showUploadTransaction;

  export const selectFilter = (state: State) => state.detax.filter;
  export const selectFilterType = (state: State) => state.detax.filter.type;
  export const selectFilterStatus = (state: State) => state.detax.filter.status;
  export const selectBatchInfo = (state: State) => state.detax.batchInfo;
  export const selectNpsBatchInfo = (state: State) => state.detax.NpsBatchInfo;
}

export default detaxSlice.reducer;
