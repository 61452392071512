/**
 * Ncaish Admin API
 * Apis to super admin operations
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AuthData { 
    userId?: string;
    name?: string;
    email?: string;
    mobile?: string;
    gender?: AuthData.GenderEnum;
    profileImg?: string;
}
export namespace AuthData {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'TRANSGENDER';
    export const GenderEnum = {
        MALE: 'MALE' as GenderEnum,
        FEMALE: 'FEMALE' as GenderEnum,
        TRANSGENDER: 'TRANSGENDER' as GenderEnum
    };
}

