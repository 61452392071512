import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./popup.scss";
import { useParams } from "react-router-dom";
import { CorporateService } from "../../../services/corporate.service";
import { isSuccess } from "@devexperts/remote-data-ts";
import { AmplitudeInstance } from "../../..";
import { AmplitudeEvent } from "../../../enum/amplitude";

const RemoveUserPopup: React.FC<{
  show: boolean;
  onHide: (show: boolean) => void;
  onCancel: () => void;
  uid: string;
}> = ({ show, onHide, onCancel, uid }) => {
  const params = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const handleRemove = {
    run: async () => {
      if (params?.id) {
        setIsLoading(true);

        const result = await CorporateService.getRemoveUser(uid);
        if (isSuccess(result)) {
          if (result.value.successful) {
            // setDetails((prev) => (prev = result.value.data || prev));

            AmplitudeInstance.logEvent(AmplitudeEvent.REMOVE_CORP_ADMIN, {
              timeStamp: new Date(),
              adminUid: uid,
            });

            window.location.reload();
          }
        }
        setIsLoading(false);
      }
    },
  };

  return (
    <Modal show={show} onHide={() => onHide(false)} centered>
      <Modal.Header className="bg-danger d-flex flex-column justify-content-center">
        <div className="text-center  text-light fs-10"> &#9888;</div>
        <Modal.Title className="text-light">
          Are you sure you want to remove access to corporate admin for this
          user?
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer className=" d-flex justify-content-center">
        <Button
          className="btn-gradiant-gray"
          variant="secondary"
          onClick={() => onHide(false)}
        >
          Close
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            handleRemove.run();
            onCancel();
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveUserPopup;
